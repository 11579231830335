import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import axiosAuth from "../../../../API/auth";

import { userClear } from "../../../../slices/user/slice";
import { productTypesEdit, switchCurrentProductTypes } from "../../../../slices/characteristic/slice";
import { ProductTypesType } from "../../../../slices/characteristic/interfaces";

import EditableText from "../../editableText"
import RemoveButton from "./remove";
import { setErrorText } from "../../../../slices/modal/slice";

export default function TypesProductItem({ data }: {data: ProductTypesType}) {
    const currentType = useAppSelector(state => state.characteristic.currentProductType);

    const dispatch = useAppDispatch();

    return (
        <div
            key={data.id}
            className={`tab flex items-center justify-between  min-w-[200px] m-1 ${currentType.id === data.id ? "border-solid border-2 border-black" : ""}`}
            onClick={(e) => {
                const target = e.target as HTMLDivElement

                if ((target.id !== "remove_type") && (!target.closest("#remove_type"))) {
                    dispatch(switchCurrentProductTypes(data))
                }
            }}
        >
            <div className="flex gap-6 justify-between w-[100%]">
                <EditableText defaultText={data.name} renameFunk={(newValue) => {
                    axiosAuth()
                        .put("/product_types/", JSON.stringify([{
                            id: data.id,
                            name: newValue
                        }]), {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(() => {
                            dispatch(productTypesEdit({
                                id: data.id,
                                name: newValue
                            }));
                        })
                        .catch((err) => {
                            if (err === "logaut") {
                                dispatch(userClear());
                            } else {
                                console.error(err);
                                dispatch(setErrorText(err.response.data.error))
                            }
                        })
                }} />

                <RemoveButton id={data.id} />
            </div>
        </div>
    )
}