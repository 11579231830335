import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";

import axiosAuth from "../../API/auth";

import { setProductTypes as setProductTypesTable, setQueryParams, tableFetched } from "../../slices/productsTable/slice";
import { setProductTypes as setProductTypesCharacteristic, characteristicFetched, switchCurrentProductTypes } from "../../slices/characteristic/slice";
import { userClear, userFetchingError } from "../../slices/user/slice";
import { sectionsPageFetched } from "../../slices/sectionsPage/slice";
import { productFetched } from "../../slices/product/slice";
import { setAll } from "../../slices/link/slice";

import MainLayout from "../../pages/mainLayout";
import {
    CharacteristicsPage,
    EasyDropSettingsPage,
    HoroshopSettingsPage,
    LinkPage,
    LoginPage,
    NewPasswordPage,
    ProductPage,
    ProductsPage,
    PromSettingsPage,
    RegistationPage,
    ResetPasswordPage,
    SectionsPage,
    SettingsPage,
    UnloadingPage
} from "../../pages";

import { ProductType } from "../../slices/productsTable/interfaces";
import { CharacteristicType, ProductTypesType } from "../../slices/characteristic/interfaces";
import { setEasydrop } from "../../slices/integrations/easydrop/slice";
import { setErrorText } from "../../slices/modal/slice";
import { setHoroshop } from "../../slices/integrations/horoshop/slice";
import { setProm } from "../../slices/integrations/prom/slice";


export default function MainRouter() {

    const dispatch = useAppDispatch();

    return (
        <RouterProvider router={createBrowserRouter([
            {
                element: <MainLayout />,
                children: [
                    {
                        path: "/",
                        element: <ProductsPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ request }) => {
                            try {
                                const query = new URLSearchParams(request.url.split("?")[1]);

                                const id = query.get('p');
                                const search = query.get('search');
                                const offset = parseInt(query.get('offset') || "");
                                const limit = parseInt(query.get('limit') || "");

                                const response = await axiosAuth().get(`products/?${id ? ("product_type_id=" + id) : ""}${search ? ("&search=" + search) : `&offset=${offset || 0}&limit=${limit || 20}`}`);

                                const productsData: {
                                    products: ProductType[],
                                    total_items: number
                                } = JSON.parse(JSON.stringify(response?.data));

                                if (search) {
                                    const parentElement = productsData.products.filter(item => !item.parent_id);

                                    productsData.total_items = parentElement.length
                                    productsData.products = parentElement.map(product => {
                                        return {
                                            ...product,
                                            children: productsData.products.filter(item => item.parent_id === product.id)
                                        }
                                    });

                                    const parentElementIds = parentElement.reduce((arrId: number[], item) => {
                                        return [...arrId, item.id]
                                    }, [])
                                    productsData.products = [...productsData.products, ...response?.data.products.filter((item: ProductType) => item.parent_id ? !parentElementIds.includes(item.parent_id) : false)]
                                }

                                dispatch(tableFetched(productsData));
                                dispatch(setProductTypesTable(response?.data?.product_types));

                                if (id) {
                                    dispatch(setQueryParams(request.url.split("?")[1]))
                                } else {
                                    if (response?.data.product_types.length !== 0) {
                                        dispatch(setQueryParams(`p=${response?.data.product_types[0].id}&offset=0&limit=20&search=`));
                                    }
                                }

                                return response
                            } catch (err) {
                                console.log(err)
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }
                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "characteristics",
                        element: <CharacteristicsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                const response = await axiosAuth().get("/process_characteristics/");
                                const { characteristics, productTypes }: { characteristics: CharacteristicType[]; productTypes: ProductTypesType[]; } = response.data;

                                dispatch(setProductTypesCharacteristic(productTypes));
                                dispatch(switchCurrentProductTypes(productTypes[0]));
                                dispatch(characteristicFetched(characteristics));

                                return response
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }
                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "sections",
                        element: <SectionsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                const response = await axiosAuth().get("/cats/");
                                dispatch(sectionsPageFetched(response.data));

                                return response
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }
                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "product/add/:id",
                        element: <ProductPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ params }) => {
                            const { id } = params;

                            try {
                                const response = await axiosAuth().get(`/new_product/?product_type_id=${id}`);
                                dispatch(productFetched(response.data));
                                dispatch(setEasydrop(response.data.integrations?.easydrop || null));
                                dispatch(setHoroshop(response.data.integrations?.horoshop || null));
                                dispatch(setProm(response.data.integrations?.prom || null));

                                return response;
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "product/edit/:id",
                        element: <ProductPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ params }) => {
                            const { id } = params;

                            try {
                                const response = await axiosAuth().get(`/product/${id}`);
                                dispatch(productFetched(response.data));
                                dispatch(setEasydrop(response.data.integrations?.easydrop || null));
                                dispatch(setHoroshop(response.data.integrations?.horoshop || null));
                                dispatch(setProm(response.data.integrations?.prom || null));

                                return response;
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "product/variation/:id",
                        element: <ProductPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ params }) => {
                            const { id } = params;

                            try {
                                const response = await axiosAuth().get(`/product/${id}`);
                                const charVariation = response.data.characteristics.find((item: any) => item.is_variation);
                                
                                delete response.data.product.characteristics[charVariation.id]

                                dispatch(productFetched({
                                    ...response.data,
                                    product: {
                                        ...response.data.product,
                                        base: {
                                            ...response.data.product.base,
                                            sku: ""
                                        },
                                        pictures: [],
                                        characteristics: {
                                            ...response.data.product.characteristics,
                                        }
                                    },
                                }));
                                dispatch(setEasydrop(response.data.integrations?.easydrop || null));
                                dispatch(setHoroshop(response.data.integrations?.horoshop || null));
                                dispatch(setProm(response.data.integrations?.prom || null));

                                return response
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "unloading/:marketplace?",
                        element: <UnloadingPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                return await axiosAuth().get("/v2/links/");
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "link/add/:id",
                        element: <LinkPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ params }) => {
                            const { id } = params

                            try {
                                return await axiosAuth().get("/v2/links/add/?marketplace_id=" + id);
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "link/edit/:id",
                        element: <LinkPage />,
                        shouldRevalidate: () => false,
                        loader: async ({ params }) => {
                            const { id } = params

                            try {
                                const response = await axiosAuth().get("/v2/link/?id=" + id);

                                dispatch(setAll(response.data.link));

                                return response
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "settings",
                        element: <SettingsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                return await axiosAuth().get("/integrations/");
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "settings/easydrop",
                        element: <EasyDropSettingsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                return await axiosAuth().get(`/user_integrations/?integration_id=2`);
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "settings/prom",
                        element: <PromSettingsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                return await axiosAuth().get(`/user_integrations/?integration_id=4`);
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "settings/horoshop",
                        element: <HoroshopSettingsPage />,
                        shouldRevalidate: () => false,
                        loader: async () => {
                            try {
                                return await axiosAuth().get(`/user_integrations/?integration_id=5`);
                            } catch (err) {
                                if (err === "logaut") {
                                    dispatch(userClear());
                                } else {
                                    console.error(err)
                                    dispatch(setErrorText((err as any).response.data.error))
                                }

                                throw err
                            }
                        },
                        errorElement: <div>Не вдалося загрузити дані</div>
                    },
                    {
                        path: "login",
                        element: <LoginPage />
                    },
                    {
                        path: "registration",
                        element: <RegistationPage />
                    },
                    {
                        path: "new_password",
                        element: <ResetPasswordPage />
                    },
                    {
                        path: "reset_password/:token",
                        element: <NewPasswordPage />
                    }
                ]
            }
        ])} />
    )
}