import { useRef, useState } from "react";

import EditableTextProps from "./interfacets";

export default function EditableText({ defaultText, renameFunk }: EditableTextProps) {
    const [inputValue, setInputValue] = useState<string>(defaultText);
    const [focusInput, setFocus] = useState<boolean>(false);

    const lastTouchTime = useRef<number>(0)

    return (
        <>
            {
                focusInput ?
                    <input className="value bg-gray-100 " value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onBlur={() => {
                            renameFunk(inputValue || defaultText);
                            setFocus(false);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                renameFunk(inputValue || defaultText);
                                setFocus(false);
                            }
                        }}
                        autoFocus
                    /> :
                    <span className="text-lg"
                        onDoubleClick={(e) => {
                            const elem = e.target as HTMLSpanElement;
                            setFocus(true);
                            if (elem.textContent) {
                                setInputValue(elem.textContent)
                            }
                        }}
                        onTouchStart={(e) => {
                            const elem = e.target as HTMLSpanElement;
                            if (elem.textContent) {
                                const currentTime = new Date().getTime();
                                const timeDifference = currentTime - lastTouchTime.current;

                                if (timeDifference < 300) {
                                    setFocus(true);
                                    setInputValue(elem.textContent)
                                }

                                lastTouchTime.current = currentTime;
                            }
                        }}
                    >{defaultText}</span>
            }
        </>
    )
}