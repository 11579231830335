import { useLoaderData } from "react-router-dom";

import Branch from "./branch";

import { SectionsPageType } from "../../../slices/sectionsPage/interfaces";

export default function SectionsCheckbox() {
    const { data: { categories } } = useLoaderData() as any as { data: { categories: SectionsPageType[] } }

    return (
        <div>
            <p className="font-bold mb-2">Розділи сайту</p>
            <div className="py-6 w-full overflow-x-auto shadow pr-2">
                {
                    categories.length > 0
                        ? categories[0].children.map((item) => <Branch key={item.id} node={item} level={0} />)
                        : <div className="text-black">Немає даних про доступні розділи сайту</div>
                }
            </div>
        </div>
    )
}