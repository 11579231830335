import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from "../../store/hooks";

import axiosAuth from '../../API/auth';

import { tablePaginatonFetching, tablePaginatonFetchingError, tablePaginatonFetched, setProductTypes } from "../../slices/productsTable/slice";
import { userClear } from '../../slices/user/slice';

import PaginationNavigation from '../../components/shared/pagination';
import TableProducts from '../../components/products/table';
import Actions from '../../components/products/actions';
import VariationsModal from '../../components/products/variationsModal';

import { ProductType } from '../../slices/productsTable/interfaces';
import { setErrorText } from '../../slices/modal/slice';

export default function ProductsPage() {
    const [params, setParams] = useSearchParams();

    const dispatch = useAppDispatch();

    function fetchingPagination(queryParamsStrng: string) {
        dispatch(tablePaginatonFetching());

        const queryParams = new URLSearchParams(queryParamsStrng);

        const paginationLimit = queryParams.get("limit") || 20;
        const paginationOffset = queryParams.get("offset") || 0;
        const searchText = queryParams.get("search") || "";
        const productTypeId = queryParams.get("p") || 0;

        return axiosAuth()
            .get(`products/?${productTypeId ? ("product_type_id=" + productTypeId) : ""}${searchText ? ("&search=" + searchText) : `&offset=${paginationOffset}&limit=${paginationLimit}`}`)
            .then(response => {
                const productsData: {
                    products: ProductType[],
                    total_items: number
                } = JSON.parse(JSON.stringify(response.data));

                if (searchText) {
                    const parentElement = productsData.products.filter(item => !item.parent_id);

                    productsData.total_items = parentElement.length
                    productsData.products = parentElement.map(product => {
                        return {
                            ...product,
                            children: productsData.products.filter(item => item.parent_id === product.id)
                        }
                    });

                    const parentElementIds = parentElement.reduce((arrId: number[], item) => {
                        return [...arrId, item.id]
                    }, [])
                    productsData.products = [...productsData.products, ...response.data.products.filter((item: ProductType) => item.parent_id ? !parentElementIds.includes(item.parent_id) : false)]
                }

                dispatch(tablePaginatonFetched(productsData));
                dispatch(setProductTypes(response.data.product_types));

                params.set("p", productTypeId + "");
                params.set("limit", paginationLimit + "");
                params.set("offset", paginationOffset + "");
                params.set("search", searchText);

                setParams(params);
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    console.error(err)
                    dispatch(setErrorText(err.response.data.error))
                    dispatch(tablePaginatonFetchingError());
                }
            })
    }

    return (
        <main className="p-2 w-full" style={{ height: "calc(100vh - 38px)" }}>
            <Actions onFetch={fetchingPagination} />
            <PaginationNavigation
                fetching={fetchingPagination}
                childrenRender={(paginationLimit, paginationIndex) => (
                    <TableProducts paginationLimit={paginationLimit} paginationIndex={paginationIndex} />
                )}
            />
            <VariationsModal />
        </main>
    )
};