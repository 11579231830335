import { useState } from 'react';

import AccordionProps from './interfaces';

export default function Accordion({ title, children, icon }: AccordionProps) {

    const [status, setStatus] = useState(false);

    return (
        <div id="accordion-flush" data-accordion="collapse" >

            <h2 id="accordion-flush-heading-1 w-full" onClick={() => { setStatus(!status) }}>

                <button type="button" className="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-500 border-b border-gray-200  gap-3" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">

                    <span className='flex items-center gap-4'>{icon}{title}</span>
                    <svg data-accordion-icon className={`w-3 h-3  shrink-0 ${status ? "rotate-0" : "rotate-180"}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h2>

            <div id="accordion-flush-body-1" className={status ? "" : "hidden"} aria-labelledby="accordion-flush-heading-1">
                {children}
            </div>
        </div>
    );
};