import EditableText from "../../../../shared/editableText";

import { ValueProps } from "../../interfaces";

export default function ValueItem({ text, removeValue, renameValue, id }: ValueProps) {
    return (
        <div className="flex items-center justify-center px-[10px] py-[3px] bg-gray-100 rounded">
            <EditableText defaultText={text} renameFunk={(newValue) => {
                renameValue(newValue, id)
            }} />
            <svg style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => { removeValue(id) }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}