import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { addAvailability, removeAvailability, updateSelectedCharacteristic } from "../../../../slices/product/slice";

import { FormControlLabel, Checkbox } from "@mui/material";

import BasicCheckboxListProps from "../interfaces";

export default function BasicCheckboxList({ char, type = "basic" }: BasicCheckboxListProps) {
    const selectedData = useAppSelector(state => state.product.characteristicsSelected);

    const dispatch = useAppDispatch();

    return (
        <div key={char.id} className="flex flex-wrap items-center gap-6">
            <p>{char.name["uk"]}</p>
            {
                char.values.map(value => {
                    return <FormControlLabel
                        key={value.id}
                        id={value.id + ""}
                        label={value.name["uk"]}
                        control={
                            <Checkbox
                                checked={selectedData[char.id]?.includes(value.id) || false}
                                onChange={() => {
                                    dispatch(updateSelectedCharacteristic({
                                        characteristic_id: char.id,
                                        value_id: value.id
                                    }))
                                    if (type === "size") {
                                        if (!selectedData[char.id]?.includes(value.id)) {
                                            dispatch(addAvailability(value.name['en']))
                                        } else {
                                            dispatch(removeAvailability(value.name['en']))
                                        }
                                    }
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                })
            }
        </div>
    )
}