import { CommandProps, Extension } from '@tiptap/react'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        CustomTableCell: {
            setCellHorizontalAlign: (alignment: "left" | "center" | "right") => ReturnType,
            setCellVerticalAlign: (alignment: "top" | "middle" | "bottom") => ReturnType
        }
    }
}

export const CustomTableCell = Extension.create({
    addGlobalAttributes() {
        return [
            {
                types: ["tableCell", "tableHeader"],
                attributes: {
                    textAlign: {
                        default: "left",
                        parseHTML: element => element.style.textAlign,
                        renderHTML: attributes => {
                            if (attributes.textAlign === "left") {
                                return {}
                            }

                            return {
                                style: `text-align: ${attributes.textAlign}`,
                            }
                        },
                    },
                    verticalAlign: {
                        default: "top",
                        parseHTML: element => element.style.verticalAlign,
                        renderHTML: attributes => {
                            if (attributes.verticalAlign === "top") {
                                return {}
                            }

                            return {
                                style: `vertical-align: ${attributes.verticalAlign}`,
                            }
                        },
                    },
                }
            }
        ]
    },

    addCommands() {
        return {
            setCellHorizontalAlign: (alignment: "left" | "center" | "right") => ({ tr, dispatch }: CommandProps) => {
                const { doc, selection } = tr;

                selection.ranges.forEach((range) => {
                    doc.nodesBetween(range.$from.pos, range.$to.pos, (node, pos, parent) => {
                        const nodeAttrs = {
                            ...node?.attrs,
                            textAlign: alignment,
                        }

                        if (parent?.type.name !== "tableCell" && parent?.type.name !== "tableHeader" && node.type.name !== "text") {
                            tr = tr.setNodeMarkup(pos, node?.type, nodeAttrs, node?.marks)
                        }
                    })
                })

                if (tr.docChanged && dispatch) {
                    dispatch(tr)
                    return true
                }

                return false
            },
            setCellVerticalAlign: (alignment: "top" | "middle" | "bottom") => ({ tr, dispatch }: CommandProps) => {
                const { doc, selection } = tr;

                selection.ranges.forEach((range) => {
                    doc.nodesBetween(range.$from.pos, range.$to.pos, (node, pos, parent) => {
                        const nodeAttrs = {
                            ...node?.attrs,
                            verticalAlign: alignment,
                        }

                        if (parent?.type.name !== "tableCell" && parent?.type.name !== "tableHeader" && node.type.name !== "text") {
                            tr = tr.setNodeMarkup(pos, node?.type, nodeAttrs, node?.marks)
                        }
                    })
                })

                if (tr.docChanged && dispatch) {
                    dispatch(tr)
                    return true
                }

                return false
            }
        }
    },
})