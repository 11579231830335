import { useLoaderData } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { setFileFormat } from "../../../slices/link/slice";

import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

import { FileFormatsType } from "../../../pages/link/interfaces";

export default function FileFormats() {
    const { data: { file_formats } } = useLoaderData() as any as { data: { file_formats: FileFormatsType } }

    const selectFormat = useAppSelector(state => state.link.file_format);

    const dispatch = useAppDispatch();

    function onChange(newValue: string) {
        dispatch(setFileFormat(newValue));
    }

    return (
        <div>
            <p className="font-bold">Формат файлу</p>
            <RadioGroup
                row
                value={selectFormat}
                onChange={(e) => onChange(e.target.value)}
            >
                {
                    file_formats.map(item => (
                        <FormControlLabel
                            key={item}
                            value={item}
                            control={<Radio />}
                            label={item}
                        />
                    ))
                }
            </RadioGroup>
        </div>
    )
}