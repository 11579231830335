import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { LoginPage, NewPasswordPage, RegistationPage, ResetPasswordPage } from "../../pages";
import ConfirmedEmail from "../../components/shared/confirmedEmail";

export default function IsConfirmedRouter() {
    return (
        <RouterProvider router={createBrowserRouter([
            {
                path: "*",
                element: <ConfirmedEmail/> ,
            },
            {
                path: "login",
                element: <LoginPage />
            },
            {
                path: "registration",
                element: <RegistationPage />
            },
            {
                path: "new_password",
                element: <ResetPasswordPage />
            },
            {
                path: "reset_password/:token",
                element: <NewPasswordPage />
            }
        ])} />
    )
}