import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { setBaseField } from "../../../slices/product/slice";

import TextField from "@mui/material/TextField";

import AdvancedField from '../advancedField';

import StandartFieldProps from "./interfaces";
import { FormControlLabel, Switch } from "@mui/material";

export default function StandartField({ label, name, validate, type = "text" }: StandartFieldProps) {
    const base = useAppSelector(state => state.product.base);

    const [formatMode, setFormatMode] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("")

    const dispatch = useAppDispatch();

    return (
        <>
            {
                type === "textarea"
                    ? <FormControlLabel disabled sx={{ mb: "5px" }} control={<Switch checked={formatMode} onChange={() => { setFormatMode(state => !state) }} />} label="Форматований ввід" />
                    : null
            }

            {
                formatMode
                    ? <AdvancedField title={label} />
                    : <TextField
                        sx={{ marginBottom: "10px" }}
                        fullWidth
                        id={name}
                        name={name}
                        label={label}
                        type={type}
                        multiline={type === "textarea"}
                        value={type === "number" ? (((base[name] || "") + "") || "0") : (base[name] || "")}
                        onChange={(e) => {
                            if (type === "number") {
                                dispatch(setBaseField({
                                    name,
                                    value: e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : "0" : "0",
                                }));
                            } else {
                                dispatch(setBaseField({
                                    name,
                                    value: e.target.value
                                }));
                            }
                        }}
                        onBlur={() => {
                            setErrorText("")
                            if (validate) {
                                try {
                                    validate.validateSync(base[name]);
                                } catch (err: any) {
                                    setErrorText(err.errors[0])
                                }
                            }
                        }}
                        error={!!errorText}
                        helperText={<span>{errorText}</span>}
                    />
            }
        </>
    )
}