import { useAppDispatch } from "../../../store/hooks";

import { sectionsPageRemove } from "../../../slices/sectionsPage/slice";

import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import { SectionsPageType } from "../../../slices/sectionsPage/interfaces";

export default function SectionsRemove({ element }: { element: SectionsPageType }) {
    const dispatch = useAppDispatch();

    return (
        <IconButton
            size="small"
            onClick={() => {
                dispatch(sectionsPageRemove(element.id ? element.id : (element.id_front || 0)));
            }}
        >
            <CloseIcon fontSize="medium" />
        </IconButton>
    )
}