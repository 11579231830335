import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Checkbox,
    IconButton,
    Grid,
    Box,
    Typography,
    Container,
    InputAdornment,
    RadioGroup,
    Radio,
    Autocomplete
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ConfirmedEmail from '../../components/shared/confirmedEmail';

export default function RegistationPage() {
    const [niches, setNiches] = useState<{ id: number, name: string }[]>([]);
    const [selectedNiches, setSelectedNiches] = useState<{ id: number, name: string }[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);

    const [failed, setFailed] = useState<boolean>(false);
    const [failedCode, setFailedCode] = useState<number>(0);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const passwordRef = useRef<string>("")

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "+38",
            password: "",
            confirmPassword: "",
            registrant_type: "seller"
        },
        validationSchema: yup.object({
            name: yup.string().required("Це поле обов'язкове"),
            email: yup.string().email("Невірний формат пошти").required("Це поле обов'язкове"),
            phone: yup.string().matches(/^\+38[\d]{10}$/, "Невірний формат телефону").required("Це поле обов'язкове"),
            password: yup.string().required("Це поле обов'язкове"),
            confirmPassword: yup.string().test(
                'is-equal',
                'Паролі не рівні',
                (value, context) => value === passwordRef.current,
            ).required("Це поле обов'язкове")
        }),
        onSubmit: (values) => {
            setLoading(true);

            const formData = new FormData();

            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("name", values.name);
            formData.append("password", values.password);
            formData.append("registrant_type", values.registrant_type);
            formData.append("niches", JSON.stringify(selectedNiches.reduce((arrId: number[], item) => [...arrId, item.id], [])));

            axios.post("/api/registration/", formData)
                .then(response => {
                    window.localStorage.setItem("access_token", response.data.access_token);
                    window.localStorage.setItem("refresh_token", response.data.refresh_token);
                    console.log(response)

                    setLoading(false);
                    setSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    setFailed(true);
                    setFailedCode(err.response.data.error)
                    setTimeout(() => {
                        setFailed(false);
                    }, 2400);
                })
        }
    })

    useEffect(() => {
        axios.get("/api/niches/?search=")
            .then(responce => {
                setNiches(responce.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }, []);

    useEffect(() => {
        if ((Object.values(formik.errors).length === 0) && Object.values(formik.values).every(item => item) && (selectedNiches.length > 0)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formik.values, formik.errors, selectedNiches]);

    let failedMessage: JSX.Element = <></>;

    switch (failedCode) {
        case 2006:
            failedMessage = <p className='text-[14px] text-red-600'>Користувач з таким email уже існує.</p>
            break;
    }

    return (
        success
            ? <ConfirmedEmail />
            :
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Реєстрація
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    autoComplete="given-name"
                                    name="name"
                                    fullWidth
                                    id="name"
                                    label="Ім'я"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete="phone"
                                    name="phone"
                                    id="phone"
                                    label="Телефон"
                                    value={formik.values.phone}
                                    onChange={(e) => {
                                        if (/^\+38[\d]{0,10}$/.test(e.target.value) || !e.target.value) {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Пароль"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="new-password"
                                    value={formik.values.password}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        passwordRef.current = e.target.value;
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(state => !state)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Повторіть пароль"
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    autoComplete="confirm-password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(state => !state)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className='max-w-[396px]'
                                    multiple
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    disableCloseOnSelect
                                    noOptionsText={"Нічого не знайдено"}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedNiches}
                                    onChange={(event, newValue) => {
                                        setSelectedNiches(newValue)
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        axios.get("/api/niches/?search=" + newInputValue)
                                            .then(responce => {
                                                setNiches(responce.data)
                                            })
                                            .catch((err) => {
                                                console.error(err)
                                            })
                                    }}
                                    options={niches}
                                    renderInput={(params) => <TextField {...params} label="Ніші" />}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    defaultValue="seller"
                                    name="registrant_type"
                                    value={formik.values.registrant_type}
                                    onChange={formik.handleChange}
                                >
                                    <div className='flex max-[400px]:flex-col'>
                                        <FormControlLabel value="seller" control={<Radio />} label="Я продавець" />
                                        <FormControlLabel value="supplier" control={<Radio />} label="Я постачальник" />
                                    </div>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        {failedMessage}
                        <LoadingButton
                            fullWidth
                            type="submit"
                            size="large"
                            loading={loading}
                            loadingPosition="center"
                            variant="contained"
                            disabled={disabled}
                            color={failed ? "error" : "primary"}
                            sx={{ mt: 1, mb: 1 }}
                        >
                            <span>{failed ? "Не вдалось зареєструватись" : "Зареєструватися"}</span>
                        </LoadingButton>
                        <div className='text-right'>
                            <Link to={"/"} className='text-[14px] text-sky-600 font-semibold'>
                                У вас є аккаунт? Увійти.
                            </Link>
                        </div>
                    </Box>
                </Box>
            </Container>
    );
}