import { Divider } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";

import Branch from "./branch";
import { useLoaderData } from "react-router-dom";
import { SectionsPageType } from "../../../slices/sectionsPage/interfaces";

export default function SectionsCheckbox() {
    const { data: { categories } } = useLoaderData() as any as { data: { categories: SectionsPageType[] } }

    const isActiveProm = useAppSelector(state => state.prom.isActive);

    return (
        <div>
            <p className="font-bold mb-2">Розділи сайту</p>
            <div className="w-full overflow-x-auto shadow pr-2">
                <div className="flex justify-end  gap-4 p-1">
                    {
                        isActiveProm
                            ? (
                                <>
                                    <p className="w-[42px]">Prom</p>
                                    <Divider orientation="vertical" flexItem />
                                </>
                            )
                            : null
                    }
                    <p className="w-[42px]">CRM</p>
                    <p className="w-[64px]"></p>
                </div>
                {
                    categories.length > 0
                        ? categories[0].children.map((item) => <Branch key={item.id} node={item} level={0} />)
                        : <div className="text-black">Немає даних про доступні розділи сайту</div>
                }
            </div>
        </div>
    )
}