import { useEffect, useState } from "react";

import { useCurrentEditor } from "@tiptap/react";
import * as yup from 'yup';

import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function YouTube() {
    const { editor } = useCurrentEditor()

    const [statusYoutubeModal, setStatusYoutubeModal] = useState<boolean>(false);

    const [youtubeSettings, setYoutubeSettings] = useState<{
        url: string,
        sizePattern: string,
        width: number,
        height: number
    }>({
        url: "",
        sizePattern: "560x315",
        width: 560,
        height: 315
    })

    const [youtubeSettingsError, setYoutubeSettingsError] = useState<{
        url: string,
    }>({
        url: "",
    })

    useEffect(() => {
        if (statusYoutubeModal && editor?.getAttributes('youtube')?.src) {
            const size = editor?.getAttributes('youtube').width + "x" + editor?.getAttributes('youtube').height

            setYoutubeSettings({
                url: editor?.getAttributes('youtube').src,
                sizePattern: ["560x315", "640x360", "853x480", "1280x720"].includes(size) ? size : "other",
                width: editor?.getAttributes('youtube').width || 560,
                height: editor?.getAttributes('youtube').height || 315
            })
        }
    }, [statusYoutubeModal])

    if (editor) {
        return (
            <>
                <Tooltip title="Додати відео з YouTube" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant="outlined"
                        onClick={() => setStatusYoutubeModal(true)}
                    >
                        <YouTubeIcon />
                    </Button>
                </Tooltip>
                <div
                    className={statusYoutubeModal ? "modal h-screen z-[2000000]" : "hidden"}
                    onClick={(event) => {
                        if (event.target === event.currentTarget) {
                            setStatusYoutubeModal(false)
                        }
                    }}
                >
                    <div style={{ padding: "15px" }} className="form bg-white w-[500px]">
                        <div className="flex items-center justify-between mb-3">
                            <p className="text-[18px]">Додати відео</p>
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { setStatusYoutubeModal(false) }}
                            >
                                <CloseOutlinedIcon />
                            </Button>
                        </div>
                        <Stack spacing={2} >
                            <TextField
                                id="outlined-basic"
                                label={<b>Введіть URL*</b>}
                                variant="outlined"
                                size="small"
                                value={youtubeSettings.url}
                                onChange={(event) => {
                                    setYoutubeSettings((state) => ({
                                        ...state,
                                        url: event.target.value
                                    }))
                                }}
                                onBlur={() => {
                                    setYoutubeSettingsError(state => ({
                                        ...state,
                                        url: ""
                                    }))
                                    try {
                                        yup.string().url("Невірний формат URL").required("Це поле обов'язкове").validateSync(youtubeSettings.url);
                                    } catch (err: any) {
                                        setYoutubeSettingsError(state => ({
                                            ...state,
                                            url: err.errors[0]
                                        }))
                                    }
                                }}
                                error={!!youtubeSettingsError.url}
                                helperText={<span>{youtubeSettingsError.url}</span>}
                            />
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Розмір відео</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={youtubeSettings.sizePattern}
                                    label="Розмір відео"
                                    onChange={(e) => {
                                        if (e.target.value === "other") {
                                            setYoutubeSettings((state) => ({
                                                ...state,
                                                sizePattern: e.target.value,
                                                width: 35,
                                                height: 35,
                                            }))
                                        } else {
                                            setYoutubeSettings((state) => ({
                                                ...state,
                                                sizePattern: e.target.value,
                                                width: parseInt(e.target.value.split("x")[0]),
                                                height: parseInt(e.target.value.split("x")[1]),
                                            }))
                                        }
                                    }}
                                >
                                    <MenuItem value={"560x315"}>560x315</MenuItem>
                                    <MenuItem value={"640x360"}>640x360</MenuItem>
                                    <MenuItem value={"853x480"}>853x480</MenuItem>
                                    <MenuItem value={"1280x720"}>1280x720</MenuItem>
                                    <MenuItem value={"other"}>Інший</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                id="outlined-basic"
                                label={<b>Ширина*</b>}
                                variant="outlined"
                                size="small"
                                type="number"
                                value={youtubeSettings.width}
                                onChange={(event) => {
                                    setYoutubeSettings((state) => ({
                                        ...state,
                                        sizePattern: "other",
                                        width: parseInt(event.target.value) > 34 ? parseInt(event.target.value) : 35
                                    }))
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label={<b>Висота*</b>}
                                variant="outlined"
                                size="small"
                                type="number"
                                value={youtubeSettings.height}
                                onChange={(event) => {
                                    setYoutubeSettings((state) => ({
                                        ...state,
                                        sizePattern: "other",
                                        height: parseInt(event.target.value) > 34 ? parseInt(event.target.value) : 35
                                    }))
                                }}
                            />
                        </Stack>
                        <Button
                            size="small"
                            sx={{ display: "block", mx: "auto", my: "10px" }}
                            variant="outlined"
                            disabled={!!youtubeSettingsError.url}
                            onClick={() => {
                                setYoutubeSettings({
                                    url: "",
                                    sizePattern: "560x315",
                                    width: 560,
                                    height: 315
                                })
                                setYoutubeSettingsError({
                                    url: ""
                                })
                                editor.chain().focus().setYoutubeVideo({
                                    src: youtubeSettings.url,
                                    width: youtubeSettings.width,
                                    height: youtubeSettings.height
                                }).run()
                                setStatusYoutubeModal(false)
                            }}
                        >
                            OK
                        </Button>
                        <p className="text-[12px]">Настискаючи «ОК», ви засвідчуєте, що завантажуваний вами контент відповідає <a className="text-[#3867f5] underline" href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer nofollow">Умови використання YouTube (включаючи Принципи спільноти YouTube).</a></p>
                        <p className="text-[12px]">Переконайтеся, що ви не порушуєте авторських прав або прав на недоторканність приватного життя інших осіб</p>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}