import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Link from '@tiptap/extension-link'
import Youtube from '@tiptap/extension-youtube'
import MenuBar from './menuBar'
import { FontSize } from './fontSizeExtensions'
import { Indent } from './fontIndentExtensions'
import { CustomTableCell } from './tableCellExtensions'
import BubbleMenuExtensions from './bubbleMenu'

const extensions = [
    TextStyle,
    Underline,
    Indent,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
        defaultAlignment: ""
    }),
    Table.configure({
        resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    CustomTableCell,
    FontSize.configure({ types: [TextStyle.name, ListItem.name] }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
    }),
    Link.configure({
        openOnClick: false,
        autolink: false,
    }),
    Youtube.configure({
        controls: false,
        inline: true,
        width: 560,
        height: 315
    })
]

export default function AdvancedField({ title }: { title: string }) {
    return (
        <div className='relative mb-[10px] p-2 border rounded border-black/25 min-h-[174px]'>
            <div className='text-[12px] text-black/55 absolute top-[-10px] left-2 bg-white px-[5px]'>
                {title}
            </div>
            <EditorProvider
                extensions={extensions}
                slotBefore={<MenuBar />}
                editorProps={{
                    attributes: {
                        class: 'p-3 border-2 rounded border-black/25',
                    }
                }}
            >
                <BubbleMenuExtensions />
            </EditorProvider>
        </div>
    )
}