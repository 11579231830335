import { useAppDispatch } from "../../../../store/hooks";

import axiosAuth from "../../../../API/auth";

import { clearValue, errorSet, hideModal, initModal, setErrorText, setSendStatus, submitFunks } from "../../../../slices/modal/slice";
import { userClear } from "../../../../slices/user/slice";
import { switchCurrentProductTypes, typesProductsAdd } from "../../../../slices/characteristic/slice";

export default function TypesProductAdd() {
    const dispatch = useAppDispatch();

    return (
        <button onClick={(e) => {
            submitFunks.addTypeProduct = (modalValue: string) => {
                if (!modalValue) {
                    dispatch(errorSet("Введіть назву"));
                } else {
                    dispatch(setSendStatus("loading"))

                    axiosAuth()
                    .put("/product_types/", JSON.stringify([{ name: modalValue }]), {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(response => {
                        dispatch(setSendStatus("idle"))
                        dispatch(typesProductsAdd(response.data[0]));
                        dispatch(switchCurrentProductTypes(response.data[0]))
                        dispatch(clearValue());
                        dispatch(hideModal());
                    })
                    .catch(err => {
                        if (err === "logaut") {
                            dispatch(userClear());
                        } else {
                            console.error(err);
                            dispatch(setErrorText(err.response.data.error))
                            dispatch(setSendStatus("error"));
                            setTimeout(() => {
                                dispatch(setSendStatus("idle"))
                            }, 2400);
                        }
                    })
                }
            }

            dispatch(initModal({
                btnText: "Добавити",
                placeholderText: "Назва категорії",
                submitFunkID: "addTypeProduct"
            }))
        }} className="absolute z-40 md:w-auto h-[55px] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5  right-0">
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z" fill="#000000" />
            </svg>
        </button>
    )
}