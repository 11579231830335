import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { clearValue, errorSet, hideModal, initModal, submitFunks } from "../../../slices/modal/slice";
import { sectionsPageAdd } from "../../../slices/sectionsPage/slice";

import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';

import { SectionsPageType } from "../../../slices/sectionsPage/interfaces";

export default function SectionsAdd({ element }: { element: SectionsPageType }) {
    const currentLanguage = useAppSelector(state => state.sectionsPage.currentLanguage);
    const sectionsPage = useAppSelector(state => state.sectionsPage.sectionsPage);

    const dispatch = useAppDispatch();

    function extractIds(data: SectionsPageType[]) {
        let result: number[] = [];

        function extractIdsRecursively(node: SectionsPageType) {
            result.push(node.id || node.id_front || 0);

            if (node.children.length > 0) {
                node.children.forEach(extractIdsRecursively);
            }
        };

        data.forEach(extractIdsRecursively);

        return result;
    };

    const maxID = useMemo(() => {
        return Math.max(...extractIds(sectionsPage || [])) + 1
    }, [sectionsPage])
    
    return (
        <IconButton
            size="small"
            onClick={() => {
                submitFunks.addSection = (modalValue: string) => {
                    const names = element.children.map(item => item.name);
                    const isEqual = names.some(name => name[currentLanguage] === modalValue);

                    if (isEqual) {
                        dispatch(errorSet("Такий розділ уже існує"));
                    } else if (!modalValue) {
                        dispatch(errorSet("Введіть назву"));
                    } else {
                        dispatch(errorSet(""));

                        dispatch(sectionsPageAdd({
                            id: element.id ? element.id : element.id_front || 0,
                            section: {
                                id: maxID,
                                name: modalValue
                            }
                        }));

                        dispatch(clearValue());
                        dispatch(hideModal());
                    }
                }

                dispatch(initModal({
                    btnText: "Добавити",
                    placeholderText: "Назва підрозділу",
                    submitFunkID: "addSection"
                }))
            }}>
            <AddIcon fontSize="medium" />
        </IconButton>
    )
}