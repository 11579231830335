////////////поправити///////////////
//////////////////////////
////////////////////////
//////////////////////
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import * as yup from 'yup';
import { useFormik } from "formik";

import { Link as MLink, TextField } from '@mui/material';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ResetPasswordPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);
    const [errorsLogin, setErrorsLogin] = useState<1001 | 1002 | 1003 | 1004 | 1005 | null>(null)

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: yup.object({
            email: yup.string().email("Невірний формат пошти").required("Це поле обов'язкове")
        }),
        onSubmit: (values) => {
            setLoading(true);

            const formData = new FormData();

            formData.append("email", values.email);

            axios.post("/api/reset_password/", formData)
            .then(response => {
                setLoading(false);
                setSuccess(true);

                setTimeout(() => {
                    setSuccess(false);
                }, 2000)
            }).catch(response => {
                setLoading(false);
                console.log(response)
            })
        }
    })

    useEffect(() => {
        if ((Object.values(formik.errors).length === 0) && Object.values(formik.values).every(item => item)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formik.values, formik.errors]);

    let errorsMessage: JSX.Element = <></>;

    switch (errorsLogin) {
        case 1001:
            errorsMessage = <p className='text-[14px] text-red-600'>Користувача з таким email немає.</p>
            break;
        case 1002:
            errorsMessage = <p className='text-[14px] text-red-600'>Неправильний пароль.</p>
            break;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5" sx={{textAlign: "center", marginBottom: 2}}>
                    Введіть пошту для скидування паролю.
                </Typography>
                <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                {errorsMessage}
                <LoadingButton
                    fullWidth
                    type="submit"
                    size="large"
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    disabled={disabled}
                    sx={{ mt: 3, mb: 2 }}
                >
                    <span>{success ? "Лист відправлено!" : "Відправити лист"}</span>
                </LoadingButton>
                <div className='flex items-center gap-4 mb-2'>
                    <Typography component="p" sx={{ textAlign: "center" }}>
                        Згадали пароль?
                    </Typography>
                    <Link to={"/login"}>
                        <MLink href="#" variant="body2">
                            Увійти
                        </MLink>
                    </Link>
                </div>
            </Box>
        </Container>
    )
}