import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";

import Button from "@mui/material/Button";

import { clearEasydrop } from "../../../slices/integrations/easydrop/slice";
import { clearHoroshop } from "../../../slices/integrations/horoshop/slice";
import { clearProm } from "../../../slices/integrations/prom/slice";

import SubmitModalProps from "./interface";

export default function SubmitModal({ isSuccess, setIsSuccess, resetForm }: SubmitModalProps) {
    const dispatch = useAppDispatch()

    if (isSuccess) {
        return (
            <div className='fixed flex gap-4 p-4 bottom-4 right-4  bg-white shadow rounded' onClick={() => {
                setIsSuccess(false);
            }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(clearEasydrop())
                        dispatch(clearHoroshop())
                        dispatch(clearProm())
                        window.scrollTo(0, 0);
                        resetForm();
                        setIsSuccess(false);
                    }}>Додати новий товар</Button>
                <Button
                    variant="outlined"
                    color="secondary">
                    <Link to="/">Перейти в таблицю</Link>
                </Button>
            </div>
        )
    } else {
        return null
    }
}