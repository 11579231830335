import { createSlice } from "@reduxjs/toolkit";
import { PromSlice } from "./interfaces";
import { SetPromCategoriesType, SetPromType, UpdatePromDetailsType } from "./actions.interfaces";

const initialState: PromSlice = {
    isActive: false,

    сategoriesList: [],

    data: {
        auto_discount: false,
        discount_period: 0,
        discount_finish: "",
        discount_start: "",
        group: {
            id: 0,
            name: ""
        },
        label: "",
        prom_category: {
            id: 0,
            name: ""
        },
        search_ru: "",
        search_uk: ""
    }
}

const promSlice = createSlice({
    name: "prom",
    initialState,
    reducers: {
        setProm: (state, action: SetPromType) => {
            if (action.payload) {
                const { details } = action.payload;

                state.isActive = true

                state.data.auto_discount = details?.auto_discount || false;
                state.data.discount_period = details?.discount_period || 0;
                state.data.discount_finish = details?.discount_finish || "";
                state.data.discount_start = details?.discount_start || "";
                state.data.group = details?.group || {
                    id: 0,
                    name: ""
                }
                state.data.label = details?.label || ""
                state.data.prom_category = details?.prom_category || {
                    id: 0,
                    name: ""
                }
                state.data.search_ru = details?.search_ru || "";
                state.data.search_uk = details?.search_uk || "";
            } else {
                state.isActive = false
            }
        },

        updatePromDetails: (state, actions: UpdatePromDetailsType) => {
            if (typeof actions.payload.value === "string") {
                switch (actions.payload.label) {
                    case "discount_finish":
                        state.data.discount_finish = actions.payload.value;
                        break;
                    case "discount_start":
                        state.data.discount_start = actions.payload.value;
                        break;
                    case "label":
                        state.data.label = actions.payload.value;
                        break;
                    case "search_uk":
                        state.data.search_uk = actions.payload.value;
                        break;
                    case "search_ru":
                        state.data.search_ru = actions.payload.value;
                        break;

                }
            } else if (typeof actions.payload.value === "number") {
                switch (actions.payload.label) {
                    case "discount_period":
                        state.data.discount_period = actions.payload.value;
                        break;
                }
            } else if (typeof actions.payload.value === "boolean") {
                state.data.auto_discount = actions.payload.value;
            } else {
                switch (actions.payload.label) {
                    case "group":
                        let newGroup = actions.payload.value;

                        if (actions.payload.value.id === state.data.group.id) {
                            newGroup = {
                                id: 0,
                                name: ""
                            }
                        }

                        state.data.group = newGroup;
                        break;
                    case "prom_category":
                        state.data.prom_category = actions.payload.value;
                        break;
                }
            }
        },

        setPromCategories: (state, actions: SetPromCategoriesType) => {
            state.сategoriesList = actions.payload;
        },

        clearProm: (state) => {
            state.data.auto_discount = false;
            state.data.discount_period = 0;
            state.data.discount_finish = "";
            state.data.discount_start = "";
            state.data.group = {
                id: 0,
                name: ""
            }
            state.data.label = ""
            state.data.prom_category = {
                id: 0,
                name: ""
            }
            state.data.search_ru = "";
            state.data.search_uk = "";
        }
    }
})

const { reducer, actions } = promSlice;

export default reducer;

export const {
    setProm,
    updatePromDetails,
    setPromCategories,
    clearProm
} = actions