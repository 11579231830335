import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import * as yup from 'yup';

import { setName } from "../../../slices/link/slice";

import TextField from "@mui/material/TextField";

export default function NameField() {
    const name = useAppSelector(state => state.link.name);

    const [errorText, setErrorText] = useState<string>("");

    const dispatch = useAppDispatch();

    function onChange(newValue: string) {
        dispatch(setName(newValue))
    }

    return (
        <TextField
            fullWidth
            label="Назва посилання*"
            variant="outlined"
            value={name}
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => {
                setErrorText("")
                try {
                    yup.string().required("Це поле обов'язкове").validateSync(name);
                } catch (err: any) {
                    setErrorText(err.errors[0])
                }
            }}
            error={!!errorText}
            helperText={ <span>{errorText}</span> }
        />
    )
}