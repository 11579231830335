import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";

import axiosAuth from "../../../../../API/auth";

import { userClear } from "../../../../../slices/user/slice";

import LoadingButton from "@mui/lab/LoadingButton";
import { SvgIcon } from "@mui/material";
import { typesProductsRemove } from "../../../../../slices/characteristic/slice";
import { setErrorText } from "../../../../../slices/modal/slice";

export default function RemoveButton({ id }: { id: number }) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <LoadingButton
            id="remove_type"
            loading={loading}
            loadingPosition="center"
            startIcon=""
            sx={{p: "0", minWidth: "28px", borderRadius: "50%"}}
            onClick={() => {
                setLoading(true)
                axiosAuth()
                .delete(`/product_types/?id=${id}`)
                .then(() => {
                    dispatch(typesProductsRemove(id));
                    setLoading(false);
                })
                .catch((err) => {
                    if (err === "logaut") {
                        dispatch(userClear());
                    } else {
                        console.error(err);
                        setLoading(false);
                        dispatch(setErrorText(err.response.data.error))
                    }
                })
            }}
        >
            {
                loading
                    ?
                    null
                    :
                    <SvgIcon>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </SvgIcon>
            }
        </LoadingButton>
    )
}