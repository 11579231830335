import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import Integration from '../../components/settings/integration';

import IntegrationsType from '../../components/settings/integration/interfaces';

export default function SettingsPage() {
    const response = useLoaderData() as any as { data: IntegrationsType[] };

    const [categories, setSategories] = useState<string[]>([]);
    const [integrations, setIntegrations] = useState<IntegrationsType[]>([]);
    const [value, setValue] = useState('0');

    useEffect(() => {
        setIntegrations(response.data);
        
        const tempCategories: string[] = [];
        response.data.forEach((item: any) => {
            if (!tempCategories.includes(item.category)) {
                tempCategories.push(item.category);
            }
        });

        setSategories(tempCategories);
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', height: "90vh", typography: 'body1', position: "relative" }}>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                        {
                            categories.map((item, i) => (
                                <Tab key={i} label={item} value={i + ""} />
                            ))
                        }
                    </TabList>
                </Box>
                {
                    categories.map((item, i) => (
                        <TabPanel key={i} value={i + ""}>
                            <div className='flex flex-wrap justify-around gap-2'>
                                {
                                    integrations.map(integration => (
                                        <Integration key={integration.id} data={integration} />
                                    ))
                                }
                            </div>
                        </TabPanel>
                    ))
                }
            </TabContext>
        </Box>
    )
}