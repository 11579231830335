import { useAppSelector } from "../../../store/hooks";

import Branch from "../branch";

export default function SectionsList() {
    const sectionsPage = useAppSelector(state => state.sectionsPage.sectionsPage);

    return (
        <div className="px-4 overflow-x-auto">
            
            {
                sectionsPage
                ? <>
                    <Branch key={sectionsPage[0].id} parent={sectionsPage[0]} node={sectionsPage[0]} level={0} />
                </>
                : <div>Відсутні дані</div>
            }
        </div>
    );
}