import { useEffect, useState } from "react"
import { useLoaderData } from "react-router-dom"

import axiosAuth from "../../../API/auth"

import { FormControlLabel, IconButton, InputAdornment, Switch, TextField } from "@mui/material"

import SubmitBtn from "../../../components/shared/submitBtn"

import IntegrationInfoType from "./interfaces"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

export default function HoroshopSettingsPage() {
    const response = useLoaderData() as any as { data: IntegrationInfoType }

    const [data, setData] = useState<IntegrationInfoType>()

    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(() => {
        setData({
            user_integration: response.data.user_integration
        })
    }, [])

    function onSubmit() {
        const allSendData = {
            settings: {
                domain: data?.user_integration.settings.domain,
                login: data?.user_integration.settings.login,
                password: data?.user_integration.settings.password
            },
            is_active: data?.user_integration.is_active,
            integration_id: data?.user_integration.integration_id
        }

        return axiosAuth()
            .post("/user_integrations/", JSON.stringify(allSendData), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
    }

    return (
        <main className="w-[600px] p-5 mx-auto">
            <p className="text-center text-[22px] ">{data?.user_integration.name}</p>
            <div className="px-2">
                <FormControlLabel
                    control={
                        <Switch
                            checked={data?.user_integration.is_active || false}
                            onChange={() => {
                                setData(state => {
                                    if (state) {
                                        return {
                                            user_integration: {
                                                ...state?.user_integration,
                                                is_active: !state?.user_integration.is_active
                                            }
                                        }
                                    }
                                })
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={(data?.user_integration.is_active ? "Виключити" : "Включити") + " інтеграцію"}
                />
            </div>

            <TextField
                disabled={!data?.user_integration.is_active}
                sx={{ my: "15px" }}
                fullWidth
                label="Домен"
                variant="outlined"
                value={data?.user_integration.settings.domain || ""}
                onChange={(e) => {
                    setData(state => {
                        if (state) {
                            return {
                                user_integration: {
                                    ...state?.user_integration,
                                    settings: {
                                        ...state?.user_integration.settings,
                                        domain: e.target.value
                                    }
                                }
                            }
                        }
                    })
                }}
            />

            <TextField
                disabled={!data?.user_integration.is_active}
                sx={{ my: "15px" }}
                fullWidth
                label="Логін"
                variant="outlined"
                value={data?.user_integration.settings.login || ""}
                onChange={(e) => {
                    setData(state => {
                        if (state) {
                            return {
                                user_integration: {
                                    ...state?.user_integration,
                                    settings: {
                                        ...state?.user_integration.settings,
                                        login: e.target.value
                                    }
                                }
                            }
                        }
                    })
                }}
            />
            <TextField
                disabled={!data?.user_integration.is_active}
                sx={{ my: "15px" }}
                fullWidth
                label="Пароль"
                variant="outlined"
                value={data?.user_integration.settings.password || ""}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                    setData(state => {
                        if (state) {
                            return {
                                user_integration: {
                                    ...state?.user_integration,
                                    settings: {
                                        ...state?.user_integration.settings,
                                        password: e.target.value
                                    }
                                }
                            }
                        }
                    })
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(state => !state)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <SubmitBtn
                request={onSubmit}
                handlerResponse={() => { }}
            />
        </main>
    )
}