import { useCurrentEditor } from "@tiptap/react"

import { Divider } from "@mui/material"

import TextType from "../actions/textType";
import TextSize from "../actions/textSize";
import TextFormat from "../actions/textFormat";
import List from "../actions/list";
import TextAlign from "../actions/textAlign";
import TextIndent from "../actions/textIndent";
import HistoryEditor from "../actions/history";
import Link from "../actions/link";
import YouTube from "../actions/youTube";
import Table from "../actions/table";

export default function MenuBar() {
    const { editor } = useCurrentEditor()

    return (
        <>
            <div className="flex items-center gap-2 my-3">
                <TextType />
                <TextSize />
                <TextFormat />
                <Divider orientation="vertical" flexItem />
                <List />
                <Divider orientation="vertical" flexItem />
                <div className={editor?.isActive('table') ? "hidden" : "flex items-center gap-2"}>
                    <TextAlign />
                </div>
                <Divider orientation="vertical" flexItem />
                <TextIndent />
                <Divider orientation="vertical" flexItem />
                <HistoryEditor />
                <Divider orientation="vertical" flexItem />
                <Link />
                <YouTube />
                <p onClick={() => {
                    editor?.commands.setContent(`
                    
                    <p style="text-align:center"><strong>фівфівфі</strong></p>

<table border="1" style="width:500px">
	<tbody>
		<tr>
			<td>asdasd</td>
			<td>asdasd</td>
		</tr>
		<tr>
			<td style="text-align:center">zxczcasdadsdad</td>
			<td>asda</td>
		</tr>
		<tr>
			<td>asdasdasdsdasdasd</td>
			<td>asdasd</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>

                    
                    `)
                }}></p>
            </div>
            <Table />
        </>
    )
}