import { Alert, AlertTitle, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import CloseIcon from '@mui/icons-material/Close';
import { setErrorText } from "../../../slices/modal/slice";

export default function ErrorMessage() {
    const errorText = useAppSelector(state => state.modal.errorModal.content);

    const dispatch = useAppDispatch();

    return (
        <div className="fixed">
            {
                errorText.map((item, i) => (
                    <div key={i} className="mb-2 bg-white z-[150]">
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        dispatch(setErrorText(item))
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            <AlertTitle>Error</AlertTitle>
                            {item}
                        </Alert>
                    </div>
                ))
            }
        </div>
    )
}