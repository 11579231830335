import { useCurrentEditor } from "@tiptap/react"

import { FormControl, MenuItem, Select } from "@mui/material"

export default function TextType() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <FormControl
                size="small"
                sx={{ m: 0, width: 150 }}
            >
                <Select
                    value={(() => {
                        if (editor.isActive('heading', { level: 2 })) return "Заголовок 2"
                        if (editor.isActive('heading', { level: 3 })) return "Заголовок 3"
                        return "Нормальний"
                    })()}
                    onChange={(e) => {
                        if (e.target.value === "Заголовок 2") {
                            editor.chain().focus().toggleHeading({ level: 2 }).run()
                        }
                        if (e.target.value === "Заголовок 3") {
                            editor.chain().focus().toggleHeading({ level: 3 }).run()
                        }
                        if (e.target.value === "Нормальний") {
                            editor.chain().focus().setParagraph().run()
                        }
                    }}
                    renderValue={(selected) => (
                        <span>{selected.toLocaleString()}</span>
                    )}
                >
                    <MenuItem value={"Заголовок 2"}><span className="text-[1.5em]">Заголовок 2</span></MenuItem>
                    <MenuItem value={"Заголовок 3"}><span className="text-[1.25em]">Заголовок 3</span></MenuItem>
                    <MenuItem value={"Нормальний"}>Нормальний</MenuItem>
                </Select>
            </FormControl>
        )
    } else {
        return null
    }
}