import { createSlice } from "@reduxjs/toolkit";

import EasydropSlice, { ProductsType } from "./interfaces";
import {
    GetEasydropSupplierProductsType,
    SetEasydropPaginatonLimitType,
    SetEasydropSearchTextType,
    SetEasydropType,
    UpdateEasydropSelectedProductsType
} from "./actions.interfaces"

const initialState: EasydropSlice = {
    isActive: false,

    suppliers: [],
    suppliersProducts: {
        productsList: [],
        paginationLimit: 20,
        total_items: 0,
        searchText: ""
    },
    selectedProducts: [],
    selectedProductsIDs: {},
}

const easydropSlice = createSlice({
    name: "easydrop",
    initialState,
    reducers: {
        setEasydrop: (state, action: SetEasydropType) => {
            if (action.payload) {
                state.isActive = true;

                state.suppliers = action.payload.suppliers || [];
                state.selectedProductsIDs = action.payload.products.reduce((obj: Record<string, number>, item: ProductsType) => {
                    return { ...obj, ...{ [item.supplier_id]: item.id } }
                }, {}) || {};
    
                state.selectedProducts = action.payload.products || [];
            } else {
                state.isActive = false;
            }
        },

        updateEasydropSelectedProducts: (state, action: UpdateEasydropSelectedProductsType) => {
            const product = action.payload;

            let IDs: Record<string, number> = JSON.parse(JSON.stringify(state.selectedProductsIDs));
            let data: ProductsType[] = JSON.parse(JSON.stringify(state.selectedProducts));

            if (product.supplier_id) {
                if (product.supplier_id in IDs) {
                    if (IDs[product.supplier_id] === product.id) {
                        data = data.filter(item => item.id !== product.id)
                        delete IDs[product.supplier_id]
                    } else {
                        data = data.filter(item => item.supplier_id !== product.supplier_id)
                        data.push(product);

                        IDs[product.supplier_id] = product.id;
                    }
                } else {
                    data = data.filter(item => item.supplier_id !== product.supplier_id)
                    data.push(product);

                    IDs[product.supplier_id] = product.id
                }
            }

            state.selectedProducts = data;
            state.selectedProductsIDs = IDs;
        },

        setEasydropSupplierProducts: (state, action: GetEasydropSupplierProductsType) => {
            state.suppliersProducts.productsList = action.payload.products;
            state.suppliersProducts.total_items = action.payload.total_items;
        },

        setEasydropPaginatonLimit: (state, action: SetEasydropPaginatonLimitType) => {
            state.suppliersProducts.paginationLimit = action.payload;
        },

        setEasydropSearchText: (state, action: SetEasydropSearchTextType) => {
            state.suppliersProducts.searchText = action.payload;
        },

        clearEasydrop: (state) => {
            state.selectedProducts = [];
            state.selectedProductsIDs = {};
        }
    }
})

const { reducer, actions } = easydropSlice;

export default reducer;

export const {
    setEasydrop,
    updateEasydropSelectedProducts,
    setEasydropSupplierProducts,
    setEasydropPaginatonLimit,
    setEasydropSearchText,
    clearEasydrop
} = actions