import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";

import axiosAuth from "../../../../API/auth";

import { userClear } from "../../../../slices/user/slice";

import { Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

import { objFiles } from "../../dropzone";

import { CharacteristicType } from "../../../../slices/characteristic/interfaces";
import { setErrorText } from "../../../../slices/modal/slice";

export default function SubmitButton({ setIsSuccess }: { setIsSuccess: React.Dispatch<React.SetStateAction<boolean>> }) {
    let { id } = useParams();
    let mode = useLocation().pathname.split("/")[2];

    const {
        base,
        base: { sku, ua_name, ua_description, price },
        pictures,
        availability,
        categoriesSelected,
        characteristicsSelected,
    } = useAppSelector(state => state.product);

    const easydropSelectedIDs = useAppSelector(state => state.easydrop.selectedProductsIDs)
    const mainSelectedCategory = useAppSelector(state => state.horoshop.mainSelectedCategory);
    const additionalSelectedCategories = useAppSelector(state => state.horoshop.additionalSelectedCategories);
    const promDetails = useAppSelector(state => state.prom.data);

    const { data: { characteristics: listCharacteristic } } = useLoaderData() as any as { data: { characteristics: CharacteristicType[] } }

    const [statusesButton, setStatusesButton] = useState<[string, "primary"] | [string, "success"] | [string, "error"]>([(mode === "add" || mode === "variation" ? "Додати" : "Зберегти зміни"), "primary"]);
    const [statusesError, setStatusesError] = useState<string>("");
    const [isLoadings, setIsLoadings] = useState<boolean>(false);

    const [helperTextDisabled, setHelperTextDisabled] = useState<string[]>([])

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setHelperTextDisabled([])

        if (!sku) setHelperTextDisabled(state => [...state, "Введіть значення в поле 'Артикул'"]);
        if (!ua_name) setHelperTextDisabled(state => [...state, "Введіть значення в поле 'Назва товару UA'"]);
        if (!ua_description) setHelperTextDisabled(state => [...state, "Введіть значення в поле 'Опис товару UA'"])
        if (!price) setHelperTextDisabled(state => [...state, "Введіть значення в поле 'Ціна'"])

        if (listCharacteristic.some(item => item.is_variation)) {
            const keysCharacteristicsSelected = Object.keys(characteristicsSelected);
            if (!keysCharacteristicsSelected.length || !keysCharacteristicsSelected.some(item => item.includes("v"))) {
                setHelperTextDisabled(state => [...state, "Виберіть одну із варіацій"])
            }
        }

        if (!pictures.length) setHelperTextDisabled(state => [...state, "Загрузіть принаймі одне фото"])

        let completedPromIntegration = true;

        if (Object.values(promDetails).some(item => {
            if (typeof item === "object") {
                return item?.id
            } else {
                return item
            }
        })) {

            completedPromIntegration = false
        }

        if (promDetails?.group?.id && promDetails?.prom_category?.id) {
            completedPromIntegration = true;
        }

        if (!completedPromIntegration) {
            setHelperTextDisabled(state => {
                return [...state, "Вам потрібно вибрати Prom категорію та відповідну їх категорію в нашій системі"]
            })
        }

        let completedHoroshopIntegration = true;

        if (additionalSelectedCategories && additionalSelectedCategories.length > 0) completedHoroshopIntegration = false;
        if (mainSelectedCategory) completedHoroshopIntegration = true;

        if (!completedHoroshopIntegration) {
            setHelperTextDisabled(state => {
                return [...state, "Вам потрібно вибрати Horoshop категорію"]
            })
        }

    }, [sku, ua_name, ua_description, price, characteristicsSelected, pictures, promDetails, mainSelectedCategory, additionalSelectedCategories])

    function onSubmit() {
        setIsLoadings(true);

        const formData = new FormData();

        pictures.forEach(item => {
            if (typeof item === "string") {
                formData.append('pictures', objFiles[item], objFiles[item].name);
            } else {
                const jsonString = JSON.stringify(item);

                const blob = new Blob([jsonString], { type: 'application/json' });

                formData.append('pictures', blob, `${item.id}.json`);
            }
        });

        let sendData: Record<string, any> = {};

        sendData.base = { ...base };

        if (mode === "variation") {
            delete sendData.base.id;
            sendData.base.parent_id = parseInt(id || "")
        } else if (mode === "add") {
            sendData.base.product_type_id = id;
        } else {
            sendData.base.id = id
        }

        sendData.availability = availability;

        sendData.characteristics = Object.fromEntries(Object.entries(characteristicsSelected).map(item => [parseInt(item[0]) + "", item[1]]));

        sendData.categories = Array.from(new Set(categoriesSelected));

        if (easydropSelectedIDs) {
            sendData.integrations = {
                ...sendData.integrations,
                easydrop: {
                    products: Object.values(easydropSelectedIDs)
                }
            };
        }

        if (promDetails?.prom_category.id) {
            sendData.integrations = {
                ...sendData.integrations,
                prom: {
                    auto_discount: promDetails.auto_discount,
                    discount_period: promDetails.discount_period,
                    discount_start: promDetails.discount_start || null,
                    discount_finish: promDetails.discount_finish || null,
                    group: {
                        id: promDetails.group.id
                    },
                    label: promDetails.label,
                    prom_category: {
                        id: promDetails.prom_category.id
                    },
                    search_ru: promDetails.search_ru,
                    search_uk: promDetails.search_uk
                }
            };
        }

        if (mainSelectedCategory) {
            sendData.integrations = {
                ...sendData.integrations,
                horoshop: {
                    category: mainSelectedCategory, 
                    additional_categories: additionalSelectedCategories.map(item => item.name)
                }
            }
        }

        formData.append('json', JSON.stringify(sendData));

        axiosAuth()
            .post((mode === "add" || mode === "variation" ? "/products/" : "/product/"), formData)
            .then(response => {
                setIsLoadings(false);
                setStatusesButton([(mode === "add" || mode === "variation" ? "Додано!" : "Збережено!"), "success"]);

                if (mode === "add") {
                    setIsSuccess(true);
                } else {
                    navigate("/");
                }
                setTimeout(() => {
                    setStatusesButton([(mode === "add" || mode === "variation" ? "Додати" : "Зберегти зміни"), "primary"]);
                }, 2500)
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setIsLoadings(false);

                    dispatch(setErrorText(err.response.data.error))

                    setStatusesError(err.response.data.error)
                    setStatusesButton([(mode === "add" || mode === "variation" ? "Не вдалося додати" : "Не вдалось зберегти зміни"), "error"]);
                    setTimeout(() => {
                        setStatusesError("")
                        setStatusesButton([(mode === "add" || mode === "variation" ? "Додати" : "Зберегти зміни"), "primary"]);
                    }, 2500)
                }
            })
    }

    return (
        <Tooltip
            title={helperTextDisabled.length > 0 ? Array.from(new Set(helperTextDisabled)).map((item, i) => <p key={i}>{item}</p>) : "Все заповнено"}
            placement="top"
            arrow
        >
            <div>
                <p className="text-center text-red-700">{statusesError === "Product with SKU already exists" ? "Товар з таким артикулом уже існує" : null}</p>
                <LoadingButton
                    fullWidth
                    size="large"
                    loading={isLoadings}
                    loadingPosition="center"
                    variant="contained"
                    endIcon={<SaveIcon fontSize="small" />}
                    color={statusesButton[1]}
                    disabled={!!helperTextDisabled.length}
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    <span>{statusesButton[0]}</span>
                </LoadingButton>
            </div>
        </Tooltip>
    )
}