import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import * as yup from 'yup';

import { deleteFormulaPrice, updateFormulaPrice } from "../../../slices/link/slice";

import { IconButton, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import FormulaProps from "./interfaces";

export default function Formula({ data, index, mode }: FormulaProps) {
    const stateUseFormulaMarkup = useAppSelector(state => state.link.use_formula_markup);

    const [errorText, setErrorText] = useState<string>("")

    const dispatch = useAppDispatch();

    useEffect(() => {
        setErrorText("")
        try {
            if ("to_price" in data) {
                yup.number().min(data.from_price + 1, `Значення повинно бути більшим за: ${data.from_price}`).validateSync(data.to_price);
            }
        } catch (err: any) {
            setErrorText(err.errors[0])
        }
    }, [data])

    return (
        <div className="w-full">
            <div className="flex gap-2 items-center my-2 relative w-full">
                {

                    mode === "first"
                        ? <div className="w-[32%] text-[30px] text-center text-[#7b7b7b]">0</div>
                        : <div className="w-[32%] text-[30px] text-center text-[#7b7b7b]">{data.from_price}</div>
                }
                <span className="max-[670px]:hidden">{"<"}</span>
                {
                    mode === "last"
                        ? <div className="w-[32%] text-[38px] text-center text-[#7b7b7b] flex-grow">
                            <AllInclusiveIcon fontSize="large" />
                        </div>
                        : <TextField
                            type="number"
                            label="До*"
                            variant="outlined"
                            value={(data.to_price + "") || "0"}
                            className="w-[32%]"
                            onChange={(e) => {
                                dispatch(updateFormulaPrice({
                                    index,
                                    change: {
                                        to_price: e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0 : 0
                                    }
                                }))
                            }}
                            error={!!errorText}
                            helperText={""}
                        />
                }
                <span className="max-[670px]:hidden">{"="}</span>
                <TextField
                    type="number"
                    label="Націнка*"
                    variant="outlined"
                    value={(data.mark_up + "") || "0"}
                    className="w-[32%]"
                    onChange={(e) => dispatch(updateFormulaPrice({
                        index,
                        change: {
                            mark_up: e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0 : 0
                        }
                    }))}
                />
                {
                    mode
                        ? null
                        : <div className="absolute right-[-50px]">
                            <IconButton
                                disabled={!stateUseFormulaMarkup}
                                aria-label="delete"
                                onClick={() => dispatch(deleteFormulaPrice(index))}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                }
            </div>
            {
                mode === "last"
                ? null
                : <p className="text-center text-[14px] text-red-900">{errorText}</p>
            }
        </div>
    )
}