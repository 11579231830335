import { useAppDispatch, useAppSelector } from "../../store/hooks";

import axiosAuth from "../../API/auth";

import { sectionsPageFetched, switchLanguage } from "../../slices/sectionsPage/slice";

import SubmitBtn from "../../components/shared/submitBtn";
import SectionsList from "../../components/sections/list";

export default function SectionsPage() {
    const sectionsPage = useAppSelector(state => state.sectionsPage.sectionsPage);
    const listLanguage = useAppSelector(state => state.sectionsPage.listLanguage);
    const currentLanguage = useAppSelector(state => state.sectionsPage.currentLanguage);

    const dispatch = useAppDispatch();

    function onSubmit() {
        return axiosAuth()
            .put("/cats/", JSON.stringify(sectionsPage), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
    }

    if (sectionsPage && sectionsPage.length !== 0) {
        return (
            <main className="flex flex-col justify-between min-h-full flex-grow">
                <div>
                    <div className="flex justify-end">
                        {
                            listLanguage.map((item, i) => (
                                <div
                                    key={i}
                                    style={{ cursor: "pointer", border: (item === currentLanguage) ? "1px solid #1976d2" : "1px solid white" }}
                                    className="m-2 p-1 flex w-fit text-sm"
                                    onClick={() => {
                                        if (item === "uk") {
                                            dispatch(switchLanguage(item));
                                        }
                                        if (item === "ru") {
                                            dispatch(switchLanguage(item));
                                        }
                                    }}
                                >
                                    {item.toLocaleUpperCase()}
                                </div>
                            ))
                        }
                    </div>
                    <SectionsList />
                </div>
                <SubmitBtn
                    request={onSubmit}
                    handlerResponse={(data) => {
                        dispatch(sectionsPageFetched(data));
                    }}
                />
            </main>
        )
    } else {
        return <div>Відсутні дані</div>;
    }
}