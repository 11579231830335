import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Link } from 'react-router-dom';

import axiosAuth from '../../../API/auth';

import { userClear } from '../../../slices/user/slice';
import { addProduct, deleteProductChild, selectAll, switchSelectProductsChilds, updateProduct, updateProducts } from '../../../slices/productsTable/slice';

import { IconButton, Menu, MenuItem, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

import ProductChildMenuProps from './interfaces';
import { setErrorText } from '../../../slices/modal/slice';

export default function ProductChildMenu({ parentControlElement, childControlElement }: ProductChildMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
    const open = Boolean(anchorEl);

    const allData = useAppSelector(selectAll);
    const selectedProductsChilds = useAppSelector(state => state.productsTable.selectedProductsChilds);
    const [deleteStatus, setDeleteStatus] = useState<"idle" | "loading" | "error">("idle");
    const [disconnectStatus, setDisconnectStatus] = useState<"idle" | "loading" | "error">("idle");

    const dispatch = useAppDispatch();

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    };
    function handleClose() {
        setAnchorEl(null);
    };

    function deleteProduct() {
        setDeleteStatus("loading");

        axiosAuth()
            .delete("/product/?product_ids=" + childControlElement.id)
            .then(() => {
                dispatch(deleteProductChild([{
                    id: parentControlElement.id,
                    changes: {
                        children: parentControlElement.children?.filter(item => item.id !== childControlElement.id)
                    }
                }]));

                if (selectedProductsChilds[parentControlElement.id]?.includes(childControlElement.id)) {
                    dispatch(switchSelectProductsChilds({
                        parentId: parentControlElement.id,
                        childId: childControlElement.id
                    }))
                }
                setDeleteStatus("idle");
                handleClose();
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                    setDeleteStatus("error");
                    setTimeout(() => setDeleteStatus("idle"), 2400)
                }
            });
    };

    function disconnectProduct() {
        setDisconnectStatus("loading");

        axiosAuth()
            .delete("/variations/?children=" + childControlElement.id)
            .then(() => {
                const childrenElem = allData.find(item => {
                    return item.id === parentControlElement.id
                })?.children?.find(item => {
                    return item.id === childControlElement.id
                });

                if (childrenElem) {
                    dispatch(addProduct(childrenElem));
                }

                dispatch(updateProducts([
                    {
                        id: parentControlElement.id,
                        changes: {
                            children: allData.find(item => item.id === parentControlElement.id)?.children?.filter(item => {
                                return item.id !== childControlElement.id
                            })
                        }
                    },
                    {
                        id: childControlElement.id,
                        changes: {
                            parent_id: undefined
                        }
                    }
                ]))

                if (selectedProductsChilds[parentControlElement.id]?.includes(childControlElement.id)) {
                    dispatch(switchSelectProductsChilds({
                        parentId: parentControlElement.id,
                        childId: childControlElement.id
                    }))
                }
                setDisconnectStatus("idle");
                handleClose();
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                    setDisconnectStatus("error");
                    handleClose();
                }
            });
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={disconnectProduct}>
                    <LoadingButton
                        sx={[
                            { color: "#212121", padding: "0", textTransform: "none", fontSize: 16 }, {
                                '&:hover': {
                                    backgroundColor: "unset",
                                },
                            }]}
                        loading={disconnectStatus === "loading"}
                        loadingPosition="start"
                        startIcon={disconnectStatus === "error" ? <CancelSharpIcon sx={{ marginRight: "5px" }} /> : <DeleteIcon sx={{ ml: "5px" }} />}
                        variant="text"
                    >
                        Від'єднати
                    </LoadingButton>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <Link to={"/product/edit/" + childControlElement.id}>
                        <EditIcon sx={{ marginRight: "5px" }} />
                        Редагувати
                    </Link>
                </MenuItem>
                <MenuItem onClick={deleteProduct}>
                    <LoadingButton
                        sx={[
                            { color: "#212121", padding: "0", textTransform: "none", fontSize: 16 }, {
                                '&:hover': {
                                    backgroundColor: "unset",
                                },
                            }]}
                        loading={deleteStatus === "loading"}
                        loadingPosition="start"
                        startIcon={deleteStatus === "error" ? <PriorityHighIcon sx={{ ml: "5px" }} /> : <DeleteIcon sx={{ ml: "5px" }} />}
                        variant="text"
                    >
                        Видалити
                    </LoadingButton>
                </MenuItem>
            </Menu>
        </div>
    );
}