import { createSlice } from "@reduxjs/toolkit";
import UserType from "./interfaces";

const initialState: UserType = {
    userStatus: "loading",
    data: {
        info: {
            email: "",
            id: 0,
            is_admin: false, 
            is_confirmed: false,
            locale: "",
            name: "",
            phone: "",
            registrant_type: ""
        },
        roots: {},
        settings: {
            integrations: []
        }
    }
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userFetching: state => { state.userStatus = 'loading' },
        userFetchingError: state => { state.userStatus = 'error' },

        userFetched: (state, action) => {
            state.data = action.payload.user;
            state.userStatus = 'idle';
        },

        userClear: state => {
            state.userStatus = 'error';
            state.data = {
                info: {
                    email: "",
                    id: 0,
                    is_admin: false, 
                    is_confirmed: false,
                    locale: "",
                    name: "",
                    phone: "",
                    registrant_type: ""
                },
                roots: {},
                settings: {
                    integrations: []
                }
            };
        }
    }
})

const { reducer, actions } = userSlice;

export default reducer;

export const {
    userFetching,
    userFetchingError,

    userFetched,

    userClear
} = actions;