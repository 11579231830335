import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { addSelectedCategories, removeSelectedCategories} from "../../../../slices/product/slice";

import Checkbox from "@mui/material/Checkbox";
import { Divider, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import BranchProps from "./interfaces";
import { updatePromDetails } from "../../../../slices/integrations/prom/slice";

export default function Branch({ node, level, onSelectedChildren, isUnSelectParent }: BranchProps) {
    const selectedData = useAppSelector(state => state.product.categoriesSelected);
    const promDetails = useAppSelector(state => state.prom.data);

    const [selectedChildren, setSelectedChildren] = useState<number[]>([])
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (node.children.length > 0) {
            if (selectedChildren.length > 0) {
                dispatch(addSelectedCategories(node.id || 0))
                onSelectedChildren?.(state => {
                    return [...state, node.id || 0]
                });
            }
        }
    }, [selectedChildren]);

    useEffect(() => {
        if (isUnSelectParent) {
            dispatch(removeSelectedCategories(node.id || 0));
        }
    }, [isUnSelectParent])

    return (
        <div
            className="pl-2 py-2">
            <div
                className={"min-w-[290px] flex items-center justify-between px-2 py-2 rounded-md text-gray-500 " + ((node.children.length > 0) ? "cursor-pointer" : "")}
                style={{ marginLeft: `${level * 15}px`, boxShadow: "0px 0px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setIsOpen((state) => !state)
                    }
                }}
            >
                <span className="cursor-text">{node.name["uk"]}</span>
                <div className="flex gap-4 items-center">
                    {
                        promDetails
                            ? (
                                <>
                                    <Checkbox
                                        sx={{ marginLeft: "auto" }}
                                        checked={node.id && promDetails?.group.id ? promDetails.group.id === node.id : false}
                                        onChange={() => {
                                            dispatch(updatePromDetails({
                                                label: "group",
                                                value: {
                                                    id: node.id || 0,
                                                    name: node.name["uk"]
                                                }
                                            }));
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                </>
                            ) : null
                    }
                    <Checkbox
                        sx={{ marginLeft: "auto" }}
                        checked={node.id ? selectedData.includes(node.id) : false}
                        onChange={() => {
                            onSelectedChildren?.(state => {
                                if (selectedData.includes(node.id || 0)) {
                                    return state.filter(item => item !== (node.id || 0))
                                } else {
                                    return [...state, node.id || 0]
                                }
                            })
                            if (selectedData.includes(node.id || 0)) {
                                dispatch(removeSelectedCategories(node.id || 0));
                            } else {
                                dispatch(addSelectedCategories(node.id || 0));
                            }
                        }}
                    />
                    {
                        (node.children.length > 0)
                            ?
                            <>
                                <Divider orientation="vertical" flexItem />
                                <IconButton
                                    onClick={() => setIsOpen((state) => !state)}
                                    size="small"
                                >
                                    {
                                        (isOpen)
                                            ? <KeyboardArrowUpIcon fontSize="large" />
                                            : <KeyboardArrowDownIcon fontSize="large" />
                                    }
                                </IconButton>
                            </>
                            : <div className="w-[62px]"></div>
                    }
                </div>
            </div>
            {
                <div className={(isOpen && node.children.length) ? "mt-2 block" : "hidden"}>
                    {node.children.map((child) => (
                        <Branch
                            key={child.id}
                            node={child}
                            level={level + 1}
                            onSelectedChildren={setSelectedChildren}
                            isUnSelectParent={!(node.id ? selectedData.includes(node.id) : false)}
                        />
                    ))}
                </div>
            }
        </div >
    )
}