import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { CircularProgress, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import ProductProps from "./interfaces";
import { updateEasydropSelectedProducts } from "../../../../slices/integrations/easydrop/slice";

export default function Product({ item, onSubmit }: ProductProps) {
  const suppliers = useAppSelector((state) => state.easydrop.suppliers);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  return (
    <div className="w-full relative max-[1024px]:flex max-[1024px]:justify-center">
      <div
        key={item.id}
        className="w-[100%] max-[1024px]:w-fit hover:bg-sky-100 cursor-pointer transition shadow p-4 flex items-center overflow-x-auto max-[1024px]:flex-col-reverse"
        onClick={(e) => {
          const target = e.target as HTMLElement;

          if (!target.closest(".MuiButtonBase-root")) {
            onSubmit(item.supplier_id || 0, setLoading);
          }
        }}
      >
        <div>
          <p className="font-bold mb-2 text-[20px] max-[1024px]:text-center">
            {suppliers.filter(
              (supplier) => supplier.id === item.supplier_id
            )[0]?.name || "Невідомий постачальник"}
          </p>
          <div className="flex items-center w-[100%] max-[1024px]:flex-col max-[1024px]:gap-4">
            <img
              className="object-cover object-center w-[150px] h-[100px]"
              src={item.image_url}
              alt=""
            />
            <div className="border-r border-slate-200 border-solid px-2 w-[300px] max-[1024px]:border-0 max-[1024px]:w-[260px] max-[400px]:w-[200px]">
              <p className="font-bold text-center text-[18px]">{item.name}</p>
            </div>
            <div className="border-r border-slate-200 border-solid px-2 w-[100px] max-[1024px]:border-0 max-[1024px]:w-[260px] max-[400px]:w-[200px]">
              <p className="font-bold text-center text-[18px]">{item.price}</p>
            </div>
            <div className="px-2 flex items-center justify-center gap-2 flex-wrap border-r max-[1024px]:border-0 border-slate-200 border-solid max-[1094px]:flex-nowrap max-[1100px]:max-w[160px]">
              {item.sizes.map((size, i) => (
                <div key={i} className="flex flex-col items-center">
                  <p className="font-bold">{size.size}</p>
                  <p className="border border-slate-600 border-solid rounded px-2 py-1">
                    {size.qty}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <IconButton
          sx={{ marginLeft: "auto" }}
          aria-label="delete"
          onClick={() => {
            dispatch(
              updateEasydropSelectedProducts(item)
            );
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>
      {loading ? (
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full bg-black/[.5]">
          <CircularProgress />
        </div>
      ) : null}
      {!suppliers.some((supplier) => supplier.id === item.supplier_id) ? (
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full p-2 bg-gray-600/[.3]">
          <IconButton
            sx={{ marginLeft: "auto" }}
            aria-label="delete"
            onClick={() => {
              dispatch(
                updateEasydropSelectedProducts(item)
              );
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
}
