import { createSlice } from "@reduxjs/toolkit"
import { SectionsPageType } from "./interfaces"
import { SectionsPageAddType, SectionsPageFetchedType, SectionsPageRemoveType, SectionsPageRenameType, SwitchLanguageType } from "./actionsInterfaces"

const initialState: {
    sectionsPage?: SectionsPageType[]
    currentLanguage: "uk" | "ru",
    listLanguage: string[]
} = {
    currentLanguage: "uk",
    listLanguage: ["uk", "ru"]
}

const sectionsPageSlice = createSlice({
    name: "sectionsPage",
    initialState,
    reducers: {
        sectionsPageFetched: (state, action: SectionsPageFetchedType) => {
            state.sectionsPage = action.payload;
        },
        sectionsPageAdd: (state, action: SectionsPageAddType) => {
            const newObj = JSON.parse(JSON.stringify(state.sectionsPage));

            const names: Record<string, string> = {};

            state.listLanguage.forEach(item => {
                names[item] = action.payload.section.name;
            })

            function pushSection(obj: SectionsPageType[]) {
                obj.forEach(item => {
                    if ((item.id || item.id_front) === action.payload.id) {
                        item.children.push({
                            id_front: action.payload.section.id,
                            name: names,
                            children: [],
                            editable: true,
                            user_id: item.user_id
                        });
                        return;
                    } else {
                        pushSection(item.children);
                    }
                })
            }

            pushSection(newObj);
            state.sectionsPage = newObj;
        },
        sectionsPageRemove: (state, action: SectionsPageRemoveType) => {
            const newObj = JSON.parse(JSON.stringify(state.sectionsPage));
            function deleteSection(obj: SectionsPageType[], parentDeleted: boolean = false) {
                obj.forEach((item, i) => {
                    if ((item.id || item.id_front) === action.payload) {
                        item.deleted = true;
                        deleteSection(item.children, true);
                        return;
                    } else if (parentDeleted) {
                        item.deleted = true;
                    } else {
                        deleteSection(item.children);
                    }
                })
            }

            deleteSection(newObj);
            state.sectionsPage = newObj;
        },
        sectionsPageRename: (state, action: SectionsPageRenameType) => {
            const newObj = JSON.parse(JSON.stringify(state.sectionsPage));

            function deleteSection(obj: SectionsPageType[]) {
                obj.forEach((item, i) => {
                    if ((item.id || item.id_front) === action.payload.id) {
                        item.name[state.currentLanguage] = action.payload.name;
                        return;
                    } else {
                        deleteSection(item.children);
                    }
                })
            }

            deleteSection(newObj);

            state.sectionsPage = newObj;
        },
        switchLanguage: (state, actions: SwitchLanguageType) => {
            state.currentLanguage = actions.payload;
        }
    }
})

const { actions, reducer } = sectionsPageSlice

export default reducer;

export const {
    sectionsPageFetched,
    sectionsPageAdd,
    sectionsPageRemove,
    sectionsPageRename,

    switchLanguage
} = actions;