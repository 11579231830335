import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";

import axiosAuth from "./API/auth";

import { userFetched, userFetching, userFetchingError } from "./slices/user/slice";

import LoadingBackdrop from "./components/shared/loadingBackdrop";

import MainRouter from "./routers/mainRouter";
import LoginRouter from "./routers/loginRouter";

import IsConfirmedRouter from "./routers/isConfirmedRouter";

export default function App() {
  const userStatus = useAppSelector(state => state.user.userStatus);
  const isConfirmed = useAppSelector(state => state.user.data.info.is_confirmed);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userFetching());

    axiosAuth().post("/is_login/")
      .then((response) => {
        dispatch(userFetched(response.data));
      }).catch((err) => {
        console.error(err)
        if (err === "logaut") {
          dispatch(userFetchingError());
        }
      })
  }, []);

  let router: JSX.Element | null = null;

  switch (userStatus) {
    case "idle":
      if (isConfirmed) {
        router = <MainRouter />
      } else {
        router = <IsConfirmedRouter />
      }
      break;
    case "error":
      router = <LoginRouter />
      break;
  }

  return (
    <div className="App">
      <div className="flex relative">
        {router || <LoadingBackdrop />}
      </div>
    </div>
  );
}