import { useEffect, useState } from "react";

import { useCurrentEditor } from "@tiptap/react";
import * as yup from 'yup';

import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function Link() {
    const { editor } = useCurrentEditor()

    const [statusLinkModal, setStatusLinkModal] = useState<boolean>(false);

    const [linkSettings, setlinkSettings] = useState<{
        type: string,
        url: string,
        addresses: string,
        theme: string,
        bodyMessage: string
    }>({
        type: "url",
        url: "",
        addresses: "",
        theme: "",
        bodyMessage: ""
    })

    const [linkSettingsError, setlinkSettingsError] = useState<{
        url: string,
        addresses: string
    }>({
        url: "",
        addresses: ""
    })

    useEffect(() => {
        document.querySelectorAll(".tiptap a").forEach(item => {
            item.addEventListener("dblclick", (e) => {
                setStatusLinkModal(true);
            })
        })

        if (statusLinkModal) {
            if (editor?.getAttributes('link').href?.split(":")[0] === "mailto") {
                setlinkSettings({
                    type: "email",
                    url: "",
                    addresses: editor?.getAttributes('link').href?.split(":")[1]?.split("?")[0] || "",
                    theme: editor?.getAttributes('link').href?.split(":")[1]?.split("?")[1]?.split("=")[1]?.split("&")[0] || "",
                    bodyMessage: editor?.getAttributes('link').href?.split(":")[1]?.split("?")[1]?.split(";")[1].split("=")[1] || ""
                })
            } else {
                setlinkSettings({
                    type: "url",
                    url: editor?.getAttributes('link').href || "",
                    addresses: "",
                    theme: "",
                    bodyMessage: ""
                })
            }

        }
    }, [statusLinkModal])

    if (editor) {
        return (
            <>
                <Tooltip title="Посилання" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant="outlined"
                        onClick={() => setStatusLinkModal(true)}
                    >
                        <InsertLinkIcon />
                    </Button>
                </Tooltip>
                <div
                    className={statusLinkModal ? "modal h-screen z-[2000000]" : "hidden"}
                    onClick={(event) => {
                        if (event.target === event.currentTarget) {
                            setStatusLinkModal(false)
                        }
                    }}
                >
                    <div style={{ padding: "15px" }} className="form bg-white w-[300px]">
                        <div className="flex items-center justify-between mb-3">
                            <p className="text-[18px]">Посилання</p>
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { setStatusLinkModal(false) }}
                            >
                                <CloseOutlinedIcon />
                            </Button>
                        </div>
                        <Stack spacing={2} >
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Тип посилання</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={linkSettings.type}
                                    label="Тип посилання"
                                    onChange={(e) => {
                                        setlinkSettings((state) => ({
                                            ...state,
                                            type: e.target.value
                                        }))
                                    }}
                                >
                                    <MenuItem value={"url"}>URL</MenuItem>
                                    <MenuItem value={"email"}>Ел. пошта</MenuItem>
                                </Select>
                            </FormControl>
                            {
                                linkSettings.type === "url"
                                    ? <>
                                        <TextField
                                            id="outlined-basic"
                                            label={<b>URL*</b>}
                                            variant="outlined"
                                            size="small"
                                            value={linkSettings.url}
                                            onChange={(event) => {
                                                setlinkSettings(state => ({
                                                    ...state,
                                                    url: event.target.value
                                                }))
                                            }}
                                            onBlur={() => {
                                                setlinkSettingsError(state => ({
                                                    ...state,
                                                    url: ""
                                                }))
                                                try {
                                                    yup.string().url("Невірний формат URL").required("Це поле обов'язкове").validateSync(linkSettings.url);
                                                } catch (err: any) {
                                                    setlinkSettingsError(state => ({
                                                        ...state,
                                                        url: err.errors[0]
                                                    }))
                                                }
                                            }}
                                            error={!!linkSettingsError.url}
                                            helperText={<span>{linkSettingsError.url}</span>}
                                        />
                                    </>
                                    : <Stack spacing={2} direction="column" >
                                        <TextField
                                            id="outlined-basic"
                                            label={<b>Адреса ел. пошти*</b>}
                                            variant="outlined"
                                            size="small"
                                            value={linkSettings.addresses}
                                            onChange={(event) => {
                                                setlinkSettings(state => ({
                                                    ...state,
                                                    addresses: event.target.value
                                                }))
                                            }}
                                            onBlur={() => {
                                                setlinkSettingsError(state => ({
                                                    ...state,
                                                    addresses: ""
                                                }))
                                                try {
                                                    yup.string().email("Невірний формат пошти").required("Це поле обов'язкове").validateSync(linkSettings.addresses);
                                                } catch (err: any) {
                                                    setlinkSettingsError(state => ({
                                                        ...state,
                                                        addresses: err.errors[0]
                                                    }))
                                                }
                                            }}
                                            error={!!linkSettingsError.addresses}
                                            helperText={<span>{linkSettingsError.addresses}</span>}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Тема листа"
                                            variant="outlined"
                                            size="small"
                                            value={linkSettings.theme}
                                            onChange={(event) => {
                                                setlinkSettings(state => ({
                                                    ...state,
                                                    theme: event.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Тіло повідомлення"
                                            variant="outlined"
                                            size="small"
                                            value={linkSettings.bodyMessage}
                                            onChange={(event) => {
                                                setlinkSettings(state => ({
                                                    ...state,
                                                    bodyMessage: event.target.value
                                                }))
                                            }}
                                        />
                                    </Stack>
                            }
                        </Stack>
                        <Stack spacing={2} direction="row" sx={{ justifyContent: "center", mt: "10px" }}>
                            <Button
                                size="small"
                                sx={{ display: "block", mx: "auto", mt: "10px" }}
                                variant="outlined"
                                disabled={(linkSettings.type === "url" ? !!linkSettingsError.url : !!linkSettingsError.addresses)}
                                onClick={() => {
                                    if (linkSettings.type === "url") {
                                        editor.chain().focus().extendMarkRange('link').setLink({ href: linkSettings.url, target: '_blank' }).run()
                                    } else {
                                        editor.chain().focus().extendMarkRange('link').setLink({ href: `mailto:${linkSettings.addresses}?subject=${linkSettings.theme}&amp;body=${linkSettings.bodyMessage}`, target: '_blank' }).run()
                                    }
                                    setlinkSettings({
                                        type: "url",
                                        url: "",
                                        addresses: "",
                                        theme: "",
                                        bodyMessage: ""
                                    })
                                    setlinkSettingsError({
                                        url: "",
                                        addresses: ""
                                    })
                                    setStatusLinkModal(false)
                                }}
                            >
                                OK
                            </Button>
                            <Button
                                size="small"
                                sx={{ display: "block", mx: "auto", mt: "10px" }}
                                variant="outlined"
                                onClick={() => {
                                    editor.chain().focus().extendMarkRange('link').unsetLink().run()
                                    setlinkSettings({
                                        type: "url",
                                        url: "",
                                        addresses: "",
                                        theme: "",
                                        bodyMessage: ""
                                    })
                                    setlinkSettingsError({
                                        url: "",
                                        addresses: ""
                                    })
                                    setStatusLinkModal(false)
                                }}
                            >
                                Видалити
                            </Button>
                        </Stack>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}