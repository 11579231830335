import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import axiosAuth from "../../API/auth";

import { characteristicFetched, selectAll, clearDataChange, setProductTypes } from "../../slices/characteristic/slice";

import CharacteristicList from "../../components/characteristic/list";
import TypesProductList from "../../components/shared/typesProduct/list";
import SubmitBtn from "../../components/shared/submitBtn";

import { CharacteristicType, ProductTypesType } from "../../slices/characteristic/interfaces";

export default function CharacteristicsPage() {
    const characteristics = useAppSelector(selectAll)
    const dataChange = useAppSelector((state) => state.characteristic.dataChange);

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearDataChange());
        };
    }, []);

    function onSubmit() {
        const data = Object.keys(dataChange).map(product_type_id => {
            const productTypeId = parseInt(product_type_id);

            return {
                product_type_id: productTypeId,
                create: {
                    characteristics: dataChange[productTypeId]?.create?.characteristics?.map(characteristicId => {
                        const characteristic = characteristics.find(item => item.id === characteristicId);

                        return {
                            name: characteristic?.name,
                            is_variation: characteristic?.is_variation,
                            size_responsible: characteristic?.size_responsible,
                            values: characteristic?.values
                        }
                    }) || [],
                    values: Object.keys(dataChange[productTypeId]?.create?.values || {})?.reduce((result: any, characteristic_id) => {
                        const characteristicId = parseInt(characteristic_id);

                        const characteristic = characteristics.find(item => item.id === characteristicId);

                        return [...result, ...dataChange[productTypeId].create.values[characteristicId].map(valueId => {
                            return {
                                characteristic_id: characteristic?.id,
                                name: characteristic?.values.find(value => value.id === valueId)?.name
                            }
                        })]
                    }, []) || []
                },
                edit: {
                    characteristics: Object.keys(dataChange[productTypeId]?.edit?.characteristics || {})?.map(characteristic_id => {
                        const characteristicId = parseInt(characteristic_id);
                        const characteristic = characteristics.find(item => item.id === characteristicId) as Record<string, any>;

                        const changes: Record<string, any> = {};

                        dataChange[productTypeId].edit.characteristics[characteristicId].forEach(stringChange => {
                            if (stringChange.includes("|")) {
                                const splitStringChange: string[] = stringChange.split("|");

                                changes[splitStringChange[0]] = {
                                    ...changes[splitStringChange[0]],
                                    [splitStringChange[1]]: characteristic?.name?.[splitStringChange[1]] || ""
                                }
                            } else {
                                changes[stringChange] = characteristic[stringChange]
                            }
                        })

                        return {
                            id: characteristicId,
                            ...changes
                        }
                    }) || [],
                    values: Object.keys(dataChange[productTypeId]?.edit?.values || {})?.reduce((result: any, characteristic_id) => {
                        const characteristicId = parseInt(characteristic_id);

                        return [...result, ...Object.keys(dataChange[productTypeId].edit.values[characteristicId]).map(value_id => {
                            const valueId = parseInt(value_id);
                            const value = characteristics
                                .find(item => item.id === characteristicId)?.values
                                .find(item => item.id === valueId) as Record<string, any>;

                            const changes: Record<string, any> = {};

                            dataChange[productTypeId].edit.values[characteristicId][valueId].forEach(stringChange => {
                                if (stringChange.includes("|")) {
                                    const splitStringChange: string[] = stringChange.split("|");

                                    changes[splitStringChange[0]] = {
                                        ...changes[splitStringChange[0]],
                                        [splitStringChange[1]]: value?.name?.[splitStringChange[1]] || ""
                                    }
                                } else {
                                    changes[stringChange] = value[stringChange]
                                }
                            });

                            return {
                                characteristic_id,
                                value_id,
                                ...changes
                            }
                        })]
                    }, []) || []
                },
                delete: {
                    characteristics: dataChange[productTypeId]?.delete?.characteristics || [],
                    values: Object.values(dataChange[productTypeId]?.delete?.values || {})?.reduce((result: any, values_id) => {
                        return [...result, ...values_id]
                    }, []) || []
                }
            }
        })

        return axiosAuth()
            .post("/process_characteristics/", JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
    }

    return (
        <main className="w-full">
            <TypesProductList />
            <div className={`px-10 py-5 flex flex-col`}>
                <CharacteristicList />
                <SubmitBtn
                    request={Object.keys(dataChange).length !== 0 ? onSubmit : "face"}
                    handlerResponse={(data) => {
                        const { characteristics, productTypes }: { characteristics: CharacteristicType[], productTypes: ProductTypesType[] } = data;

                        dispatch(setProductTypes(productTypes));
                        dispatch(characteristicFetched(characteristics));
                        dispatch(clearDataChange())
                    }}
                />
            </div>
        </main>
    );
};