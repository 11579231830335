import { useLoaderData } from "react-router-dom";
import { CharacteristicType } from "../../../slices/characteristic/interfaces";

import BasicCheckboxListVariation from "./basicCheckboxListVariation";
import ColectionCheckboxVariation from "./colectionCheckboxVariation";

export default function VariationCheckbox() {
    const { data: { characteristics } } = useLoaderData() as any as { data: { characteristics: CharacteristicType[] } }
    const variations = characteristics.filter(item => item.is_variation);

    if (!!variations.length) {
        return (
            <div>
                <p className="font-bold mb-2">Варіація*</p>
                {
                    variations.map(variation => {
                        if (variation.values.length > 10) {
                            return <ColectionCheckboxVariation key={variation.id} char={variation} />
                        } else {
                            return <BasicCheckboxListVariation key={variation.id} char={variation} />
                        }
                    })
                }
            </div>
        )
    } else {
        return null
    }
}