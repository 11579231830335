import {
    CommandProps,
    Extension
} from '@tiptap/core'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        indent: {
            indent: () => ReturnType
            outdent: () => ReturnType
        }
    }
}

type IndentOptions = {
    names: Array<string>
    indentRange: number
    minIndentLevel: number
    maxIndentLevel: number
    defaultIndentLevel: number
}

export const Indent = Extension.create<IndentOptions, never>({
    name: 'indent',

    addOptions() {
        return {
            names: ['heading', 'paragraph', "bulletList", "orderedList"],
            indentRange: 30,
            minIndentLevel: 0,
            maxIndentLevel: 30 * 10,
            defaultIndentLevel: 0
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.names,
                attributes: {
                    indent: {
                        default: this.options.defaultIndentLevel,
                        parseHTML: element => parseInt(element.style.marginLeft, 10) || this.options.defaultIndentLevel,
                        renderHTML: attributes => {
                            if (attributes.indent === this.options.defaultIndentLevel) {
                                return {}
                            }

                            return {
                                style: `margin-left: ${attributes.indent}px;`,
                            }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            indent: () => ({ tr, dispatch }: CommandProps) => {
                const { doc, selection } = tr;
                const { from, to } = selection;
                doc.nodesBetween(from, to, (node, pos, parent) => {
                    if (this.options.names.includes(node.type.name)) {
                        const indent = clamp((node?.attrs.indent || 0) + this.options.indentRange, this.options.minIndentLevel, this.options.maxIndentLevel)

                        const nodeAttrs = {
                            ...node?.attrs,
                            indent,
                        }

                        if (parent?.type.name !== "listItem") {
                            tr = tr.setNodeMarkup(pos, node?.type, nodeAttrs, node?.marks)
                        }
                    }
                })

                if (tr.docChanged && dispatch) {
                    dispatch(tr)
                    return true
                }

                return false
            },
            outdent: () => ({ tr, dispatch }: CommandProps) => {
                const { doc, selection } = tr;
                const { from, to } = selection;

                doc.nodesBetween(from, to, (node, pos, parent) => {
                    if (this.options.names.includes(node.type.name)) {
                        const indent = clamp((node?.attrs.indent || 0) + (-this.options.indentRange), this.options.minIndentLevel, this.options.maxIndentLevel)

                        const nodeAttrs = {
                            ...node?.attrs,
                            indent,
                        }

                        if (parent?.type.name !== "listItem") {
                            tr = tr.setNodeMarkup(pos, node?.type, nodeAttrs, node?.marks)
                        }
                    }
                })

                if (tr.docChanged && dispatch) {
                    dispatch(tr)
                    return true
                }

                return false
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            Tab: () => this.editor.commands.indent(),
            'Shift-Tab': () => this.editor.commands.outdent()
        }
    },

    onUpdate() {
        const { editor } = this
        if (editor.isActive('listItem')) {
            const node = editor.state.selection.$head.node()
            if (node.attrs.indent) {
                editor.commands.updateAttributes(node.type.name, { indent: 0 })
            }
        }
    }
})

function clamp(val: number, min: number, max: number): number {
    if (val < min) {
        return min
    }
    if (val > max) {
        return max
    }
    return val
}