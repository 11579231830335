import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useLoaderData } from "react-router-dom";

import { updateAvailability } from "../../../slices/product/slice";

import TextField from "@mui/material/TextField";

import ColectionCheckbox from "../characteristicsCheckbox/colectionCheckbox";
import BasicCheckboxList from "../characteristicsCheckbox/basicCheckboxList";
import { CharacteristicType } from "../../../slices/characteristic/interfaces";

export default function AvailabilityCheckbox() {
    const { data: { characteristics } } = useLoaderData() as any as { data: { characteristics: CharacteristicType[] } }
    const size = characteristics.find(item => item.size_responsible)

    const availability = useAppSelector(state => state.product.availability);

    const dispatch = useAppDispatch();

    if (size) {
        return (
            <div>
                <p className="font-bold mb-2">Наявність</p>
                <div className="flex flex-col gap-3">
                    {
                        (size.values.length > 10)
                            ? <ColectionCheckbox key={size.id} char={size} type="size" />
                            : <BasicCheckboxList key={size.id} char={size} type="size" />
                    }
                    <div className="flex gap-2">
                        {
                            availability.map((item, i) => {
                                return <TextField
                                    key={i}
                                    size="small"
                                    id="outlined-basic"
                                    label={item.size}
                                    variant="outlined"
                                    sx={{ width: "60px" }}
                                    value={item.qty}
                                    onChange={(e) => {
                                        dispatch(updateAvailability({
                                            qty: e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0 : 0,
                                            size: item.size
                                        }))
                                    }}
                                />
                            })
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}