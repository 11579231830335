import { createSlice } from "@reduxjs/toolkit";
import { ModalType } from "./interfaces";
import { ErrorSetType, InitModalType, SetSendStatusType, SetValueType } from "./actionsInterfaces";


const initialState: ModalType = {
    modalValue: "",
    status: "hide",
    error: "",
    modalProps: {
        btnText: "Зберегти",
        placeholderText: "",
        submitFunkID: "",
        sendStatusText: {
            loading: "Збереження",
            success: "Збережено",
            error: "Не вдалося зберегти"
        }
    },
    sendStatus: "idle",

    errorModal: {
        content: []
    }
}

export const submitFunks: Record<string, (value: string) => void> = {};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        initModal: (state, actions: InitModalType) => {
            state.status = "show";
            state.modalProps.btnText = actions.payload.btnText;
            state.modalProps.placeholderText = actions.payload.placeholderText;
            state.modalProps.submitFunkID = actions.payload.submitFunkID;
        },
        hideModal: state => {state.status = "hide"},

        setValue: (state, actions: SetValueType) => {
            state.modalValue = actions.payload
        },
        clearValue: state => {state.modalValue = ""},
        errorSet: (state, action: ErrorSetType) => {
            state.error = action.payload;
        },
        setSendStatus: (state, action: SetSendStatusType) => {
            state.sendStatus = action.payload;
        },


        setErrorText: (state, actions) => {
            if (state.errorModal.content.includes(actions.payload)) {
                state.errorModal.content = state.errorModal.content.filter(item => item !== actions.payload)
            } else {
                state.errorModal.content = [...state.errorModal.content, actions.payload]
            }
        }
    }
});

const {actions, reducer} = modalSlice;

export default reducer;

export const {
    initModal,
    hideModal,

    setValue,
    clearValue,
    errorSet,
    setSendStatus,

    setErrorText
} = actions;