import { useAppSelector } from "../../../../store/hooks";

import TypesProductItem from "../item";
import TypesProductAdd from "../add";

export default function TypesProductList() {
    const listType = useAppSelector(state => state.characteristic.productTypes);

    return (
        <div className="relative">
            <TypesProductAdd />

            <div className="flex mb-6 overflow-x-auto pr-[70px]">
                {
                    listType.map((item) => {
                        return <TypesProductItem key={item.id} data={item} />
                    })
                }
            </div>
        </div>
    )
}