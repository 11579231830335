import { useCurrentEditor } from "@tiptap/react";

import { Button, Tooltip } from "@mui/material";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export default function List() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <>
                <Tooltip title="Вставити/видалити нумерований список" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive('orderedList') ? "contained" : "outlined"}
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    >
                        <FormatListNumberedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Вставити/видалити маркований список" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive('bulletList') ? "contained" : "outlined"}
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
    
                    >
                        <FormatListBulletedIcon />
                    </Button>
                </Tooltip>
            </>
        )
    } else {
        return null
    }
}