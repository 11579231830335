import { useAppDispatch, useAppSelector } from "../../../store/hooks"

import axiosAuth from "../../../API/auth";
import { v4 as uuidv4 } from 'uuid';

import { Autocomplete, Checkbox, Stack, TextField } from "@mui/material";
import { userClear } from "../../../slices/user/slice";
import { setErrorText } from "../../../slices/modal/slice";
import { useEffect } from "react";
import { clearHoroshop, setHoroshopDataList, updateHoroshopAdditionalCategories, updateHoroshopCategory } from "../../../slices/integrations/horoshop/slice";

export default function IntegrationHoroshop() {
    const horoshopActive = useAppSelector(state => state.horoshop.isActive)
    const mainSelectedCategory = useAppSelector(state => state.horoshop.mainSelectedCategory);
    const additionalSelectedCategories = useAppSelector(state => state.horoshop.additionalSelectedCategories);
    const horoshopCategoriesList = useAppSelector(state => state.horoshop.categoriesList);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (horoshopActive) {

            axiosAuth()
                .get("/v2/horoshop_pages/")
                .then((response) => {
                    dispatch(setHoroshopDataList(response.data.response.pages.map((item: any) => ({
                        id: item.id,
                        name: item.title.ua
                    }))))
                })
                .catch((err) => {
                    if (err === "logaut") {
                        dispatch(userClear());
                    } else {
                        console.error(err)
                        dispatch(setErrorText(err.response.data.error))
                    }
                })

            return () => {
                dispatch(clearHoroshop())
            }
        }
    }, [])

    if (horoshopActive) {
        return (
            <div className="shadow p-2">
                <p className="mb-3">Інтеграція Horoshop</p>
                <Stack
                    spacing={3}
                >
                    <Autocomplete
                        freeSolo
                        fullWidth
                        options={horoshopCategoriesList.map(item => item.name)}
                        value={mainSelectedCategory}
                        onChange={(event, newValue) => {
                            dispatch(updateHoroshopCategory(newValue || ""));
                        }}
                        inputValue={mainSelectedCategory || ""}
                        onInputChange={(event, newInputValue) => {
                            dispatch(updateHoroshopCategory(newInputValue || ""));
                        }}
                        renderInput={(params) => {
                            const required = ((additionalSelectedCategories.length > 0) || !!mainSelectedCategory)

                            return <TextField {...params} label={"Основна категорія" + (required ? "*" : "")} />
                        }}
                    />
                    <Autocomplete
                        freeSolo
                        multiple
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                            if (typeof option === "string") {
                                return option
                            } else {
                                return option.name
                            }
                        }}
                        options={horoshopCategoriesList}
                        value={additionalSelectedCategories || []}
                        onChange={(event, newValue) => {
                            dispatch(updateHoroshopAdditionalCategories(newValue.map(item => {
                                if (typeof item === "string") {
                                    return {
                                        id: uuidv4(),
                                        name: item
                                    }
                                } else {
                                    return item
                                }
                            })));
                        }}
                        renderInput={(params) => <TextField {...params} label="Додаткові категорії" />}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                    />
                </Stack>
            </div>
        )
    } else {
        return null
    }
}