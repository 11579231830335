import { useCurrentEditor } from "@tiptap/react"

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export default function TextSize() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <FormControl
                size="small"
                sx={{ m: 0, width: 150 }}
            >
                <InputLabel id="demo-simple-select-label">Розмір</InputLabel>
                <Select
                    label="Розмір"
                    labelId="demo-simple-select-label"
                    value={(() => {
                        if (editor.isActive('textStyle', { fontSize: '8px' })) return 8
                        if (editor.isActive('textStyle', { fontSize: '9px' })) return 9
                        if (editor.isActive('textStyle', { fontSize: '10px' })) return 10
                        if (editor.isActive('textStyle', { fontSize: '11px' })) return 11
                        if (editor.isActive('textStyle', { fontSize: '12px' })) return 12
                        if (editor.isActive('textStyle', { fontSize: '14px' })) return 14
                        if (editor.isActive('textStyle', { fontSize: '16px' })) return 16
                        if (editor.isActive('textStyle', { fontSize: '18px' })) return 18
                        if (editor.isActive('textStyle', { fontSize: '20px' })) return 20
                        if (editor.isActive('textStyle', { fontSize: '22px' })) return 22
                        if (editor.isActive('textStyle', { fontSize: '24px' })) return 24
                        if (editor.isActive('textStyle', { fontSize: '26px' })) return 26
                        if (editor.isActive('textStyle', { fontSize: '28px' })) return 28
                        if (editor.isActive('textStyle', { fontSize: '36px' })) return 36
                        if (editor.isActive('textStyle', { fontSize: '48px' })) return 48
                        if (editor.isActive('textStyle', { fontSize: '72px' })) return 72
                        return ""
                    })()}
                    renderValue={(selected) => (
                        <span>{selected}</span>
                    )}
                    onChange={(e) => {
                        if (e.target.value) {
                            editor.commands.setFontSize(e.target.value + "");
                        } else {
                            editor.commands.unsetFontSize();
                        }
                    }}
                >
                    <MenuItem value={""}>Типово</MenuItem>
                    <MenuItem value={8}><span className="text-[8px]">8</span></MenuItem>
                    <MenuItem value={9}><span className="text-[9px]">9</span></MenuItem>
                    <MenuItem value={10}><span className="text-[10px]">10</span></MenuItem>
                    <MenuItem value={11}><span className="text-[11px]">11</span></MenuItem>
                    <MenuItem value={12}><span className="text-[12px]">12</span></MenuItem>
                    <MenuItem value={14}><span className="text-[14px]">14</span></MenuItem>
                    <MenuItem value={16}><span className="text-[16px]">16</span></MenuItem>
                    <MenuItem value={18}><span className="text-[18px]">18</span></MenuItem>
                    <MenuItem value={20}><span className="text-[20px]">20</span></MenuItem>
                    <MenuItem value={22}><span className="text-[22px]">22</span></MenuItem>
                    <MenuItem value={24}><span className="text-[24px]">24</span></MenuItem>
                    <MenuItem value={26}><span className="text-[26px]">26</span></MenuItem>
                    <MenuItem value={28}><span className="text-[28px]">28</span></MenuItem>
                    <MenuItem value={36}><span className="text-[36px]">36</span></MenuItem>
                    <MenuItem value={48}><span className="text-[48px]">48</span></MenuItem>
                    <MenuItem value={72}><span className="text-[72px]">72</span></MenuItem>
                </Select>
            </FormControl>
        )
    } else {
        return null
    }
}