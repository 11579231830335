import { useCurrentEditor } from "@tiptap/react";

import { Button, Tooltip } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

export default function HistoryEditor() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <>
                <Tooltip title="Відмінити (Ctrl+Z)" placement="top">
                    <span>
                        <Button
                            size="small"
                            sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                            variant="outlined"
                            onClick={() => editor.chain().focus().undo().run()}
                            disabled={!editor.can().chain().focus().undo().run()}
                        >
                            <UndoIcon />
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Відновити (Ctrl+Y)" placement="top">
                    <span>
                        <Button
                            size="small"
                            sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                            variant="outlined"
                            onClick={() => editor.chain().focus().redo().run()}
                            disabled={!editor.can().chain().focus().redo().run()}
                        >
                            <RedoIcon />
                        </Button>
                    </span>
                </Tooltip>
            </>
        )
    } else {
        return null
    }
}