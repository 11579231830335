import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { characteristicValueEdit, characteristicValueRemove } from "../../../../../slices/characteristic/slice";
import { clearValue, hideModal, initModal, submitFunks } from "../../../../../slices/modal/slice";

import { TextField, IconButton, Autocomplete } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import ValueItem from "../item";

import { CharacteristicType, CharacteristicValueType } from "../../../../../slices/characteristic/interfaces";
import ValuesListProps from "./interfaces";

export default function ValuesList({ data, currentLanguage }: ValuesListProps) {
    const currentType = useAppSelector((state) => state.characteristic.currentProductType);

    const [visibleData, setVisibleData] = useState<CharacteristicType>(data)

    const [currentValue, setCurrentValue] = useState<{ label: string; id?: number | undefined } | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let values = JSON.parse(JSON.stringify(data.values));

        if (values.length !== 0) {
            if (!isNaN(parseInt(values[0].name[currentLanguage]))) {
                values = values.sort((a: CharacteristicValueType, b: CharacteristicValueType) => {
                    let sizeA = parseInt(a.name[currentLanguage]);
                    let sizeB = parseInt(b.name[currentLanguage]);

                    return sizeA - sizeB;
                })
            }

            setVisibleData({
                ...data,
                values: values
            })
        } else {
            setVisibleData(data)
        }
    }, [data, currentLanguage]);

    function removeValue(id: number) {
        dispatch(characteristicValueRemove({
            product_type_id: currentType.id,
            changesForClient: {
                id: data.id,
                changes: {
                    values: data.values.filter(item => item.id !== id)
                },
            },
            changesForSerever: {
                idCharacteristic: data.id,
                idValue: id
            }
        }));
    }

    function renameValue(newValue: string, idValue: number) {
        dispatch(characteristicValueEdit({
            product_type_id: currentType.id,
            changesForClient: {
                id: data.id,
                changes: {
                    values: data.values.map((item) => {
                        if (item.id === idValue) {
                            return { ...item, name: { ...item.name, [currentLanguage]: newValue } };
                        } else {
                            return item;
                        }
                    })
                },
            },
            changesForSerever: {
                idCharacteristic: data.id,
                idValue,
                changes: `name|${currentLanguage}`
            }
        }));
    }


    return (
        <div className="w-[100%] flex flex-wrap gap-[5px]">
            {
                data.values.length > 10
                    ?
                    <div className="w-full flex items-center gap-2">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={visibleData.values.map(item => ({
                                label: item.name[currentLanguage],
                                id: item.id
                            }))}
                            fullWidth
                            renderInput={(params) => <TextField {...params} placeholder="Почніть вводити значення" />}
                            value={currentValue}
                            onChange={(e, value) => {
                                setCurrentValue(value)
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            noOptionsText={"Нічого не знайдено"}
                        />
                        {
                            currentValue
                                ?
                                <>
                                    <IconButton onClick={() => {
                                        submitFunks.editValueCharacteristic = (value) => {
                                            setCurrentValue({ ...currentValue, label: value })
                                            renameValue(value, currentValue?.id || 0);
                                            dispatch(clearValue());
                                            dispatch(hideModal());
                                        }

                                        dispatch(initModal({
                                            btnText: "Зберегти",
                                            placeholderText: "Нове значення",
                                            submitFunkID: "editValueCharacteristic"
                                        }))
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => {
                                        removeValue(currentValue.id || 0);

                                        setCurrentValue(null)
                                    }}>
                                        <ClearIcon />
                                    </IconButton>
                                </>
                                : null
                        }
                    </div>
                    :
                    visibleData.values.map((item: CharacteristicValueType) => (
                        <ValueItem
                            key={item.id}
                            text={item.name[currentLanguage]}
                            removeValue={removeValue}
                            renameValue={renameValue}
                            id={item.id}
                        />
                    ))
            }
        </div>
    )
}