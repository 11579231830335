import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { selectedCategories } from "../../../../slices/link/slice";

import Checkbox from "@mui/material/Checkbox";

import { Divider, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import BranchProps from "./interfaces";

export default function Branch({ node, level, isOnSelectParent }: BranchProps) {
    const selectCategories = useAppSelector(state => state.link.categories);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [parentLastAction, setParentLastAction] = useState<"add" | "remove">();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOnSelectParent === "add" || isOnSelectParent === "remove") {
            if (!(isOnSelectParent === "add" && selectCategories.includes(node.id || -1))) {
                dispatch(selectedCategories(node.id || -1));
            }
            setParentLastAction(isOnSelectParent)
        }
    }, [isOnSelectParent])

    return (
        <div className="pl-2 py-2 ">
            <div
                className={"min-w-[290px] flex items-center justify-between px-2 py-2 rounded-md text-gray-500 " + ((node.children.length > 0) ? "cursor-pointer" : "")}
                style={{ marginLeft: `${level * 15}px`, boxShadow: "0px 0px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setIsOpen((state) => !state)
                    }
                }}
            >
                <span className="cursor-text">{node.name["uk"]}</span>
                <div className="flex gap-4 items-center">
                    <Checkbox
                        sx={{ marginLeft: "auto" }}
                        checked={node.id ? selectCategories.includes(node.id) : false}
                        onChange={() => {
                            if (selectCategories.includes(node.id || -1)) {
                                setParentLastAction("remove");
                            } else {
                                setParentLastAction("add");
                            }
                            dispatch(selectedCategories(node.id || -1));
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {
                        (node.children.length > 0)
                            ?
                            <>
                                <Divider orientation="vertical" flexItem />
                                <IconButton
                                    onClick={() => setIsOpen((state) => !state)}
                                    size="small"
                                >
                                    {
                                        (isOpen)
                                            ? <KeyboardArrowUpIcon fontSize="large" />
                                            : <KeyboardArrowDownIcon fontSize="large" />
                                    }
                                </IconButton>
                            </>
                            : <div className="w-[62px]"></div>
                    }
                </div>
            </div>
            {
                <div className={(isOpen && node.children.length) ? "mt-2 block" : "hidden"}>
                    {node.children.map((child) => (
                        <Branch
                            key={child.id}
                            node={child}
                            level={level + 1}
                            isOnSelectParent={parentLastAction}
                        />
                    ))}
                </div>
            }
        </div >
    )
}