import { Outlet, useNavigation } from "react-router-dom";

import SideBar from "../components/side_bar";
import Modal from "../components/shared/modal";
import LoadingBackdrop from "../components/shared/loadingBackdrop";
import ErrorMessage from "../components/shared/errorMessage";

export default function MainLayout() {
    const { state } = useNavigation();

    return (
        <>
            <SideBar />
            <div className="p-4 pl-0 w-[calc(100%-70px)] min-h-[100vh] max-[770px]:pl-4 max-[770px]:w-full">
                <div className="border-2 border-gray-200 border-dashed rounded-lg min-h-full flex">
                    {state === "loading" ? <LoadingBackdrop /> : <Outlet />}
                </div>
            </div>
            <Modal />
            <ErrorMessage />
        </>
    )
}