import { createSlice } from "@reduxjs/toolkit";
import HoroshopSlice from "./interfaces";
import { SetHoroshopDataListType, SetHoroshopType, UpdateHoroshopAdditionalCategoriesType } from "./actions.interfaces";
import { v4 as uuidv4 } from 'uuid';

const initialState: HoroshopSlice = {
    isActive: false,

    categoriesList: [],
    
    additionalSelectedCategories: [],
    mainSelectedCategory: ""
}

const horoshopSlice = createSlice({
    name: "horoshop",
    initialState,
    reducers: {
        setHoroshop: (state, action: SetHoroshopType) => {
            if (action.payload) {
                state.isActive = true;

                state.mainSelectedCategory = action.payload?.details?.category || "";
                state.additionalSelectedCategories = (action.payload?.details?.additional_categories || []).map(item => ({
                    id: uuidv4(),
                    name: item
                }));
            } else {
                state.isActive = false;
            }
        },

        setHoroshopDataList: (state, action: SetHoroshopDataListType) => {
            state.categoriesList = action.payload;
        },

        updateHoroshopCategory: (state, action: { payload: string, type: string }) => {
            state.mainSelectedCategory = action.payload;
        },

        updateHoroshopAdditionalCategories: (state, action: UpdateHoroshopAdditionalCategoriesType) => {
            state.additionalSelectedCategories = action.payload;
        },

        clearHoroshop: (state) => {
            state.mainSelectedCategory = "";
            state.additionalSelectedCategories = [];
        }
    }
})

const { reducer, actions } = horoshopSlice;

export default reducer;

export const {
    setHoroshop,
    setHoroshopDataList,

    updateHoroshopCategory,
    updateHoroshopAdditionalCategories,

    clearHoroshop
} = actions