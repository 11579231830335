import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { characteristicRemove, selectAll, characteristicEdit, characteristicValueAdd } from "../../../slices/characteristic/slice";

import { SvgIcon, IconButton, FormControlLabel, Switch, Button } from "@mui/material";

import EditableText from "../../shared/editableText";
import ValuesList from "./values/list";

import { CharacteristicProps } from "./interfaces";

export default function Characteristic({ data }: CharacteristicProps) {
    const characteristics = useAppSelector(selectAll);
    const listLanguage = useAppSelector(state => state.characteristic.listLanguage);
    const defaultLanguage = useAppSelector(state => state.characteristic.currentLanguage);
    const currentType = useAppSelector((state) => state.characteristic.currentProductType);

    const [currentLanguage, setCurrentLanguage] = useState<string>(defaultLanguage);

    const [inputValue, setInputValue] = useState<string>('');

    const dispatch = useAppDispatch();

    const arrIDValue: number[] = useMemo(() => {
        const arr: number[] = [];

        characteristics.forEach(item => {
            item.values.forEach((value) => {
                arr.push(value.id);
            })
        });

        return arr
    }, []);

    const maxIDValue = useRef<number>(Math.max(...arrIDValue));

    useEffect(() => {
        maxIDValue.current = maxIDValue.current + 1;
    }, [characteristics])

    function removeCharacteristics() {
        dispatch(characteristicRemove({
            product_type_id: currentType.id,
            idNewElement: data.id
        }))
    }

    function renameCharacteristics(newValue: string) {
        dispatch(characteristicEdit({
            product_type_id: currentType.id,
            changesForClient: {
                id: data.id,
                changes: {
                    name: { ...data.name, [currentLanguage]: newValue }
                },
            },
            changesForSerever: {
                id: data.id,
                changes: `name|${currentLanguage}`,
            }
        }))
    }

    function switchTypeChar(type: "is_variation") {
        dispatch(characteristicEdit({
            product_type_id: currentType.id,
            changesForClient: {
                id: data.id,
                changes: {
                    [type]: !data[type]
                },
            },
            changesForSerever: {
                id: data.id,
                changes: type,
            }
        }));
    }

    function addValue() {
        if (inputValue !== '') {
            const values: Record<string, string> = {};

            listLanguage.forEach(item => {
                values[item] = inputValue
            });

            setInputValue('');
            dispatch(characteristicValueAdd({
                product_type_id: currentType.id,
                changesForClient: {
                    id: data.id,
                    changes: {
                        values: [...data.values, {
                            characteristic_id: data.id,
                            id: maxIDValue.current,
                            name: values
                        }]
                    },
                },
                changesForSerever: {
                    idCharacteristic: data.id,
                    idValue: maxIDValue.current
                }
            }));
        }
    }

    function changeLocalLanguage(language: string) {
        setCurrentLanguage(language)
    }

    return (
        <div className="mb-5 shadow-[0_0_5px_1px_#d2d2d2] px-5 py-2.5 rounded">
            <div className="flex flex-row max-[986px]:flex-col max-[986px]:items-start items-center justify-between">
                <div className="flex flex-col md:flex-row items-start gap-2 md:items-center">
                    <p className="mr-1">
                        <EditableText defaultText={data.name[currentLanguage]} renameFunk={(newValue) => {
                            renameCharacteristics(newValue)
                        }} />
                    </p>
                    <div>
                        <FormControlLabel
                            control={<Switch
                                checked={data.size_responsible}
                                onChange={() => {
                                    dispatch(characteristicEdit({
                                        product_type_id: currentType.id,
                                        changesForClient: {
                                            id: data.id,
                                            changes: {
                                                size_responsible: !data.size_responsible
                                            },
                                        },
                                        changesForSerever: {
                                            id: data.id,
                                            changes: "size_responsible",
                                        }
                                    }));
                                    dispatch(characteristicEdit({
                                        product_type_id: currentType.id,
                                        changesForClient: {
                                            id: data.id,
                                            changes: {
                                                is_variation: false
                                            },
                                        },
                                        changesForSerever: {
                                            id: data.id,
                                            changes: "is_variation",
                                        }
                                    }))
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Розмір"
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            disabled={data.size_responsible}
                            control={<Switch
                                checked={data.is_variation}
                                onChange={() => switchTypeChar("is_variation")}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Варіація"
                        />
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex">
                        {
                            listLanguage.map((item, i) => {
                                return <div key={i} style={{ cursor: "pointer", border: (item === currentLanguage) ? "1px solid #1976d2" : "1px solid white" }} className="m-2 p-1 flex w-fit text-sm " onClick={() => {
                                    changeLocalLanguage(item);
                                }}>{item.toLocaleUpperCase()}</div>
                            })
                        }
                    </div>
                    <IconButton aria-label="delete" onClick={removeCharacteristics}>
                        <SvgIcon fontSize="large">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </SvgIcon>
                    </IconButton>
                </div>
            </div>

            <div className="flex flex-wrap gap-[5px]">
                <ValuesList data={data} currentLanguage={currentLanguage} />

                <div className="flex flex-col gap-4 w-full">
                    <input
                        type="text"
                        placeholder="Введіть нове значення"
                        value={inputValue}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                addValue();
                            }
                        }}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        className="p-[10px] rounded outline-none mt-[10px] border-[1px] border-[#8e8e8e] focus:border-[#1976d2]"
                    />

                    <Button variant="outlined" onClick={addValue}>Додати</Button>
                </div>

            </div>
        </div>
    );
}
