import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";

import axiosAuth from "../../../API/auth";

import { userClear } from "../../../slices/user/slice";

import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";

export default function ConfirmedEmail() {
    const [resendEmail, setResendEmail] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <Container component="main">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <div className='flex flex-col center text-center mb-2 mt-[25vh]'>
                    <p className='text-[30px] max-[426px]:text-[25px] mb-4 max-[426px]:mb-3 font-bold'>Ласкаво присимо!</p>
                    <p className='text-[18px] max-[426px]:text-[15px]'>Ви не підтвердили свій обліковий запис. Будь ласка, перевірте свою поштову скриньку (і папку зі спамом) - ви мали отримати електронний лист із посиланням для підтвердження</p>
                </div>
                <div className='flex items-center gap-4 mb-2'>
                    <Typography component="p" sx={{ textAlign: "center" }}>
                        Не отримали лист?
                    </Typography>
                    <p
                        className="text-sky-600 font-semibold cursor-pointer underline"
                        onClick={() => {
                            axiosAuth()
                                .get("/api/resend_confirmation/")
                                .then((res) => {
                                    if (res.status === 200) {
                                        setResendEmail(true);
                                        setTimeout(() => {
                                            setResendEmail(false);
                                        }, 2400)
                                    }
                                })
                        }}
                    >
                        {resendEmail ? "Лист відправлено!" : "Надіслати ще раз"}
                    </p>
                </div>
                <Button
                    variant="contained"
                    onClick={() => {
                        Promise.all([
                            fetch("/api/logout/", {
                                method: "DELETE",
                                headers: {
                                    "Authorization": window.localStorage.getItem("access_token") || ""
                                }
                            }),
                            fetch("/api/logout/", {
                                method: "DELETE",
                                headers: {
                                    "Authorization": window.localStorage.getItem("refresh_token") || ""
                                }
                            })
                        ]).then((data) => {
                            window.localStorage.clear();
                            dispatch(userClear());
                            navigate("/");
                        })
                    }}
                >
                    Вийти
                </Button>
            </Box>
        </Container>
    )
}