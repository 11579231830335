import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";

import { userClear } from "../../../slices/user/slice";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import SubmitBtnProps from "./interfaces";
import { setErrorText } from "../../../slices/modal/slice";

export default function SubmitBtn({ request, handlerResponse }: SubmitBtnProps) {
    const [statusSubmit, setStatusSubmit] = useState<["Зберегти", "primary"] | ["Збережено!", "success"] | ["Не вдалося зберегти", "error"]>(["Зберегти", "primary"]);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    function onSubmit() {
        setLoading(true);

        if (request === "face") {
            setStatusSubmit(["Збережено!", "success"]);
            setLoading(false);
            setTimeout(() => {
                setStatusSubmit(["Зберегти", "primary"]);
            }, 2500)
        } else {
            request()
            .then((response) => {
                setStatusSubmit(["Збережено!", "success"]);
                setLoading(false);
                setTimeout(() => {
                    setStatusSubmit(["Зберегти", "primary"]);
                }, 2500)
                handlerResponse(response.data)
            }).catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setStatusSubmit(["Не вдалося зберегти", "error"]);
                    setLoading(false);
                    setTimeout(() => {
                        setStatusSubmit(["Зберегти", "primary"]);
                    }, 2500)
                    console.error(err)
                    dispatch(setErrorText(err.response.data.error))
                }
            })
        }
    }

    return (
        <LoadingButton
            fullWidth
            size="large"
            onClick={onSubmit}
            loading={loading}
            loadingPosition="center"
            variant="contained"
            endIcon={<SaveIcon fontSize="small" />}
            color={statusSubmit[1]}
        >
            <span>{statusSubmit[0]}</span>
        </LoadingButton>
    )
}