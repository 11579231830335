import { useLoaderData } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';

import BasicCheckboxList from './basicCheckboxList';
import ColectionCheckbox from './colectionCheckbox';
import { CharacteristicType } from '../../../slices/characteristic/interfaces';

export default function CharacteristicsCheckbox() {
    const { data: { characteristics } } = useLoaderData() as any as { data: { characteristics: CharacteristicType[] } }

    if (characteristics.length === 0) {
        return <div>Немає даних про доступні характеристики</div>
    } else {
        return (
            <div>
                <p className="font-bold mb-2">Характеристики</p>

                <div className="flex flex-col gap-2 grow">
                    {
                        characteristics.map((char) => {
                            if (char.size_responsible || char.is_variation) return null;

                            if (char.values.length > 10) {
                                return <ColectionCheckbox key={char.id} char={char} />
                            } else {
                                return <BasicCheckboxList key={char.id} char={char} />
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}
