import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import ScalePictureModalProps from "./interfaces";

export default function ScalePictureModal({ url, onHidden }: ScalePictureModalProps) {
    if (url) {
        return (
            <div className="modal">
                <div className="relative w-full h-full">
                        <img className="w-full h-full object-contain" src={url} alt="" />
                        <div
                            className='absolute top-1 z-[6] right-1 bg-white/30 rounded-full m-2'
                            onClick={(e) => onHidden("")}
                        >
                            <IconButton aria-label="delete" size="small">
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </div>
            </div>
        )
    } else {
        return null
    }
}