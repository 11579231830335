import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import axiosAuth from "../../../../API/auth";

import { userClear } from "../../../../slices/user/slice";

import { Autocomplete, TextField } from "@mui/material"
import { useEffect } from "react";
import { setErrorText } from "../../../../slices/modal/slice";
import { setPromCategories, updatePromDetails } from "../../../../slices/integrations/prom/slice";

export default function PromCategory({ required }: { required: boolean }) {
    const promCategories = useAppSelector(state => state.prom.сategoriesList);
    const promDetails = useAppSelector(state => state.prom.data);

    const dispatch = useAppDispatch();

    useEffect(() => {
        axiosAuth()
            .get("/prom_cats/?search=" + promDetails?.prom_category.name)
            .then((response) => {
                dispatch(setPromCategories(response.data))
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                }
                console.error(err)
                dispatch(setErrorText(err.response.data.error))
            })
    }, [])

    return (
        <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText={"Нічого не знайдено"}
            getOptionLabel={(option) => option.name}
            value={promDetails?.prom_category || { id: 0, name: "" }}
            onChange={(event, newValue) => {
                if (newValue) {
                    dispatch(updatePromDetails({
                        label: "prom_category",
                        value: newValue
                    }));
                }
            }}
            onInputChange={(event, newInputValue) => {
                console.log(newInputValue)
                if (!newInputValue) {
                    dispatch(updatePromDetails({
                        label: "prom_category",
                        value: { id: 0, name: "" }
                    }));
                }
                if (newInputValue !== promDetails?.prom_category.name) {
                    axiosAuth()
                        .get("/prom_cats/?search=" + newInputValue)
                        .then((response) => {
                            dispatch(setPromCategories(response.data))
                        })
                        .catch((err) => {
                            if (err === "logaut") {
                                dispatch(userClear());
                            } else {
                                console.error(err)
                                dispatch(setErrorText(err.response.data.error))
                            }
                        })
                }

            }}
            options={promCategories}
            renderInput={(params) => <TextField {...params} label={"Категорія Prom" + (required ? "*" : "")} />}
        />
    )
}