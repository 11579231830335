import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { useLocation } from "react-router-dom";

import { clearSelected } from "../../../slices/product/slice";

import SubmitModal from "../submitModal";
import SubmitButton from "./submitButton";
import DeleteButton from "./deleteButton";

export default function ActionButton() {
    let mode = useLocation().pathname.split("/")[2];

    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    return (
        <>
            <SubmitButton setIsSuccess={setIsSuccess} />
            {
                mode === "add" || mode === "variation"
                    ?
                    <SubmitModal isSuccess={isSuccess} setIsSuccess={setIsSuccess} resetForm={() => {
                        dispatch(clearSelected())
                    }} />
                    :
                    <DeleteButton />
            }
        </>
    )
}