import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

import { RootState } from "../../store/store";
import { AllTypeCharacteristicDataChange, CharacteristicType, ProductTypesType } from "./interfaces";
import { CharacteristicAddActionType, CharacteristicEditActionType, CharacteristicFetchedType, CharacteristicRemoveActionType, CharacteristicValueAddActionType, CharacteristicValueEditActionType, CharacteristicValueRemoveActionType, ProductTypesRemoveType, ProductTypesUpdateType, SetProductTypesType, SwitchCurrentProductTypesType } from "./actionsInterfaces";

const characteristicAdapter = createEntityAdapter({
    selectId: (item: CharacteristicType) => item.id,
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: {
    productTypes: ProductTypesType[],
    currentProductType: ProductTypesType,
    dataChange: AllTypeCharacteristicDataChange,

    listLanguage: string[],
    currentLanguage: string,
} = {
    productTypes: [],
    currentProductType: {
        id: 0,
        name: ""
    },

    dataChange: {},

    listLanguage: ["uk", "ru", "en"],
    currentLanguage: "uk",
}

const characteristicSlice = createSlice({
    name: "characteristic",
    initialState: characteristicAdapter.getInitialState(initialState),
    reducers: {
        setProductTypes: (state, action: SetProductTypesType) => {
            state.productTypes = action.payload;
        },

        switchCurrentProductTypes: (state, action: SwitchCurrentProductTypesType) => {
            state.currentProductType = action.payload;
        },

        productTypesEdit: (state, action: ProductTypesUpdateType) => {
            const copy: ProductTypesType[] = JSON.parse(JSON.stringify(state.productTypes))

            state.productTypes = copy.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload
                } else {
                    return item;
                }
            });
        },
        typesProductsAdd: (state, action: ProductTypesUpdateType) => {
            state.productTypes = [...state.productTypes, action.payload];
        },
        typesProductsRemove: (state, action: ProductTypesRemoveType) => {
            state.productTypes = state.productTypes.filter(item => item.id !== action.payload)
        },

        characteristicFetched: (state, action: CharacteristicFetchedType) => {
            characteristicAdapter.setAll(state, action.payload);
        },

        characteristicAdd: (state, action: CharacteristicAddActionType) => {
            const { element, product_type_id } = action.payload;

            const { characteristics } = state.dataChange?.[product_type_id]?.create || { characteristics: [] };

            state.dataChange[product_type_id] = {
                ...state.dataChange[product_type_id],
                create: {
                    ...state.dataChange[product_type_id]?.create,
                    characteristics: [...characteristics, element.id]
                }
            }

            characteristicAdapter.addOne(state, element)
        },
        characteristicEdit: (state, action: CharacteristicEditActionType) => {
            const { product_type_id } = action.payload;
            const { id: idForSerever, changes: changesForSerever } = action.payload.changesForSerever;
            const { id: idForClient, changes: changesForClient } = action.payload.changesForClient;

            const characteristicsEdit = state.dataChange?.[product_type_id]?.edit?.characteristics || {};
            const characteristicsCreate = state.dataChange?.[product_type_id]?.create?.characteristics || [];

            if (!characteristicsCreate.includes(idForSerever)) {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    edit: {
                        ...state.dataChange[product_type_id]?.edit,
                        characteristics: {
                            ...state.dataChange[product_type_id]?.edit?.characteristics,
                            [idForSerever]: [...(characteristicsEdit?.[idForSerever] || []), changesForSerever]
                        }
                    }
                }
            }

            if (changesForSerever === "size_responsible") {
                characteristicAdapter.updateMany(state, characteristicAdapter.getSelectors().selectAll(state).map(item => {
                    if (item.id === idForClient) {
                        return {
                            id: item.id,
                            changes: changesForClient
                        }
                    }
                    return {
                        id: item.id,
                        changes: {
                            size_responsible: false
                        }
                    }
                }))
            } else {
                characteristicAdapter.updateOne(state, { id: idForClient, changes: changesForClient });
            }
        },
        characteristicRemove: (state, action: CharacteristicRemoveActionType) => {
            const { idNewElement, product_type_id } = action.payload;

            const { characteristics: characteristicsCreate } = state.dataChange?.[product_type_id]?.create || { characteristics: [] };
            const { characteristics: characteristicsDelete } = state.dataChange?.[product_type_id]?.delete || { characteristics: [] };

            if (characteristicsCreate.includes(idNewElement)) {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    create: {
                        ...state.dataChange[product_type_id]?.create,
                        characteristics: characteristicsCreate.filter(idOldElement => idOldElement !== idNewElement)
                    }
                }
            } else {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    delete: {
                        ...state.dataChange[product_type_id]?.delete,
                        characteristics: [...characteristicsDelete, idNewElement]
                    }
                }
            }

            characteristicAdapter.removeOne(state, idNewElement)
        },

        characteristicValueAdd: (state, action: CharacteristicValueAddActionType) => {
            const { product_type_id } = action.payload;
            const { idCharacteristic, idValue } = action.payload.changesForSerever;
            const { id: idForClient, changes: changesForClient } = action.payload.changesForClient;

            let { characteristics: characteristicsCreate, values: valuesCreate } = state.dataChange?.[product_type_id]?.create || { characteristics: [], values: {} };

            characteristicsCreate = characteristicsCreate || [];
            valuesCreate = valuesCreate || {};

            if (!characteristicsCreate.includes(idCharacteristic)) {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    create: {
                        ...state.dataChange[product_type_id]?.create,
                        values: {
                            ...state.dataChange[product_type_id]?.create?.values,
                            [idCharacteristic]: [...(valuesCreate?.[idCharacteristic] || []), idValue]
                        }
                    }
                }
            }

            characteristicAdapter.updateOne(state, { id: idForClient, changes: changesForClient });
        },
        characteristicValueEdit: (state, action: CharacteristicValueEditActionType) => {
            const { product_type_id } = action.payload;
            const { idCharacteristic, idValue, changes } = action.payload.changesForSerever;
            const { id: idForClient, changes: changesForClient } = action.payload.changesForClient;

            const { characteristics: characteristicsCreate } = state.dataChange?.[product_type_id]?.create || { characteristics: [] };
            const { values: valuesEdit } = state.dataChange?.[product_type_id]?.edit || { values: {} };

            if (!characteristicsCreate.includes(idCharacteristic)) {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    edit: {
                        ...state.dataChange[product_type_id]?.edit,
                        values: {
                            ...state.dataChange[product_type_id]?.edit?.values,
                            [idCharacteristic]: {
                                ...state.dataChange[product_type_id]?.edit?.values[idCharacteristic],
                                [idValue]: [...(valuesEdit?.[idCharacteristic]?.[idValue] || []), changes]
                            }
                        }
                    }
                }
            }

            characteristicAdapter.updateOne(state, { id: idForClient, changes: changesForClient });
        },
        characteristicValueRemove: (state, action: CharacteristicValueRemoveActionType) => {
            const { product_type_id } = action.payload;
            const { idCharacteristic, idValue } = action.payload.changesForSerever;
            const { id: idForClient, changes: changesForClient } = action.payload.changesForClient;

            const { characteristics: characteristicsCreate, } = state.dataChange?.[product_type_id]?.create || { characteristics: [] };
            const { values: valuesDelete } = state.dataChange?.[product_type_id]?.delete || { values: [] };

            if (!characteristicsCreate.includes(idCharacteristic)) {
                state.dataChange[product_type_id] = {
                    ...state.dataChange[product_type_id],
                    delete: {
                        ...state.dataChange[product_type_id]?.delete,
                        values: {
                            ...state.dataChange[product_type_id]?.delete?.values,
                            [idCharacteristic]: [...(valuesDelete?.[idCharacteristic] || []), idValue]
                        }
                    }
                }
            }

            characteristicAdapter.updateOne(state, { id: idForClient, changes: changesForClient });
        },

        languageChange: (state, action) => {
            state.currentLanguage = action.payload;
        },

        clearDataChange: state => { state.dataChange = {} }
    }
});

const { reducer, actions } = characteristicSlice;

export default reducer;

export const { selectAll } = characteristicAdapter.getSelectors<RootState>(state => state.characteristic);

export const {
    setProductTypes,
    switchCurrentProductTypes,
    productTypesEdit,
    typesProductsAdd,
    typesProductsRemove,

    characteristicFetched,

    characteristicAdd,
    characteristicEdit,
    characteristicRemove,

    characteristicValueAdd,
    characteristicValueEdit,
    characteristicValueRemove,

    languageChange,

    clearDataChange
} = actions