import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useGoogleLogin } from '@react-oauth/google';

import { userFetched } from '../../slices/user/slice';

import {Avatar, CssBaseline, TextField, Button, Grid, Box, Typography, Container, InputAdornment, IconButton} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import GoogleIcon from '@mui/icons-material/Google';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

export default function LoginPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [failed, setFailed] = useState<boolean>(false);

    const [errorsLogin, setErrorsLogin] = useState<1001 | 1002 | 1003 | 1004 | 1005 | null>(null)
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: yup.object({
            email: yup.string().email("Невірний формат пошти").required("Це поле обов'язкове"),
            password: yup.string().required("Це поле обов'язкове"),
        }),
        onSubmit: (values) => {
            setLoading(true);

            const formData = new FormData();

            formData.append("email", values.email);
            formData.append("password", values.password);

            axios.post("/api/login/", formData)
                .then(response => {
                console.log(response)

                    setLoading(false);
                    window.localStorage.setItem("access_token", response.data.access_token);
                    window.localStorage.setItem("refresh_token", response.data.refresh_token);
                    dispatch(userFetched(response.data));
                    navigate("/")
                })
                .catch(err => {
                    console.log(err);
                    setErrorsLogin(err.response.data.error)
                    setLoading(false);
                    setFailed(true);
                    setTimeout(() => {
                        setFailed(false);
                    }, 1400)
                });
        }
    })

    const onLoginGoogle = useGoogleLogin({
        onSuccess: ((tokenResponse) => {
            axios.post('/api/google-auth/', JSON.stringify(tokenResponse), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(response)
                window.localStorage.setItem("access_token", response.data.access_token);
                window.localStorage.setItem("refresh_token", response.data.refresh_token);
                dispatch(userFetched(response.data));
                navigate("/")
            })
        }),
        onError: (err) => {
            console.error(err)
        }
    })

    useEffect(() => {
        if ((Object.values(formik.errors).length === 0) && Object.values(formik.values).every(item => item)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formik.values, formik.errors]);

    let errorsLoginMessage: JSX.Element = <></>;

    switch (errorsLogin) {
        case 1001:
            errorsLoginMessage = <p className='text-[14px] text-red-600'>Користувача з таким email немає.</p>
            break;
        case 1002:
            errorsLoginMessage = <p className='text-[14px] text-red-600'>Неправильний пароль.</p>
            break;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Вхід
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(state => !state)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errorsLoginMessage}
                    <LoadingButton
                        fullWidth
                        type="submit"
                        loading={loading}
                        loadingPosition="center"
                        variant="contained"
                        disabled={disabled}
                        sx={{ mt: 1, mb: 1 }}
                        color={failed ? "error" : "primary"}
                    >
                        <span>{failed ? "Не вдалось увійти" : "Увійти"}</span>
                    </LoadingButton>
                    <Button sx={{ mb: 1 }} variant="outlined" fullWidth startIcon={<GoogleIcon />} onClick={() => onLoginGoogle()}>
                        <span className='text-[14px]'>Увійти через Google аккаунт</span>
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link className='text-[14px] text-sky-600 text-nowrap' to={"/new_password"}>
                                Забули пароль?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link className='text-[14px] text-sky-600 font-semibold' to={"/registration"}>
                                У вас немає акаунта? Зареєструватись.
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}