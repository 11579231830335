import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import axiosAuth from "../../../API/auth";

import { FormControlLabel, Switch } from "@mui/material";

import IntegrationInfoType from "./interfaces";
import SubmitBtn from "../../../components/shared/submitBtn";

export default function PromSettingsPage() {
    const response = useLoaderData() as any as { data: IntegrationInfoType }

    const [data, setData] = useState<IntegrationInfoType>()

    useEffect(() => {
        setData({
            user_integration: response.data.user_integration
        })
    }, [])

    function onSubmit() {
        const allSendData = {
            settings: {},
            is_active: data?.user_integration.is_active,
            integration_id: data?.user_integration.integration_id
        }

        return axiosAuth()
            .post("/user_integrations/", JSON.stringify(allSendData), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
    }

    return (
        <main className="w-[600px] p-5 mx-auto">
            <p className="text-center text-[22px] ">{data?.user_integration.name}</p>
            <div className="px-2">
                <FormControlLabel control={<Switch
                    checked={data?.user_integration.is_active || false}
                    onChange={() => {
                        setData(state => {
                            if (state) {
                                return {
                                    user_integration: {
                                        ...state?.user_integration,
                                        is_active: !state?.user_integration.is_active
                                    }
                                }
                            }
                        })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />} label={(data?.user_integration.is_active ? "Виключити" : "Включити") + " інтеграцію"} />
            </div>

            <SubmitBtn
                request={onSubmit}
                handlerResponse={() => {}}
            />
        </main>
    )
}