import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks"

import { addFormulaPrice, setFixedPrice, switchUseFixedMarkup, switchUseFormulaMarkup, switchUseMaxDropPrice, switchUseRetailPrice } from "../../../slices/link/slice";

import { FormControlLabel, TextField, Tooltip, Button, Switch } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import Formula from "../formula";

export default function Markup() {
    const [selectMarkupType, setSelectMarkupType] = useState<"retail" | "fixed" | "formula" | "maxdrop">("fixed");

    const stateUseRetailPrice = useAppSelector(state => state.link.use_retail_price);
    const stateUseFormulaMarkup = useAppSelector(state => state.link.use_formula_markup);
    const stateUseFixedMarkup = useAppSelector(state => state.link.use_fixed_markup);
    const stateUseMaxDropPrice = useAppSelector(state => state.link.use_max_drop_price);

    const stateFixedPrice = useAppSelector(state => state.link.fixed_markup);
    const stateFormulaPrice = useAppSelector(state => state.link.formula_markup);

    const dispatch = useAppDispatch();

    function onChange(newValue: "retail" | "fixed" | "formula" | "maxdrop") {
        setSelectMarkupType(newValue);
    }

    return (
        <div>
            <p className="font-bold mb-2">Спосіб націнки</p>
            <div className="flex max-[670px]:flex max-[670px]:flex-col">
                <Button
                    variant={selectMarkupType === "fixed" ? "contained" : "outlined"}
                    onClick={() => onChange("fixed")}
                    className="w-[33%] max-[670px]:w-[100%]"
                >
                    Вказати фіксовану націнку
                </Button>
                <Button
                    variant={selectMarkupType === "retail" ? "contained" : "outlined"}
                    onClick={() => onChange("retail")}
                    className="w-[33%] max-[670px]:w-[100%]"
                >
                    Взяти роздрібну ціну
                </Button>
                <Button
                    variant={selectMarkupType === "formula" ? "contained" : "outlined"}
                    onClick={() => onChange("formula")}
                    className="w-[33%] max-[670px]:w-[100%]"
                >
                    Задати формулу націнки
                </Button>
                <Button
                    variant={selectMarkupType === "maxdrop" ? "contained" : "outlined"}
                    onClick={() => onChange("maxdrop")}
                    className="w-[33%] max-[670px]:w-[100%]"
                >
                    Брати максимальну Дроп Ціну
                </Button>
            </div>
            {
                selectMarkupType === "retail"
                    ? <div className="my-2">
                        {
                            stateUseFixedMarkup || stateUseFormulaMarkup
                                ?
                                <Tooltip placement="left" title={stateUseFixedMarkup ? "Вимкніть фіксовану націнку" : "Вимкніть використання формул"}>
                                    <span>
                                        <FormControlLabel
                                            disabled
                                            control={
                                                <Switch
                                                    checked={stateUseRetailPrice}
                                                    onChange={() => {
                                                        dispatch(switchUseRetailPrice());
                                                    }}
                                                />
                                            }
                                            label={stateUseRetailPrice ? "Застосовано" : "Не застосовано"}
                                        />
                                    </span>
                                </Tooltip>
                                :
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stateUseRetailPrice}
                                            onChange={() => {
                                                dispatch(switchUseRetailPrice());
                                            }}
                                        />
                                    }
                                    label={stateUseRetailPrice ? "Застосовано" : "Не застосовано"}
                                />
                        }
                    </div>
                    : null
            }
            {
                selectMarkupType === "fixed"
                    ? <div>
                        {
                            stateUseRetailPrice || stateUseFormulaMarkup
                                ?
                                <Tooltip placement="left" title={stateUseRetailPrice ? "Вимкніть викоритсання роздрібної ціни" : "Вимкніть використання формул"}>
                                    <span>
                                        <FormControlLabel
                                            disabled
                                            control={
                                                <Switch
                                                    checked={stateUseFixedMarkup}
                                                    onChange={() => {
                                                        dispatch(switchUseFixedMarkup());
                                                    }}
                                                />
                                            }
                                            label={stateUseFormulaMarkup ? "Вимкнути" : "Увімкнути"}
                                        />
                                    </span>
                                </Tooltip>
                                :
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stateUseFixedMarkup}
                                            onChange={() => {
                                                dispatch(switchUseFixedMarkup());
                                            }}
                                        />
                                    }
                                    label={stateUseFixedMarkup ? "Вимкнути" : "Увімкнути"}
                                />
                        }
                        <div className="my-4 relative">
                            {
                                stateUseFixedMarkup
                                    ? null
                                    : <div className="w-full h-full absolute z-20"></div>
                            }
                            <div className={(stateUseFixedMarkup ? "p-2" : " opacity-50 p-2")}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Націнка"
                                    variant="outlined"
                                    value={(stateFixedPrice + "") || "0"}
                                    onChange={(e) => {
                                        dispatch(setFixedPrice(e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0 : 0))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                selectMarkupType === "formula"
                    ? <div>
                        {
                            stateUseFixedMarkup || stateUseRetailPrice
                                ?
                                <Tooltip placement="left" title={stateUseFixedMarkup ? "Вимкніть фіксовану націнку" : "Вимкніть викоритсання роздрібної ціни"}>
                                    <span>
                                        <FormControlLabel
                                            disabled
                                            control={
                                                <Switch
                                                    checked={stateUseFormulaMarkup}
                                                    onChange={() => {
                                                        dispatch(switchUseFormulaMarkup());
                                                    }}
                                                />
                                            }
                                            label={stateUseFormulaMarkup ? "Вимкнути" : "Увімкнути"}
                                        />
                                    </span>
                                </Tooltip>
                                :
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stateUseFormulaMarkup}
                                            onChange={() => {
                                                dispatch(switchUseFormulaMarkup());
                                            }}
                                        />
                                    }
                                    label={stateUseFormulaMarkup ? "Вимкнути" : "Увімкнути"}
                                />
                        }
                        <div className={(stateUseFormulaMarkup ? "relative" : " relative opacity-50")}>
                            {
                                stateUseFormulaMarkup
                                    ? null
                                    : <div className="w-full h-full absolute z-20"></div>
                            }
                            <div className="flex flex-col items-center gap-2 my-2 relative p-2">
                                {
                                    stateFormulaPrice[0]
                                        ? <Formula key={0} data={stateFormulaPrice[0]} index={0} mode="first" />
                                        : null
                                }
                                {
                                    stateFormulaPrice.map((item, index) => {
                                        if (index !== 0 && index !== (stateFormulaPrice.length - 1)) {
                                            return <Formula key={index} data={item} index={index} />
                                        } else {
                                            return null
                                        }
                                    })
                                }
                                {
                                    (stateFormulaPrice.length - 1)
                                        ? <Formula key={stateFormulaPrice.length - 1} data={stateFormulaPrice[stateFormulaPrice.length - 1]} index={stateFormulaPrice.length - 1} mode="last" />
                                        : null
                                }
                                <Tooltip title="Додати формулу" arrow>
                                    <Button
                                        variant="contained"
                                        sx={{ minWidth: "40px", width: "40px", height: "100%" }}
                                        onClick={() => dispatch(addFormulaPrice())}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                selectMarkupType === "maxdrop"
                    ? <div className="my-2">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={stateUseMaxDropPrice}
                                    onChange={() => {
                                        dispatch(switchUseMaxDropPrice());
                                    }}
                                />
                            }
                            label={stateUseMaxDropPrice ? "Застосовано" : "Не застосовано"}
                        />
                    </div>
                    : null
            }
        </div >
    )
}