import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import {TextField, Pagination, CircularProgress, ButtonGroup, Button} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import ProductsProps from "./interfaces";
import { setEasydropPaginatonLimit, setEasydropSearchText, updateEasydropSelectedProducts } from "../../../../slices/integrations/easydrop/slice";

export default function Products({ show, setIsShow, idSuppliers, onPaginaton }: ProductsProps) {
  const suppliersProducts = useAppSelector((state) => state.easydrop.suppliersProducts.productsList);
  const selectedProductsIDs = useAppSelector((state) => state.easydrop.selectedProductsIDs);

  const [load, setLoad] = useState<boolean>(false);
  const [paginationIndex, setPaginationIndex] = useState<number>(0);
  const searchText = useAppSelector((state) => state.easydrop.suppliersProducts.searchText);
  const paginationLimit = useAppSelector((state) => state.easydrop.suppliersProducts.paginationLimit);
  const totalItems = useAppSelector((state) => state.easydrop.suppliersProducts.total_items);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
      setIsShow(false);
      setPaginationIndex(0);
    };
  }, []);

  if (show) {
    return (
      <div className="modal z-[2000000]">
        <div
          className=" px-7  h-[100%] overflow-auto bg-white"
          style={{ width: "clamp(28.75rem, -20.034rem + 103.37vw, 90rem)" }}
        >
          <div className="sticky top-0 bg-white z-[1000]">
            <div
              className="ml-auto w-fit mb-4 cursor-pointer"
              onClick={(e) => {
                document.body.style.overflow = "";
                setPaginationIndex(0);
                setIsShow(false);
              }}
            >
              <CloseIcon fontSize="large" />
            </div>
            <div className="shadow">
              <div className="mb-4 relative bg-white ">
                <TextField
                  fullWidth
                  id="search"
                  label="Знайдіть товар за артикулом"
                  variant="filled"
                  value={searchText}
                  onChange={(e) => {
                    dispatch(setEasydropSearchText(e.target.value));
                    onPaginaton(
                      idSuppliers,
                      setLoad,
                      0,
                      paginationLimit,
                      e.target.value
                    );
                  }}
                />
                <div
                  className="absolute right-10 bottom-[50%] translate-y-1/2 cursor-pointer max-[384px]:hidden"
                  onClick={() =>
                    onPaginaton(
                      idSuppliers,
                      setLoad,
                      0,
                      paginationLimit,
                      searchText
                    )
                  }
                >
                  <SearchIcon />
                </div>
              </div>
              <div className="ml-auto w-fit flex items-center max-[586px]:flex-col gap-1 max-[586px]:justify-center">
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                  <Button
                    variant={paginationLimit === 10 ? "contained" : "outlined"}
                    onClick={() => {
                      onPaginaton(idSuppliers, setLoad, 0, 10);

                      setPaginationIndex(0);
                      dispatch(setEasydropPaginatonLimit(10));
                    }}
                  >
                    10
                  </Button>
                  <Button
                    variant={paginationLimit === 20 ? "contained" : "outlined"}
                    onClick={() => {
                      onPaginaton(idSuppliers, setLoad, 0, 20);

                      setPaginationIndex(0);
                      dispatch(setEasydropPaginatonLimit(20));
                    }}
                  >
                    20
                  </Button>
                  <Button
                    variant={paginationLimit === 50 ? "contained" : "outlined"}
                    onClick={() => {
                      onPaginaton(idSuppliers, setLoad, 0, 50);

                      setPaginationIndex(0);
                      dispatch(setEasydropPaginatonLimit(50));
                    }}
                  >
                    50
                  </Button>
                  <Button
                    variant={paginationLimit === 100 ? "contained" : "outlined"}
                    onClick={() => {
                      onPaginaton(idSuppliers, setLoad, 0, 100);

                      setPaginationIndex(0);
                      dispatch(setEasydropPaginatonLimit(100));
                    }}
                  >
                    100
                  </Button>
                </ButtonGroup>
                <Pagination
                  showFirstButton
                  showLastButton
                  count={Math.ceil(totalItems / paginationLimit) || 1}
                  page={paginationIndex + 1}
                  onChange={(e, value) => {
                    onPaginaton(
                      idSuppliers,
                      setLoad,
                      paginationLimit * (value - 1),
                      paginationLimit
                    );
                    setPaginationIndex(value - 1);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className={
                load
                  ? "relative opacity-40 overflow-x-auto"
                  : "relative overflow-x-auto"
              }
            >
              <div className="flex mt-3 px-4 py-6 overflow-x-auto w-[100%] max-[1200px]:w-[1000px] bg-gray-300 text-sm font-medium rounded-t-[10px]">
                <div className="w-[125px]">Фото</div>
                <div className="border-r px-3 border-slate-200 border-solid">
                  Артикул
                </div>
                <div className="w-[300px] border-r px-3 border-slate-200 border-solid">
                  Назва товару
                </div>
                <div className="w-[100px] border-r px-3 border-slate-200 border-solid">
                  Ціна
                </div>
                <div className="px-3 ">Доступні розміри</div>
              </div>
              {load ? (
                <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full ">
                  <CircularProgress />
                </div>
              ) : null}
              {suppliersProducts.map((item) => {
                return (
                  <div
                    className={
                      "shadow flex w-[100%] mb-3 hover:bg-sky-100 cursor-pointer transition max-[1200px]:w-[1000px]" +
                      (selectedProductsIDs[idSuppliers] === item.id
                        ? " bg-sky-200"
                        : " bg-white")
                    }
                    key={item.id}
                    onClick={() => {
                      document.body.style.overflow = "";
                      setIsShow(false);
                      setPaginationIndex(0);
                      dispatch(
                        updateEasydropSelectedProducts({ ...item, supplier_id: idSuppliers })
                      );
                    }}
                  >
                    <div className="w-[120px] overflow-hidden shrink-0 mr-3">
                      <img
                        src={item.image_url}
                        alt=""
                        className="w-[100%] object-center"
                      />
                    </div>
                    <div className="flex items-center py-2">
                      <div className="w-[90px] border-r px-3 border-slate-200 border-solid">
                        <p className="font-bold text-center">{item.sku}</p>
                      </div>
                      <div className="w-[300px] border-r px-3 border-slate-200 border-solid">
                        <p className="font-bold">{item.name}</p>
                      </div>
                      <div className="w-[100px] border-r px-3 border-slate-200 border-solid">
                        <p className="font-bold">{item.price}</p>
                      </div>
                      <div className="border-r px-3 border-slate-200 border-solid">
                        <div className="flex gap-2 flex-wrap max-[1094px]:flex-nowrap max-[1100px]:max-w[160px]">
                          {item.sizes.map((size, i) => (
                            <div key={i} className="flex flex-col items-center">
                              <p className="font-bold">{size.size}</p>
                              <p className="border border-slate-600 border-solid rounded px-2 py-1">
                                {size.qty}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
