import { useState } from "react";

import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import LinkMenu from "../menu";

import LinkRowProps from "./interfaces";

export default function LinkRow({ dataLink, marketplaceId, onDelete }: LinkRowProps) {
    const [successClickBoardCopy, setSuccessClickBoardCopy] = useState<boolean>(false);
    const [successDownload, setSuccessDownload] = useState<boolean>(false);

    function onClickBoardCopy(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e.currentTarget.dataset.url) {
            navigator.clipboard.writeText(e.currentTarget.dataset.url).then(() => {
                setSuccessClickBoardCopy(true);
                setTimeout(() => {
                    setSuccessClickBoardCopy(false);
                }, 2400);
            })
        }
    }

    function onClickDownload() {
        setSuccessDownload(true);
        setTimeout(() => {
            setSuccessDownload(false);
        }, 2400);
    }

    return (
        <div className="flex p-1 gap-2 max-lg:flex-col">
            <div className="flex grow gap-2">
                <div className="flex items-center grow border-[1px] border-solid border-[#1976d2] rounded p-3">
                    {dataLink.name}
                </div>
                <div className="flex items-center border-[1px] border-solid border-[#1976d2] rounded p-3">
                    {dataLink.file_format}
                </div>
            </div>
            <div className="flex gap-2 max-[530px]:hidden">
                <Button
                    variant="outlined"
                    sx={{ minWidth: "260px" }}
                    color={successClickBoardCopy ? "success" : "primary"}
                    endIcon={<ContentCopyIcon />}
                    data-url={dataLink.link}
                    onClick={onClickBoardCopy}
                >
                    <span>{successClickBoardCopy ? "Скопійовано" : "Скопіювати посилання"}</span>
                </Button>
                <a href={dataLink.link}>
                    <Button
                        variant="outlined"
                        sx={{ height: "100%" }}
                        color={successDownload ? "success" : "primary"}
                        fullWidth
                        endIcon={<CloudUploadIcon fontSize="large" />}
                        onClick={onClickDownload}
                    >
                        <span>Завантажити</span>
                    </Button>
                </a>
                <div className="flex items-center border-[1px] border-solid border-[#1976d2] rounded-full h-fit self-center">
                    <LinkMenu
                        linkId={dataLink.id}
                        marketplaceId={marketplaceId}
                        onDelete={onDelete}
                    />
                </div>
            </div>
            <div className="hidden gap-2 max-[530px]:flex justify-evenly">
                <div className="h-[45px] w-[45px] flex items-center justify-center shrink-0">
                    <Button
                        variant="outlined"
                        color={successClickBoardCopy ? "success" : "primary"}
                        data-url={dataLink.link}
                        onClick={onClickBoardCopy}
                        sx={{ minWidth: "45px", width: "45px", height: "100%", borderRadius: "999px" }}
                    >
                        <ContentCopyIcon />
                    </Button>
                </div>
                <a
                    href={dataLink.link}
                    className="h-[45px] w-[45px] flex items-center justify-center shrink-0"
                >
                    <Button
                        variant="outlined"
                        sx={{ minWidth: "45px", width: "45px", height: "100%", borderRadius: "999px" }}
                        color={successDownload ? "success" : "primary"}
                        fullWidth
                        onClick={onClickDownload}
                    >
                        <CloudUploadIcon fontSize="large" />
                    </Button>
                </a>
                <div className="flex items-center border-[1px] border-solid border-[#1976d2] rounded-full w-fit h-fit self-center">
                    <LinkMenu
                        linkId={dataLink.id}
                        marketplaceId={marketplaceId}
                        onDelete={onDelete}
                    />
                </div>
            </div>
        </div>
    )
}