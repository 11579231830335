import { useAppSelector } from "../../../../store/hooks";

import { submitFunks } from "../../../../slices/modal/slice";

import LoadingButton from "@mui/lab/LoadingButton";

export default function ModalSendButton() {
    const props = useAppSelector(state => state.modal.modalProps);
    const value = useAppSelector(state => state.modal.modalValue);
    const sendStatus = useAppSelector(state => state.modal.sendStatus);

    function onSubmit() {
        submitFunks[props.submitFunkID](value);
    }

    const btnStatus: Record<string, "primary" | "error" | "success"> = {
        idle: "primary",
        error: "error",
        success: "success"
    }

    return (
        <LoadingButton
            loading={sendStatus === "loading"}
            loadingPosition="center"
            endIcon=""
            variant="contained"
            onClick={onSubmit}
            color={btnStatus[sendStatus]}
        >
            {props.btnText}
        </LoadingButton>
    )
}