import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import characteristic from '../slices/characteristic/slice';
import sectionsPage from "../slices/sectionsPage/slice"
import modal from '../slices/modal/slice';
import productsTable from '../slices/productsTable/slice';
import product from '../slices/product/slice';
import user from '../slices/user/slice';
import link from "../slices/link/slice";
import easydrop from "../slices/integrations/easydrop/slice";
import horoshop from "../slices/integrations/horoshop/slice";
import prom from "../slices/integrations/prom/slice"

export const store = configureStore({
  reducer: { characteristic, sectionsPage, modal, productsTable, product, user, link, easydrop, horoshop, prom }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;