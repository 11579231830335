import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { switchSelectedCharacteristic } from "../../../../slices/product/slice";

import { Autocomplete, TextField } from "@mui/material";

import { CharacteristicType } from "../../../../slices/characteristic/interfaces";

export default function ColectionCheckboxVariation({ char }: { char: CharacteristicType }) {
    const selectedData = useAppSelector(state => state.product.characteristicsSelected);

    const dispatch = useAppDispatch();

    return (
        <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={char.values.map(value => ({
                label: value.name["uk"],
                id: value.id
            }))}
            renderInput={(params) => <TextField {...params} label={char.name["uk"]} />}
            value={char.values.filter(value => selectedData[char.id + "|v"]?.includes(value.id) || selectedData[char.id ]?.includes(value.id)).map(value => ({
                label: value.name["uk"],
                id: value.id
            }))[0]}
            onChange={(event, value) => {
                dispatch(switchSelectedCharacteristic({
                    characteristic_id: char.id,
                    value_id: value?.id
                }));
            }}
        />
    )
}