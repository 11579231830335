import { useEffect, useState } from "react";

import axiosAuth from "../../../API/auth";

import { useAppDispatch } from "../../../store/hooks";
import { userClear } from "../../../slices/user/slice";
import { setErrorText } from "../../../slices/modal/slice";

export default function Availability({ size }: { size: { size_id: number, qty: number, size: string } }) {
    const [value, setValue] = useState<string>("0");
    const [statusSaveAvailability, setStatusSaveAvailability] = useState<"" | "#00dc0b4f" | "#ff01016e">("")

    const dispatch = useAppDispatch();

    useEffect(() => {
        setValue(size.qty + "")
    }, [size])

    function onSubmit() {
        axiosAuth()
            .put("/size/", JSON.stringify({
                id: size.size_id,
                qty: parseInt(value)
            }), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                setStatusSaveAvailability("#00dc0b4f")
                setTimeout(() => {
                    setStatusSaveAvailability("")
                }, 2400);
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setStatusSaveAvailability("#ff01016e")
                    setTimeout(() => {
                        setStatusSaveAvailability("")
                    }, 2400);
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                }
            })
    }

    return (
        <div className="flex flex-col items-center w-[40px]">
            <p className="font-bold">{size.size}</p>
            <input
                className=" border-2 border-cyan-600 border-solid w-[100%] p-2 rounded-md bg-inherit"
                style={{ background: statusSaveAvailability, transition: "0.4s" }}
                type="text"
                value={value || "0"}
                onChange={(e) => {
                    setValue(e.target.value ? ((parseInt(e.target.value) > 0 ? parseInt(e.target.value) : "0") + "") : "0")
                }}
                onBlur={onSubmit}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.currentTarget.querySelector("input")?.blur();
                        onSubmit();
                    }
                }}
            />
        </div>
    )
}