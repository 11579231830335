import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { addAvailability, removeAvailability, updateSelectedCharacteristic } from "../../../../slices/product/slice";

import { TextField, Checkbox, Autocomplete } from "@mui/material";

import BasicCheckboxListProps from "../interfaces";

export default function ColectionCheckbox({ char, type = "basic" }: BasicCheckboxListProps) {
    const selectedData = useAppSelector(state => state.product.characteristicsSelected);

    const dispatch = useAppDispatch();

    return <Autocomplete
        isOptionEqualToValue={(option, value) => option.id === value.id}
        multiple
        options={char.values.map(value => {
            return {
                label: value.name["uk"],
                id: value.id
            }
        })}
        noOptionsText={"Нічого не знайдено"}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
            <li
                {...props}
            >
                <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.label}
            </li>
        )}
        renderInput={(params) => (
            <TextField {...params} label={char.name["uk"]} />
        )}
        onChange={(event, newValue) => {
            console.log(newValue)

            const addValue = newValue.filter(value => {
                if (selectedData[char.id]) {
                    return !selectedData[char.id].includes(value.id)
                } else {
                    return true
                }
            });

            const removeValueID = selectedData[char.id]?.filter(value => !newValue.some(item => item.id === value))[0];
            const removeValueLabel = char.values.filter(value => value.id === removeValueID)

            const removeValue = [];
            if (removeValueID) {
                removeValue.push({
                    id: removeValueID,
                    label: removeValueLabel[0]?.name['uk']
                })
            }

            const cangesValues = [...addValue, ...removeValue];

            cangesValues.forEach(value => {
                dispatch(updateSelectedCharacteristic({
                    characteristic_id: char.id,
                    value_id: value.id
                }));
                if (type === "size") {
                    if (!selectedData[char.id]?.includes(value.id)) {
                        dispatch(addAvailability(value.label));
                    } else {
                        dispatch(removeAvailability(value.label))
                    }
                }
            })
        }}
        value={char.values.filter(value => selectedData[char.id]?.includes(value.id)).map(value => ({
            label: value.name["uk"],
            id: value.id
        }))}
    />
}