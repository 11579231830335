import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { setQueryParams, setSortedOtherCol, setSortedPriceCol } from "../../../slices/productsTable/slice";

import { Button, FormControl, InputLabel, MenuItem, Select, TextField, InputAdornment, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from '@mui/icons-material/Search';

export default function Actions({ onFetch }: { onFetch: (queryParams: string) => void }) {
  const sortedOtherCol = useAppSelector((state) => state.productsTable.sortedOtherCol);
  const sortedPriceCol = useAppSelector((state) => state.productsTable.sortedPriceCol);

  const productTypes = useAppSelector(state => state.productsTable.productTypes);

  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(useAppSelector(state => state.productsTable.queryParams));
  
  const currentProductType = queryParams.get("p") || "0";
  const searchText = queryParams.get("search") || "";

  return (
    <div className="flex items-center gap-4 mb-4 mt-2 justify-center max-[740px]:flex-col max-[740px]:gap-2">
      <div className="flex items-center gap-4 justify-start w-[35%] max-[740px]:w-[90%]">
        <Link
          to={"/product/add/" + currentProductType}
          title="Додати товар"
          className="h-[50px] w-[50px] rounded-full overflow-hidden ml-2 flex items-center justify-center shrink-0"
        >
          <Button
            variant="contained"
            sx={{ minWidth: "50px", width: "50px", height: "100%" }}
          >
            <AddIcon />
          </Button>
        </Link>
        <div className="bg-white w-full">
          <TextField
            fullWidth
            label="Пошук за артикулом або назвою товару"
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              queryParams.set("search", e.target.value);
              queryParams.set("offset", "0");
              dispatch(setQueryParams(queryParams.toString()));

              onFetch(queryParams.toString());
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      dispatch(setQueryParams(queryParams.toString()));
                      onFetch(queryParams.toString())
                    }}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-4 justify-center w-[60%] max-[740px]:w-[90%] max-[570px]:flex-col max-[570px]:gap-2">
        <FormControl className="w-[200px]">
          <InputLabel id="demo-simple-select-label">Сортувати за...</InputLabel>
          <Select
            value={sortedOtherCol}
            label="Сортувати за..."
            onChange={(e) => {
              dispatch(setSortedOtherCol(e.target.value));
            }}
          >
            <MenuItem value={""}>Замовчуванням</MenuItem>
            <MenuItem value={"sku"}>Артикулом</MenuItem>
            <MenuItem value={"name"}>Назвою товару</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="w-[200px]">
          <InputLabel id="demo-simple-select-label">
            Сортувати за ціною
          </InputLabel>
          <Select
            value={sortedPriceCol}
            label="Сортувати за ціною"
            onChange={(e) => {
              dispatch(setSortedPriceCol(e.target.value));
            }}
          >
            <MenuItem value={""}>По замовчуванню</MenuItem>
            <MenuItem value={"asc"}>За зростанням</MenuItem>
            <MenuItem value={"desc"}>За спаданням</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="w-[200px]">
          <InputLabel id="demo-simple-select-label">Категорія товарів</InputLabel>
          <Select
            value={currentProductType !== "0" ? currentProductType : ""}
            label="Категорія товарів"
            onChange={(e) => {
              queryParams.set("p", e.target.value + "");
              dispatch(setQueryParams(queryParams.toString()));

              onFetch(queryParams.toString());
            }}
          >
            {productTypes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
