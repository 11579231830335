import { createSlice } from "@reduxjs/toolkit";
import ProductSlice from "./interfaces";
import { AddAvailabilityType, AddSelectedCategoriesType, ProductFetchedType, RemoveAvailabilityType, RemoveSelectedCategoriesType, SetBaseFieldType, SwitchSelectedCharacteristicType, UpdateAvailabilityType, UpdatePicturesType, UpdateSelectedCharacteristicType } from "./actionsInterfaces";

const initialState: ProductSlice = {
    base: {},
    availability: [],

    categoriesSelected: [],
    characteristicsSelected: {},

    pictures: []
}

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        productFetched: (state, action: ProductFetchedType) => {
            state.base = action.payload?.product?.base || {};

            state.availability = action.payload?.product?.availability || [];
            state.pictures = action.payload?.product?.pictures || [];

            const tempCharacteristicsSelected: Record<string, number[]> = {}
            Object.keys(action.payload?.product?.characteristics || {}).forEach(charId => {
                const element = action.payload?.characteristics?.find(item => item.id === parseInt(charId))

                if (element?.is_variation) {
                    tempCharacteristicsSelected[charId + "|v"] = action.payload?.product?.characteristics?.[charId] || []
                } else {
                    tempCharacteristicsSelected[charId] = action.payload?.product?.characteristics?.[charId] || []
                }
            });

            state.characteristicsSelected = tempCharacteristicsSelected;
            state.categoriesSelected = action.payload?.product?.categories || [];
        },

        setBaseField: (state, action: SetBaseFieldType) => {
            state.base = {
                ...state.base,
                [action.payload.name]: action.payload.value
            }
        },

        switchSelectedCharacteristic: (state, action: SwitchSelectedCharacteristicType) => {
            const { characteristic_id, value_id } = action.payload;

            state.characteristicsSelected = {
                ...state.characteristicsSelected,
                [characteristic_id + "|v"]: [value_id || 0]
            }
        },

        updateSelectedCharacteristic: (state, action: UpdateSelectedCharacteristicType) => {
            const copyState: Record<number, number[]> = JSON.parse(JSON.stringify(state.characteristicsSelected));

            const IDsCharacteristic = Object.keys(copyState);
            const { characteristic_id, value_id } = action.payload;

            if (IDsCharacteristic.includes(characteristic_id + "")) {
                const IDsValue = copyState[characteristic_id];
                if (IDsValue.includes(value_id)) {
                    copyState[characteristic_id] = copyState[characteristic_id].filter(id => id !== value_id);
                } else {
                    copyState[characteristic_id].push(value_id);
                }
            } else {
                copyState[characteristic_id] = [value_id];
            }

            state.characteristicsSelected = copyState;
        },

        addSelectedCategories: (state, action: AddSelectedCategoriesType) => {
            let copyState: number[] = JSON.parse(JSON.stringify(state.categoriesSelected));

            copyState.push(action.payload)

            state.categoriesSelected = copyState
        },
        removeSelectedCategories: (state, action: RemoveSelectedCategoriesType) => {
            let copyState: number[] = JSON.parse(JSON.stringify(state.categoriesSelected));

            copyState = copyState.filter(id => id !== action.payload)

            state.categoriesSelected = copyState
        },

        addAvailability: (state, action: AddAvailabilityType) => {
            const copyState: {
                id?: number,
                qty: number,
                size: string
            }[] = JSON.parse(JSON.stringify(state.availability));

            copyState.push({
                size: action.payload,
                qty: 0
            })

            state.availability = copyState;
        },
        removeAvailability: (state, action: RemoveAvailabilityType) => {
            state.availability = state.availability.filter(item => item.size !== action.payload);
        },
        updateAvailability: (state, action: UpdateAvailabilityType) => {
            const copyState: {
                id: number,
                qty: number,
                size: string
            }[] = JSON.parse(JSON.stringify(state.availability));

            const { qty, size } = action.payload;

            copyState.forEach(item => {
                if (item.size === size) {
                    item.qty = qty
                }
            })

            state.availability = copyState;
        },

        updatePictures: (state, action: UpdatePicturesType) => {
            state.pictures = action.payload;
        },

        clearSelected: (state) => {
            state = {
                availability: [],
                base: {},

                categoriesSelected: [],
                characteristicsSelected: {},

                pictures: []
            }
        }
    }
})

const { reducer, actions } = productSlice;

export default reducer;

export const {
    productFetched,

    setBaseField,

    switchSelectedCharacteristic,
    updateSelectedCharacteristic,

    addSelectedCategories,
    removeSelectedCategories,

    addAvailability,
    removeAvailability,
    updateAvailability,

    updatePictures,

    clearSelected
} = actions