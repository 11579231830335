import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Link } from 'react-router-dom';

import axiosAuth from '../../../API/auth';

import { deleteProducts, setDataVariatonModal, switchSelectProduct } from '../../../slices/productsTable/slice';
import { userClear } from '../../../slices/user/slice';

import {IconButton, Menu, MenuItem, Divider} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { setErrorText } from '../../../slices/modal/slice';

export default function ProductMenu({ idControlElement }: { idControlElement: number }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
    const open = Boolean(anchorEl);

    const selectProducts = useAppSelector(state => state.productsTable.selectedProducts);

    const [deleteStatus, setDeleteStatus] = useState<"idle" | "loading" | "error">("idle");

    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteProduct = () => {
        setDeleteStatus("loading");

        axiosAuth()
            .delete("/product/?product_ids=" + idControlElement)
            .then(() => {
                dispatch(deleteProducts([idControlElement]));
                if (selectProducts.includes(idControlElement)) {
                    dispatch(switchSelectProduct(idControlElement))
                }
                setDeleteStatus("idle");
                handleClose();
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    console.error(err)
                    dispatch(setErrorText(err.response.data.error))
                    setDeleteStatus("error");
                    setTimeout(() => setDeleteStatus("idle"), 2400)
                }
            });
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    dispatch(setDataVariatonModal(idControlElement))
                    handleClose()
                }}>
                    <AddIcon sx={{ marginRight: "5px" }} />
                    Додати варіацію
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={"product/variation/" + idControlElement}>
                        <AddIcon sx={{ marginRight: "5px" }} />
                        Створити варіацію
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <Link to={"/product/edit/" + idControlElement}>
                        <EditIcon sx={{ marginRight: "5px" }} />
                        Редагувати
                    </Link>
                </MenuItem>
                <MenuItem onClick={deleteProduct}>
                    <LoadingButton
                        sx={[
                            { color: "#212121", padding: "0", textTransform: "none", fontSize: 16 }, {
                                '&:hover': {
                                    backgroundColor: "unset",
                                },
                            }]}
                        loading={deleteStatus === "loading"}
                        loadingPosition="start"
                        startIcon={deleteStatus === "error" ? <PriorityHighIcon sx={{ ml: "5px" }} /> : <DeleteIcon sx={{ ml: "5px" }} />}
                        variant="text"
                    >
                        Видалити
                    </LoadingButton>
                </MenuItem>
            </Menu>
        </div>
    );
}