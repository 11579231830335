import { useAppSelector } from "../../../store/hooks";

import { selectAll } from "../../../slices/characteristic/slice";

import Characteristic from "../item";
import CharacteristicAdd from "../add";

import { CharacteristicType } from "../../../slices/characteristic/interfaces";

export default function CharacteristicList() {
    const currentType = useAppSelector((state) => state.characteristic.currentProductType);

    const characteristics = useAppSelector(selectAll);

    const viewCharacteristics = characteristics.filter(item => item.product_type_id === currentType.id);

    return (
        <div>
            {viewCharacteristics.map((item: CharacteristicType) => (
                <Characteristic key={item.id} data={item} />
            ))}

            <div className={`flex flex-col gap-6 py-4`}>
                <CharacteristicAdd />
            </div>
        </div>
    )
}