import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { characteristicAdd, selectAll } from "../../../slices/characteristic/slice";
import { clearValue, errorSet, hideModal, initModal, submitFunks } from "../../../slices/modal/slice";

import { Button } from "@mui/material";

export default function CharacteristicAddBlock() {
    const characteristics = useAppSelector(selectAll);
    const listLanguage = useAppSelector((state) => state.characteristic.listLanguage);
    const currentType = useAppSelector((state) => state.characteristic.currentProductType);

    const lastIDChar = useRef<number>(Math.max(...characteristics.map(item => item.id), 1));

    useEffect(() => {
        lastIDChar.current = lastIDChar.current + 1;
    }, [characteristics])

    const dispatch = useAppDispatch();

    function onSubmit() {
        submitFunks.addCharacteristic = (modalValue: string) => {
            const values: Record<string, string> = {};
            
            listLanguage.forEach(item => {
                values[item] = modalValue
            });

            if (!modalValue) {
                dispatch(errorSet("Введіть назву"));
            } else {
                dispatch(characteristicAdd({
                    product_type_id: currentType.id,
                    element: {
                        id: lastIDChar.current,
                        name: values,
                        product_type_id: currentType.id,
                        type_name: currentType.name,
                        values: [],
                        is_variation: false,
                        size_responsible: false
                    }
                }));

                dispatch(clearValue())
                dispatch(hideModal())
            }
        }

        dispatch(initModal({
            btnText: "Добавити",
            placeholderText: "Назва характеристики",
            submitFunkID: "addCharacteristic"
        }))
    }

    return (
        <Button onClick={onSubmit}  sx={{ bgcolor: "primary.main" }} variant="contained">Додати характеристику</Button>
    )
}