import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

import { ProductType, ProductTypesType } from "./interfaces";
import { RootState } from "../../store/store";
import { SetDataVariatonModalType, SetProductTypesType, SetSortedOtherColType, SetSortedPriceColType, SwitchSelectProductType, SwitchSelectProductsChildsType, TableFetchedType, TablePaginatonFetchedType } from "./actionsInterfaces";

const productsTableAdapter = createEntityAdapter({
    selectId: (item: ProductType) => item.id,
});

const initialState: {
    productTypes: ProductTypesType[],

    productsPaginationStatus: 'idle' | 'loading' | 'error',
    queryParams: string,
    total_items: number,

    sortedOtherCol: string,
    sortedPriceCol: string,

    selectedProducts: number[],
    selectedProductsChilds: Record<string, number[]>,

    dataVariatonModal: number
} = {
    productTypes: [],

    productsPaginationStatus: 'idle',
    queryParams: "",
    total_items: 0,

    sortedOtherCol: '',
    sortedPriceCol: '',

    selectedProducts: [],
    selectedProductsChilds: {},

    dataVariatonModal: 0
}

const productsTableSlice = createSlice({
    name: "productsTable",
    initialState: productsTableAdapter.getInitialState(initialState),
    reducers: {
        tableFetched: (state, action: TableFetchedType) => {
            productsTableAdapter.setAll(state, action.payload.products);
            state.total_items = action.payload.total_items;
        },

        setProductTypes: (state, action: SetProductTypesType) => {
            state.productTypes = action.payload;
        },

        tablePaginatonFetching: state => { state.productsPaginationStatus = 'loading' },
        tablePaginatonFetchingError: state => { state.productsPaginationStatus = 'error' },
        
        setQueryParams: (state, action: {payload: string, type: string}) => {
            state.queryParams = action.payload;
        },

        tablePaginatonFetched: (state, action: TablePaginatonFetchedType) => {
            productsTableAdapter.setAll(state, action.payload.products);
            state.total_items = action.payload.total_items;
            state.productsPaginationStatus = 'idle';
        },

        addProduct: productsTableAdapter.addOne,
        addProducts: productsTableAdapter.addMany,
        updateProduct: productsTableAdapter.updateOne,
        updateProducts: productsTableAdapter.updateMany,
        deleteProducts: productsTableAdapter.removeMany,
        deleteProductChild: productsTableAdapter.updateMany,
        
        setSortedOtherCol: (state, action: SetSortedOtherColType) => {
            state.sortedOtherCol = action.payload;
        },
        setSortedPriceCol: (state, action: SetSortedPriceColType) => {
            state.sortedPriceCol = action.payload;
        },

        switchSelectAll: (state) => {
            const allProduct = productsTableAdapter.getSelectors().selectAll(state);

            if (state.selectedProducts.length === allProduct.length) {
                state.selectedProducts = [];
                state.selectedProductsChilds = {};
            } else {
                const allProductIds = allProduct.map((row) => row.id);

                state.selectedProducts = allProductIds;

                allProductIds.forEach(parentId => {
                    state.selectedProductsChilds = {
                        ...state.selectedProductsChilds,
                        [parentId]: allProduct.find(item => item.id === parentId)?.children?.map(item => item.id)
                    }
                })
            }
        },
        switchSelectProduct: (state, action: SwitchSelectProductType) => {
            const allProduct = productsTableAdapter.getSelectors().selectAll(state);

            if (state.selectedProducts.includes(action.payload)) {
                state.selectedProducts = state.selectedProducts.filter(id => id !== action.payload);
                const copy = JSON.parse(JSON.stringify(state.selectedProductsChilds));

                delete copy[action.payload];

                state.selectedProductsChilds = copy;
            } else {
                state.selectedProducts = [...state.selectedProducts, action.payload];
                state.selectedProductsChilds = {
                    ...state.selectedProductsChilds,
                    [action.payload]: allProduct.find(item => item.id === action.payload)?.children?.map(item => item.id)
                }
            }
        },
        switchSelectProductsChilds: (state, action: SwitchSelectProductsChildsType) => {
            if (state.selectedProductsChilds[action.payload.parentId]?.includes(action.payload.childId)) {
                const newArr = state.selectedProductsChilds[action.payload.parentId].filter(childId => {
                    return childId !== action.payload.childId;
                });

                if (newArr.length === 0) {
                    const copy = JSON.parse(JSON.stringify(state.selectedProductsChilds));

                    delete copy[action.payload.parentId];

                    state.selectedProductsChilds = copy;
                } else {
                    state.selectedProductsChilds[action.payload.parentId] = newArr;
                }
            } else {
                state.selectedProductsChilds = {
                    ...state.selectedProductsChilds,
                    [action.payload.parentId]: [
                        ...(state.selectedProductsChilds[action.payload.parentId] || []),
                        action.payload.childId
                    ]
                }
            }
        },

        clearSelectProduct: state => { state.selectedProducts = [] },
        clearSelectProductsChilds: state => { state.selectedProductsChilds = {} },

        setDataVariatonModal: (state, action: SetDataVariatonModalType) => { state.dataVariatonModal = action.payload }
    }
});

const { reducer, actions } = productsTableSlice;

export default reducer;

export const { selectAll } = productsTableAdapter.getSelectors<RootState>(state => state.productsTable);

export const {
    tableFetched,

    setProductTypes,

    setQueryParams,

    tablePaginatonFetching,
    tablePaginatonFetchingError,
    tablePaginatonFetched,

    addProduct,
    addProducts,
    updateProduct,
    updateProducts,
    deleteProducts,
    deleteProductChild,

    setSortedOtherCol,
    setSortedPriceCol,

    switchSelectAll,
    switchSelectProduct,
    switchSelectProductsChilds,

    clearSelectProduct,
    clearSelectProductsChilds,

    setDataVariatonModal
} = actions