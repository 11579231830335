import { useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { Link } from 'react-router-dom';

import axiosAuth from '../../../API/auth';

import { userClear } from '../../../slices/user/slice';

import { Menu, MenuItem, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import LinkMenuProps from './interfaces';
import { setErrorText } from '../../../slices/modal/slice';

export default function LinkMenu({ linkId, marketplaceId, onDelete }: LinkMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
    const open = Boolean(anchorEl);

    const [deleteStatus, setDeleteStatus] = useState<"idle" | "loading" | "error">("idle");

    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteProduct = () => {
        setDeleteStatus("loading");

        axiosAuth()
            .delete("/v2/links/?id=" + linkId)
            .then(() => {
                onDelete(linkId, marketplaceId)
                setDeleteStatus("idle");
                handleClose();
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setDeleteStatus("error");
                    setTimeout(() => setDeleteStatus("idle"), 2400)
                    console.error(err);
                    dispatch(setErrorText(err.response.data.error))
                }
            });
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose}>
                    <Link to={"/link/edit/" + linkId}>
                        <EditIcon sx={{ marginRight: "5px" }} />
                        Редагувати
                    </Link>
                </MenuItem>
                <MenuItem onClick={deleteProduct}>
                    <LoadingButton
                        sx={[
                            { color: "#212121", padding: "0", textTransform: "none", fontSize: 16 }, {
                                '&:hover': {
                                    backgroundColor: "unset",
                                },
                            }]}
                        loading={deleteStatus === "loading"}
                        loadingPosition="start"
                        startIcon={deleteStatus === "error" ? <PriorityHighIcon sx={{ ml: "5px" }} /> : <DeleteIcon sx={{ ml: "5px" }} />}
                        variant="text"
                    >
                        Видалити
                    </LoadingButton>
                </MenuItem>
            </Menu>
        </div>
    );
}