import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import axiosAuth from "../../../API/auth";

import { userClear } from "../../../slices/user/slice";
import { addProducts, clearSelectProduct, clearSelectProductsChilds, deleteProductChild, deleteProducts, selectAll, updateProducts } from "../../../slices/productsTable/slice";

import LoadingButton from "@mui/lab/LoadingButton";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { ProductType } from "../../../slices/productsTable/interfaces";
import { setErrorText } from "../../../slices/modal/slice";

export default function MultipleSelectActions() {
    const allData = useAppSelector(selectAll);
    const selectProducts = useAppSelector(state => state.productsTable.selectedProducts);
    const selectedProductsChilds = useAppSelector(state => state.productsTable.selectedProductsChilds);

    const [deleteStatus, setDeleteStatus] = useState<"idle" | "loading" | "error">("idle");
    const [disconnectStatus, setDisconnectStatus] = useState<"idle" | "loading" | "error">("idle");

    const dispatch = useAppDispatch();

    function deleteProduct() {
        let ids = selectProducts.concat(...Object.values(selectedProductsChilds)).join(",");

        setDeleteStatus("loading");

        axiosAuth()
            .delete("/product/?product_ids=" + ids)
            .then(() => {
                console.log(Object.keys(selectedProductsChilds).map(parentId => ({
                    id: parseInt(parentId),
                    changes: {
                        children: allData[allData.findIndex(item => item.id === parseInt(parentId))].children?.filter(item => {
                            return !selectedProductsChilds[parentId].includes(item.id);
                        })
                    }
                })))

                dispatch(deleteProducts(selectProducts));
                dispatch(deleteProductChild(Object.keys(selectedProductsChilds).map(parentId => ({
                    id: parseInt(parentId),
                    changes: {
                        children: allData[allData.findIndex(item => item.id === parseInt(parentId))].children?.filter(item => {
                            return !selectedProductsChilds[parentId].includes(item.id);
                        })
                    }
                }))))

                dispatch(clearSelectProduct());
                dispatch(clearSelectProductsChilds());

                setDeleteStatus("idle");
            }).catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setDeleteStatus("error");
                    setTimeout(() => setDeleteStatus("idle"), 2400)
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                }
            });
    };

    function disconnectProducts() {
        setDisconnectStatus("loading");
        const arr: number[] = []

        const idsChildrenElemSelected = arr.concat(...Object.values(selectedProductsChilds));
        const idsParentElemSelected = Object.keys(selectedProductsChilds).map(id => parseInt(id));

        axiosAuth()
            .delete("/variations/?children=" + idsChildrenElemSelected.join(","))
            .then(() => {
                const childrenElems: ProductType[] = []

                idsParentElemSelected.forEach(id => {
                    const parentElem = allData.find(item => item.id === id)

                    childrenElems.push(...parentElem?.children?.filter(item => {
                        return selectedProductsChilds[id].includes(item.id)
                    }).map(item => ({
                        ...item,
                        parent_id: undefined
                    })) || [])

                })

                dispatch(addProducts(childrenElems));

                const arrParentElemChildren = idsParentElemSelected.map(id => {
                    const parentElem = allData.find(item => item.id === id)

                    return {
                        id: id,
                        changes: {
                            children: parentElem?.children?.filter(item => {
                                return !selectedProductsChilds[id].includes(item.id)
                            })
                        }
                    }
                })

                setDisconnectStatus("idle");
                dispatch(clearSelectProductsChilds());
                dispatch(updateProducts(arrParentElemChildren))
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    setDisconnectStatus("error");
                    setTimeout(() => setDisconnectStatus("idle"), 2400)
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                }
            });
    }

    return (
        ((selectProducts.length > 0) || (Object.keys(selectedProductsChilds).length > 0))
            ?
            <div className='fixed flex gap-4 p-4 bottom-4 right-4 z-20 bg-white shadow rounded'>
                <LoadingButton
                    onClick={() => {
                        deleteProduct();
                    }}
                    loading={deleteStatus === "loading"}
                    startIcon={deleteStatus === "error" ? <PriorityHighIcon /> : null}
                    variant="contained"
                    color="error"
                >
                    <span>Видалити</span>
                </LoadingButton>
                {
                    (Object.keys(selectedProductsChilds).length > 0)
                        ?
                        <LoadingButton
                            onClick={() => {
                                disconnectProducts();
                            }}
                            loading={disconnectStatus === "loading"}
                            startIcon={disconnectStatus === "error" ? <PriorityHighIcon /> : null}
                            variant="contained"
                            color="warning"
                        >
                            <span>Від'єднати</span>
                        </LoadingButton>
                        : null
                }
            </div>
            : null
    )
}