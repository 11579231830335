import { ChangeEvent, DragEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Compressor from "compressorjs";

import { updatePictures } from '../../../slices/product/slice';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { OldFile } from '../../../pages/product/interface';

export const objFiles: Record<string, File> = {};

export default function Dropzone() {
    const oldPictures = useAppSelector(state => state.product.pictures);

    const input = useRef<HTMLInputElement>(null);
    const maxIDObjFiles = useRef<number>(0);

    const dispatch = useAppDispatch();

    const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files) as File[];

        const compessImg = await compressedImage(newFiles);

        Promise.all([...compessImg]).then(images => {
            const newImages: Record<string, File> = {}

            images.forEach(item => {
                newImages[maxIDObjFiles.current] = item;
                objFiles[maxIDObjFiles.current] = item;

                maxIDObjFiles.current = maxIDObjFiles.current + 1;
            })

            dispatch(updatePictures([...oldPictures, ...Object.keys(newImages)]))
        })
    };

    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const newFiles = Array.from(e.target.files as FileList) as File[];

        if (input.current) input.current.value = "";

        const compessImg = await compressedImage(newFiles);

        Promise.all([...compessImg]).then(images => {
            const newImages: Record<string, File> = {}

            images.forEach(item => {
                newImages[maxIDObjFiles.current] = item;
                objFiles[maxIDObjFiles.current] = item;

                maxIDObjFiles.current = maxIDObjFiles.current + 1;
            })

            dispatch(updatePictures([...oldPictures, ...Object.keys(newImages)]))
        })
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;
        if (input.current) input.current.value = "";

        const updatedItems = [...oldPictures];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        dispatch(updatePictures(updatedItems))
    };

    const removeFile = (index: number) => {
        const updatedFiles = [...oldPictures];
        updatedFiles.splice(index, 1);
        dispatch(updatePictures(updatedFiles))
    };

    return (
        <div
            className='w-full'
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
        >
            <p className="font-bold mb-2">Фото</p>
            <label
                htmlFor="files"
                className='block mb-[20px] cursor-pointer text-center p-[20px] rounded-[4px] border-2 border-dashed border-[#cccccc]'
            >
                Перетягніть сюди файли
                <input
                    ref={input}
                    id='files'
                    type="file"
                    accept="image/*"
                    onChange={handleFileInputChange}
                    multiple
                    style={{ opacity: 0, visibility: 'hidden', width: 0, height: 0 }}
                />
            </label>
            <div className='w-full overflow-auto'>
                <div style={{ width: `${oldPictures.length * 180}px` }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided) => (
                                <div
                                    className='flex flex-row flex-wrap'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {oldPictures.map((file: (string | OldFile), index) => {
                                        let id = index + "-d";
                                        return (
                                            <Draggable key={id} draggableId={id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div style={{ position: 'relative' }}>
                                                            <img
                                                                key={index}
                                                                src={typeof file === "string" ? URL.createObjectURL(objFiles[file]) : file.url}
                                                                alt={`Preview ${index}`}
                                                                className='relative z-[5] w-[150px] h-[150px]'
                                                            />
                                                            <div className='absolute top-1 z-[6] right-1 bg-white rounded-full' onClick={(e) => {
                                                                e.preventDefault();
                                                                removeFile(index);
                                                            }}>
                                                                <IconButton aria-label="delete" size="small">
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

async function compressedImage(arrImage: File[]) {
    return arrImage.map(async item => {
        return await new Promise((resolve: (value: File) => void, reject) => {
            new Compressor(item, {
                quality: 0.5,
                success(result) {
                    const newFile = result as File;

                    resolve(newFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    })
}