import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useNavigate, useSearchParams } from "react-router-dom";

import axiosAuth from "../../../API/auth";

import { clearSelectProduct, clearSelectProductsChilds, deleteProducts, selectAll, setDataVariatonModal, updateProduct } from "../../../slices/productsTable/slice";
import { userClear } from "../../../slices/user/slice";

import { Paper, TextField, InputAdornment, IconButton, Divider, Chip, CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import SubmitBtn from "../../shared/submitBtn";

import { ProductType } from "../../../slices/productsTable/interfaces";
import { setErrorText } from "../../../slices/modal/slice";

export default function VariationsModal() {
    const allProduct = useAppSelector(selectAll)
    const idVariatonModal = useAppSelector(state => state.productsTable.dataVariatonModal);

    const [loadingStatus, setLoadingStatus] = useState<"idle" | "error" | "loading">("loading")

    const [searchText, setSearchText] = useState<string>("");
    const [searchData, setSearchData] = useState<ProductType[]>([]);
    const [selectedData, setSelectedData] = useState<{ id: number, name: string }[]>([]);

    const dispatch = useAppDispatch();

    const queryParams = new URLSearchParams(useAppSelector(state => state.productsTable.queryParams));

    const currentProductType = queryParams.get("p") || "0";

    function searctFetch(text: string) {
        setLoadingStatus("loading")

        axiosAuth()
            .get(`/variations/?parent_id=${idVariatonModal}&product_type_id=${currentProductType}${text ? ("&search=" + text) : ""}${!text ? "&offset=0" : ""}${!text ? "&limit=20" : ""}`)
            .then(response => {
                if (response.data.products.length > 0) {
                    setSearchData(response.data.products.filter((item: ProductType) => item.id !== idVariatonModal));
                    setLoadingStatus("idle");
                } else {
                    setSearchData([]);
                    setLoadingStatus("error");
                }
            })
            .catch((err) => {
                if (err === "logaut") {
                    dispatch(userClear());
                } else {
                    console.error(err)
                    setSearchData([]);
                    setLoadingStatus("error");
                    dispatch(setErrorText(err.response.data.error))
                }
            })
    }

    useEffect(() => {
        if (idVariatonModal) {
            searctFetch("");
        }
    }, [idVariatonModal])

    function onSubmit() {
        return axiosAuth().put("/variations/", JSON.stringify({
            parent_id: idVariatonModal,
            children: selectedData.map(item => item.id)
        }), {
            headers: {
                "Content-Type": "application/json"
            }
        })
    }

    if (idVariatonModal) {
        let listProduct:JSX.Element | JSX.Element[] = <div className="flex justify-center items-center h-full"><CircularProgress /></div>

        switch (loadingStatus) {
            case "loading":
                listProduct = <div className="flex justify-center items-center h-full"><CircularProgress /></div>
                break;
            case "error":
                listProduct = <div><p className="p-2">Елементів не знайдено</p></div>
                break;
            case "idle":
                <div>
                    {
                        listProduct = searchData.map(item => (
                            <div key={item.id}>
                                <div
                                    className={"flex gap-2 p-2 cursor-pointer hover:bg-[#e0e0e0] " + (selectedData.find(i => i.id === item.id) ? "bg-[#c9c9c9]" : "")}
                                    onClick={() => {
                                        setSelectedData((state) => {
                                            if (state.find(i => i.id === item.id)) {
                                                return state.filter(selectedElem => selectedElem.id !== item.id)
                                            } else {
                                                return [...state, {
                                                    id: item.id,
                                                    name: item.sku
                                                }]
                                            }
                                        });
                                    }}
                                >
                                    <p className="w-[150px]">{item.sku}</p>
                                    <p className="w-[250px]">{item.ua_name}</p>
                                    <div className="w-[100px] h-[80px] overflow-hidden">
                                        <img src={item.picture} alt={item.ua_name} className="w-[100px] h-[80px] object-center" />
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        ))
                    }
                </div>
                break;
        }
        return (
            <div
                className="absolute flex items-center justify-center w-[100%] h-[100%] left-0 top-0 mx-auto z-50 "
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        dispatch(setDataVariatonModal(0));
                        setSearchText("")
                        setSearchData([]);
                        setSelectedData([]);
                    }
                }}
            >
                <div className="flex flex-col gap-4 w-[800px] h-[600px] bg-white shadow p-4 rounded-md">
                    <TextField
                        fullWidth
                        label="Пошук за артикулом або назвою товару"
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            searctFetch(e.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => searctFetch(searchText)}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <div className="flex gap-2 flex-wrap max-h-[72px] overflow-auto">
                        {
                            selectedData.map(item => (
                                <Chip
                                    key={item.id}
                                    className="max-w-[180px] truncate"
                                    label={item.name}
                                    onDelete={() => {
                                        setSelectedData(state => {
                                            return state.filter(selectedElem => selectedElem.id !== item.id)
                                        })
                                    }}
                                />
                            ))
                        }
                    </div>
                    <Paper className="h-[390px] overflow-auto relative">
                        <div className="flex gap-2 p-2 bg-[#d1d5db] sticky top-0">
                            <p className="w-[150px]">Артикул</p>
                            <p className="w-[250px]">Назва</p>
                            <p>Картинка</p>
                        </div>
                        {listProduct}
                    </Paper>
                    <div className="mx-auto">
                        <SubmitBtn request={onSubmit} handlerResponse={(data) => {
                            dispatch(setDataVariatonModal(0));
                            setSearchText("")
                            setSearchData([]);
                            setSelectedData([]);
                            const parentElemChildren = allProduct.find(item => item.id === idVariatonModal)?.children

                            dispatch(updateProduct({
                                id: idVariatonModal,
                                changes: {
                                    children: parentElemChildren?.concat(selectedData.map(selectItem => {
                                        return {
                                            ...(allProduct.find(item => item.id === selectItem.id) || allProduct[0]),
                                            parent_id: idVariatonModal
                                        }
                                    }))
                                }
                            }))
                            dispatch(clearSelectProduct());
                            dispatch(clearSelectProductsChilds());
                            dispatch(deleteProducts(selectedData.map(item => item.id)));
                        }} />
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}