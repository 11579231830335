import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

import axiosAuth from "../../../API/auth";

import { userClear } from "../../../slices/user/slice";

import LoadingButton from "@mui/lab/LoadingButton";

import Button from "@mui/material/Button";

import Product from "./product";
import Products from "./products";

import { SuppliersType } from "../../../slices/integrations/easydrop/interfaces";
import { setEasydropSupplierProducts } from "../../../slices/integrations/easydrop/slice";
import { setErrorText } from "../../../slices/modal/slice";

export default function IntegrationEasydrop() {
    const isActiveIntegration = useAppSelector(state => state.easydrop.isActive);

    const easydropSelectedIDs = useAppSelector((state) => state.easydrop.selectedProductsIDs);

    const remainSuppliers = useAppSelector(createSelector([
        (state: RootState) => state.easydrop.selectedProductsIDs,
        (state: RootState) => state.easydrop.suppliers
    ], (select, allData): SuppliersType[] => {
        return allData.filter((item: SuppliersType) => !Object.keys(select).includes(item.id + ""))
    }));

    const selectedData = useAppSelector(state => state.easydrop.selectedProducts);
    const paginationLimit = useAppSelector(state => state.easydrop.suppliersProducts.paginationLimit);
    const searchText = useAppSelector(state => state.easydrop.suppliersProducts.searchText);

    const [loading, setLoading] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [productsId, setProductsId] = useState<number>(remainSuppliers[0]?.id);
    const [openListSuppliers, setOpenListSuppliers] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const idEditProduct = useRef<number>(0);

    function onSubmit(
        id: number,
        setLoader: React.Dispatch<React.SetStateAction<boolean>>,
        offset: number = 0,
        limit: number = paginationLimit,
        search: string = searchText
    ) {
        setLoader(true);
        idEditProduct.current = id;
        axiosAuth()
            .get(`/integration_products/?supplier_id=${id}&integration=easydrop&offset=${offset}&limit=${limit}${search ? ("&search=" + search) : ""}`)
            .then(response => {
                document.body.style.overflow = "hidden";
                setIsShowModal(true)
                dispatch(setEasydropSupplierProducts(response.data))
                setLoader(false);
            })
            .catch(err => {
                if (err === "logaut") {
                    dispatch(userClear());
                }
                console.error(err)
                dispatch(setErrorText(err.response.data.error))
                setLoader(false);
            })
    }

    useEffect(() => {
        setProductsId(remainSuppliers[0]?.id);
    }, [easydropSelectedIDs]);

    useEffect(() => {
        document.body.addEventListener('click', (e) => {
            const elem = e.target as HTMLElement;

            if (!elem.closest(".data-list-open")) {
                setOpenListSuppliers(false);
            }
        })
    }, [])

    if (isActiveIntegration) {
        return (
            <div className="shadow p-2">
                <p>Інтеграція EasyDrop</p>
                <div className="flex flex-col gap-3 mb-3 my-3">
                    {
                        selectedData.map(item => (
                            <Product key={item.id} item={item} onSubmit={onSubmit} />
                        ))
                    }
                </div>
                {
                    remainSuppliers.length === 0
                        ? null
                        :
                        <>
                            <div className="relative data-list-open">
                                <div
                                    className={"absolute w-full z-50 bottom-0 flex flex-col gap-3 items-center mb-3 h-0 overflow-auto bg-white shadow  " + (openListSuppliers ? "h-fit max-h-[300px] py-2 " : "")}
                                >
                                    {
                                        remainSuppliers.map(item => (
                                            <Button key={item.id} variant="outlined" onClick={() => {
                                                setOpenListSuppliers(false)
                                                onSubmit(item.id, setLoading);
                                            }}>
                                                {item.name}
                                            </Button>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="data-list-open">
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="center"
                                        variant="outlined"
                                        onClick={() => {
                                            setOpenListSuppliers(true)
                                        }}
                                    >
                                        Додати постачальника
                                    </LoadingButton>
                                </div>
                            </div>
                        </>
                }
                <Products
                    show={isShowModal}
                    setIsShow={setIsShowModal}
                    idSuppliers={idEditProduct.current || productsId}
                    onPaginaton={onSubmit}
                />
            </div>
        )
    } else {
        return null
    }
}

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
