import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { selectAll, switchSelectAll } from "../../../slices/productsTable/slice";

import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress} from "@mui/material";

import MultipleSelectActions from "../multipleSelectActions";
import ScalePictureModal from "../scalePictureModal";
import Product from "../product";

import { ProductType } from "../../../slices/productsTable/interfaces";
import TableProductsProps from "./interfaces";

export default function TableProducts({ paginationLimit, paginationIndex }: TableProductsProps) {
    const allData = useAppSelector(selectAll);
    const selectProducts = useAppSelector(state => state.productsTable.selectedProducts);

    const sortedOtherCol = useAppSelector(state => state.productsTable.sortedOtherCol);
    const sortedPriceCol = useAppSelector(state => state.productsTable.sortedPriceCol);

    const productsPaginationStatus = useAppSelector(state => state.productsTable.productsPaginationStatus);

    const [scalePicture, setScalePicture] = useState<string>("");

    const dispatch = useAppDispatch();

    function sortedFunc() {
        let sortData: ProductType[] = JSON.parse(JSON.stringify(allData));

        switch (sortedOtherCol) {
            case "name":
                sortData = sortData.sort((a, b) => {
                    let nameA = a.ua_name.toUpperCase();
                    let nameB = b.ua_name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
                break;
            case "sku":
                sortData = sortData.sort((a, b) => {
                    let nameA = a.sku.toUpperCase();
                    let nameB = b.sku.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
                break;
        }

        switch (sortedPriceCol) {
            case "asc":
                sortData = sortData.sort((a, b) => {
                    return a.price - b.price;
                })
                break;
            case "desc":
                sortData = sortData.sort((a, b) => {
                    return b.price - a.price;
                })
                break;
        }

        return sortData;
    }

    return (
        <div className="relative " style={{ height: "calc(100% - 152.5px)" }}>
            {
                productsPaginationStatus === "loading"
                    ?
                    <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full ">
                        <CircularProgress />
                    </div>
                    : null
            }
            {
                productsPaginationStatus === "error"
                    ? <div>Не вдалося загрузити дані</div>
                    :
                    <div className={productsPaginationStatus === "loading" ? "opacity-40 h-full" : "h-full"} >
                        <TableContainer component={Paper} className="h-full">
                            <Table aria-label="collapsible table ">
                                <TableHead className='bg-gray-300 sticky top-[0px] z-20'>
                                    <TableRow className='font-bold'>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectProducts.length === allData.length}
                                                onChange={() => dispatch(switchSelectAll())}
                                            />
                                        </TableCell>
                                        <TableCell>Назва</TableCell>
                                        <TableCell>Артикул</TableCell>
                                        <TableCell>Фото</TableCell>
                                        <TableCell>Ціна</TableCell>
                                        <TableCell>Розмір</TableCell>
                                        <TableCell>Варіація</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {sortedFunc().map((product, i) => (
                                        <Product
                                            key={product.id}
                                            index={paginationLimit * paginationIndex + (i + 1)}
                                            data={product}
                                            setScalePicture={setScalePicture}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <MultipleSelectActions />
                        <ScalePictureModal
                            url={scalePicture}
                            onHidden={setScalePicture}
                        />
                    </div>
            }
        </div>
    )
}