import { useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";

import axiosAuth from "../../../../API/auth";

import { userClear } from "../../../../slices/user/slice";

import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import { setErrorText } from "../../../../slices/modal/slice";

export default function DeleteButton() {
    let { id } = useParams();

    const [isLoadings, setIsLoadings] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function onDelete(id: string | undefined) {
        setIsLoadings(true);

        axiosAuth()
            .delete(`product/?product_ids=${id}`)
            .then(responce => {
                navigate("/")
                setIsLoadings(false);
            })
            .catch(err => {
                if (err === "logaut") {
                    dispatch(userClear());
                    setIsLoadings(false);
                } else {
                    console.log(err)
                    dispatch(setErrorText(err.response.data.error))
                }
            })
    }

    return (
        <LoadingButton
            type="button"
            size="large"
            loading={isLoadings}
            loadingPosition="center"
            variant="contained"
            endIcon={<DeleteIcon fontSize="small" />}
            color="error"
            onClick={(e) => onDelete(id)}
        >
            <span>Видалити</span>
        </LoadingButton>
    )
}