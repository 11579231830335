import { useState } from "react";
import { useAppSelector } from "../../../store/hooks";

import SectionsRename from "../update/rename";
import SectionsAdd from "../add";
import SectionsRemove from "../remove";

import { Divider, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import BranchProps from "./interfaces";

export default function Branch({ parent, node, level }: BranchProps) {
    const currentLanguage = useAppSelector(state => state.sectionsPage.currentLanguage);
    
    const [isOpen, setIsOpen] = useState<boolean>(!node.editable);

    if (node.deleted) {
        return null
    } else {
        return (
            <div key={node.id || node.id_front} className="py-2">
                <div
                    className={"min-w-[290px] flex items-center justify-between p-2 rounded-md bg-gray-50 border-slate-200 border border-solid " + (((node.children.length > 0) && !node.children.every(item => item.deleted)) ? "cursor-pointer" : "")}
                    style={{ marginLeft: `${level * 20}px`}}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setIsOpen((state) => !state)
                        }
                    }}
                >
                    <span className="text-black cursor-text">{node.name[currentLanguage]}</span>
                    <div className="flex gap-2">
                        {
                            node.editable
                            ? <>
                                <SectionsRename parent={parent} element={node} />
                                <Divider orientation="vertical" flexItem />
                                <SectionsAdd element={node} />
                                <Divider orientation="vertical" flexItem />
                                <SectionsRemove element={node} />
                            </>
                            : <SectionsAdd element={node} />
                        }
                        {
                            ((node.children.length > 0) && !node.children.every(item => item.deleted))
                                ?
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton
                                        onClick={() => setIsOpen((state) => !state)}
                                        size="small"
                                    >
                                        {
                                            (isOpen)
                                                ? <KeyboardArrowUpIcon fontSize="medium" />
                                                : <KeyboardArrowDownIcon fontSize="medium" />
                                        }
                                    </IconButton>
                                </>
                                : <div className="w-[43px]"></div>
                        }
                    </div>
                </div>
                {isOpen && node.children && (
                    <div className={` ${isOpen ? " mt-2" : ""}`}>
                        {
                            node.children.map((child) => <Branch key={child.id} parent={node} node={child} level={level + 1} />)
                        }
                    </div>
                )}
            </div>
        );
    }
}