import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from  "@react-oauth/google" ; 

import { Provider } from 'react-redux';
import { store } from './store/store';

import './style/style.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN ? process.env.REACT_APP_GOOGLE_API_TOKEN : ""}>
      <App />
    </GoogleOAuthProvider>
  </Provider>
);