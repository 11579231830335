import axios from 'axios';

export default function axiosAuth() {
    const instance = axios.create({
        baseURL: '/api'
    });

    instance.interceptors.request.use(
        (config) => {
            console.log("========================================================================")
            console.log(config.method + ": " + config.url)
            console.log("Конфіг запиту:")
            console.log(config)
            console.log("JSON дані для відправки на сервер:")
            try {
                console.log(config.data?.get("json"))
            } catch (error) {
                console.log(config.data)
            }
            config.adapter = ["xhr", "http", "https"]
            config.headers.Authorization = window.localStorage.getItem("access_token") || ""
            return config
        });

    instance.interceptors.response.use(
        (response) => {
            console.log("Відповідь сервера:")
            console.log(response)
            return response
        },
        async (error) => {
            console.log("Помилка сервера:")
            console.log(error)
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const result = await refreshToken();
                
                if (typeof result === "string") {
                    window.localStorage.setItem("access_token", result);
                    originalRequest.headers.Authorization = result;

                    return axios(originalRequest);
                } else {
                    if (result === 1004 || result === 1005 || result === 1006 || result === 1007 || result === 1008) {
                        return Promise.reject("logaut");
                    }
                }
            } else {
                console.log(error.response.status);
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

async function refreshToken(): Promise<number | string> {
    let result: string | number = 0;

    await axios({
        method: 'post',
        url: "/api/refresh_jwt/",
        headers: {
            "Authorization": window.localStorage.getItem("refresh_token") || ""
        }
    }).then(response => {
        result = response.data.access_token as string
    }).catch(({response}) => {
        result = response.data.error as number
    })

    return result;
}