import { Link } from "react-router-dom";

import { IconButton, Stack } from "@mui/material";

import Dropzone from "../../components/product/dropzone";
import AvailabilityCheckbox from "../../components/product/availabilityCheckbox";
import CharacteristicsCheckbox from "../../components/product/characteristicsCheckbox";
import SectionsCheckbox from "../../components/product/sectionsCheckbox";
import VariationCheckbox from "../../components/product/variationCheclbox";
import BasicForm from "../../components/product/basicForm";
import IntegrationEasydrop from "../../components/integration/easydrop";
import IntegrationProm from "../../components/integration/prom";
import SubmitButton from "../../components/product/actionButton";
import IntegrationHoroshop from "../../components/integration/horoshop";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useAppSelector } from "../../store/hooks";

export default function ProductPage() {
    const queryParams = useAppSelector(state => state.productsTable.queryParams);

    return (
        <main className="w-full">
            <div className="m-1">
                    <Link to={`/?${queryParams}`}>
                        <IconButton aria-label="delete">
                            <ArrowBackIosNewIcon fontSize="large" />
                        </IconButton>
                    </Link>
                </div>
            <Stack
                spacing={2}
                m={3}
                mt={0}
            >
                <BasicForm />

                <CharacteristicsCheckbox />

                <VariationCheckbox />

                <AvailabilityCheckbox />

                <Dropzone />

                <SectionsCheckbox />

                <IntegrationEasydrop />

                <IntegrationProm />

                <IntegrationHoroshop />

                <SubmitButton />
            </Stack>
        </main>
    );
}