import { Button, Tooltip } from "@mui/material";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import { useCurrentEditor } from "@tiptap/react";

export default function TextFormat() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <>
                <Tooltip title="Жирний (Ctrl+B)" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive('bold') ? "contained" : "outlined"}
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleBold()
                                .run()
                        }
                    >
                        <FormatBoldIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Курсив (Ctrl+I)" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive('italic') ? "contained" : "outlined"}
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleItalic()
                                .run()
                        }
                    >
                        <FormatItalicIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Підкреслювання (Ctrl+U)" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive('underline') ? "contained" : "outlined"}
                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleUnderline()
                                .run()
                        }
                    >
                        <FormatUnderlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Видалити форматування" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant="outlined"
                        onClick={() => editor.chain().focus().unsetAllMarks().run()}
                    >
                        <FormatClearIcon />
                    </Button>
                </Tooltip>
            </>
        )
    } else {
        return null
    }
}