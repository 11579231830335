import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { clearValue, errorSet, hideModal, setSendStatus, setValue } from "../../../slices/modal/slice";

import ModalSendButton from "./button";

export default function Modal() {
    const status = useAppSelector(state => state.modal.status);
    const value = useAppSelector(state => state.modal.modalValue);
    const props = useAppSelector(state => state.modal.modalProps);
    const errorMessage = useAppSelector(state => state.modal.error);

    const dispatch = useAppDispatch();

    function onClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (e.target === e.currentTarget) {
            dispatch(errorSet(""));
            dispatch(clearValue());
            dispatch(hideModal());
            dispatch(setSendStatus("idle"));
        }
    }

    switch (status) {
        case "hide":
            return null
        case "show":
            return (
                <div className="modal h-screen z-[2000000] " onClick={onClose}>
                    <div className="form bg-white">
                        <div className="flex flex-col gap-2">
                            <div className="relative w-full">
                                <input type="text"
                                    id="voice-search"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" placeholder={props.placeholderText}
                                    value={value}
                                    onChange={(e) => dispatch(setValue(e.target.value))} />
                            </div>
                            <p className="text-red-900 text-center">{errorMessage}</p>
                            <ModalSendButton />
                        </div>
                    </div>
                </div>
            )
    }
}