import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { clearValue, errorSet, hideModal, initModal, submitFunks } from "../../../../slices/modal/slice";
import { sectionsPageRename } from "../../../../slices/sectionsPage/slice";

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';

import SectionsRenameProps from "./interfaces";

export default function SectionsRename({ parent, element }: SectionsRenameProps) {
    const dispatch = useAppDispatch();
    const currentLanguage = useAppSelector(state => state.sectionsPage.currentLanguage);

    return (
        <IconButton
            size="small"
            onClick={() => {
                submitFunks.renameSection = (modalValue: string) => {
                    const names = parent.children.map(item => item.name);
                    const isEqual = names.some(name => name[currentLanguage] === modalValue);

                    if (isEqual) {
                        dispatch(errorSet("Такий розділ уже існує"));
                    } else if (!modalValue) {
                        dispatch(errorSet("Введіть назву"));
                    } else {
                        dispatch(errorSet(""));

                        dispatch(sectionsPageRename({
                            id: element.id ? element.id : element.id_front || 0,
                            name: modalValue
                        }));

                        dispatch(clearValue());
                        dispatch(hideModal());
                    }
                }

                dispatch(initModal({
                    btnText: "Змінити",
                    placeholderText: "Нова назва",
                    submitFunkID: "renameSection"
                }))
            }}>
            <EditIcon fontSize="medium" />
        </IconButton>
    )
}