import { useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { switchSelectProduct, switchSelectProductsChilds } from "../../../slices/productsTable/slice";

import { IconButton, TableCell, TableRow, Box, Collapse, Table, TableHead, TableBody, Checkbox, TableContainer } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Availability from "../availability";
import ProductMenu from "../productMenu";
import ProductChildMenu from "../productChildMenu";

import { ProductType } from "../../../slices/productsTable/interfaces";
import ProductProps from "./interaces";

export default function Product({ data, index, setScalePicture }: ProductProps) {
    const selectProducts = useAppSelector(state => state.productsTable.selectedProducts);
    const selectedProductsChilds = useAppSelector(state => state.productsTable.selectedProductsChilds);

    const [open, setOpen] = useState(false);

    const variationUsed = useMemo(() => {
        try {
            const result: Record<string, number> = {}

            result[data.variation.value] = 1;

            data.children?.forEach(child => {
                result[child.variation.value] = (result?.[child.variation.value] || 0) + 1
            })

            return result
        } catch (error) {
            return null
        }
    }, [data])

    const dispatch = useAppDispatch();

    return (
        <>
            <TableRow
                sx={{
                    '& > *': {
                        borderBottom: '1px solid #e0e0e0',
                        backgroundColor: variationUsed ? (variationUsed[data.variation.value] > 1 ? "red" : selectProducts.includes(data.id) ? '#e0e0e0' : 'inherit') : ""
                    },
                }}
            >
                <TableCell >
                    <div className='flex items-center'>
                        <div className='text-[20px]'>{index}</div>
                        <div className='flex items-center'>
                            <Checkbox
                                checked={selectProducts.includes(data.id)}
                                onChange={() => dispatch(switchSelectProduct(data.id))}
                            />
                        </div>
                        <div>
                            <ProductMenu idControlElement={data.id} />
                        </div>
                    </div>
                </TableCell>
                <TableCell className="min-w-[250px]">
                    {data.ua_name}
                </TableCell>
                <TableCell className="min-w-[70px]">{data.sku}</TableCell>
                <TableCell sx={{ p: "2px" }}>
                    <img
                        className='min-w-[70px] h-[50px] cursor-pointer'
                        src={data.picture}
                        alt=""
                        onClick={() => {
                            setScalePicture(data.picture)
                        }}
                    />
                </TableCell>
                <TableCell className="min-w-[70px]">{data.price}</TableCell>
                <TableCell className="min-w-[330px] overflow-auto">
                    <div className='flex gap-4'>
                        {data.availability.length > 1
                            ? [...data.availability].sort((a: any, b: any) => parseInt(a.size) - parseInt(b.size)).map((size: any, i: number) => (<Availability key={i} size={size} />))
                            : data.availability.map((size: any, i: number) => (<Availability key={i} size={size} />))
                        }
                    </div>
                </TableCell>
                <TableCell className="min-w-[70px]">{data.variation?.value || "Відсутня"}</TableCell>
                <TableCell>
                    {
                        (data.children && data.children.length > 0)
                            ?
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            : null
                    }
                    {data.parent_id ? "Варіація" : ""}
                </TableCell>
            </TableRow>
            <TableRow>
                {
                    (data.children && data.children.length > 0)
                        ? <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ marginY: 1, position: "relative" }}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead className='bg-gray-300 sticky top-[0px] z-[10]'>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Назва</TableCell>
                                                    <TableCell>Артикул</TableCell>
                                                    <TableCell>Фото</TableCell>
                                                    <TableCell>Ціна</TableCell>
                                                    <TableCell>Розмір</TableCell>
                                                    <TableCell>Варіація</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody >
                                                {
                                                    data.children ? data.children.map((child: ProductType, i: number) => (
                                                        <TableRow key={child.id} sx={{
                                                            '& > *': {
                                                                borderBottom: '1px solid #e0e0e0',
                                                                backgroundColor: variationUsed ? (variationUsed[data.variation.value] > 1 ? "red" : selectProducts.includes(data.id) ? '#e0e0e0' : 'inherit') : ""
                                                            },
                                                        }}>
                                                            <TableCell>
                                                                <div className='flex items-center'>
                                                                    <div className='text-[20px]'>{i + 1}</div>
                                                                    <div className='flex items-center'>
                                                                        <Checkbox
                                                                            checked={(selectedProductsChilds[data.id]?.includes(child.id)) || false}
                                                                            onChange={() => dispatch(switchSelectProductsChilds({
                                                                                parentId: data.id,
                                                                                childId: child.id
                                                                            })
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <ProductChildMenu
                                                                            parentControlElement={data}
                                                                            childControlElement={child}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="w-[270px]">
                                                                {child.ua_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {child.sku}
                                                            </TableCell>
                                                            <TableCell>
                                                                <img
                                                                    className='w-[50px] h-[50px] cursor-pointer'
                                                                    src={child.picture}
                                                                    alt=""
                                                                    onClick={() => {
                                                                        setScalePicture(child.picture)
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {child.price}
                                                            </TableCell>
                                                            <TableCell className="min-w-[330px] overflow-auto">
                                                                <div className='flex gap-4'>
                                                                    {child.availability.length > 1
                                                                        ? [...child.availability].sort((a: any, b: any) => parseInt(a.size) - parseInt(b.size)).map((size: any, i: number) => (<Availability key={i} size={size} />))
                                                                        : child.availability.map((size: any, i: number) => (<Availability key={i} size={size} />))
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {child.variation?.value || "Відсутня"}
                                                            </TableCell>
                                                        </TableRow>
                                                    )) : null
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Collapse>
                        </TableCell>
                        : null
                }
            </TableRow>
        </>
    );
}