import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { switchSelectedCharacteristic } from "../../../../slices/product/slice";

import {FormControl, RadioGroup, FormControlLabel, Radio} from "@mui/material";

import { CharacteristicType } from "../../../../slices/characteristic/interfaces";

export default function BasicCheckboxListVariation({ char }: { char: CharacteristicType }) {
    const selectedData = useAppSelector(state => state.product.characteristicsSelected);

    const dispatch = useAppDispatch();
    return (
        <div key={char.id} className="flex flex-wrap items-center gap-6 mb-4">
            <p>{char.name["uk"]}</p>
            <FormControl>
                <RadioGroup
                    sx={{display: "flex", flexDirection: "row"}}
                    value={
                        char.values.find(value => selectedData[char.id + "|v"]?.includes(value.id))?.id 
                        || char.values.find(value => selectedData[char.id]?.includes(value.id))?.id 
                        || false}
                    onChange={(e, value) => {
                        dispatch(switchSelectedCharacteristic({
                            characteristic_id: char.id,
                            value_id: parseInt(e.target.value)
                        }))
                    }}
                >
                    {
                        char.values.map(value => (
                            <FormControlLabel key={value.id} value={value.id} control={<Radio />} label={value.name["uk"]} />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </div>
    )
}