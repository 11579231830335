import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import axiosAuth from "../../../API/auth";

import { InputLabel, Select, OutlinedInput, ListSubheader, MenuItem, Checkbox, ListItemText, Switch, FormControl, FormControlLabel } from "@mui/material";

import SubmitBtn from "../../../components/shared/submitBtn";
import IntegrationInfoType from "./interfaces";

export default function EasyDropSettingsPage() {
    const response = useLoaderData() as any as { data: IntegrationInfoType }

    const [data, setData] = useState<IntegrationInfoType>()
    const [selectedSuppliers, setSelectedSuppliers] = useState<Record<string, string[]>>({})

    useEffect(() => {
        setData(response.data)
        const tempData: Record<string, string[]> = {};

        const { defined_suppliers } = response.data.user_integration.settings
        const suppliersIDs = response.data.grouped_suppliers
            .map(suppliers => suppliers.suppliers)
            .reduce((arr, suppliers) => {
                return [...arr, ...suppliers]
            }, [])

        for (const idProductType in defined_suppliers) {
            tempData[idProductType] = defined_suppliers[idProductType].map(idSupplier => {
                return suppliersIDs.filter(supplier => supplier.id === idSupplier)[0].name + "|" + idSupplier
            })
        }

        setSelectedSuppliers(tempData);
    }, [])

    function onSubmit() {
        const sendData: Record<string, number[]> = {}

        for (const idProductType in selectedSuppliers) {
            if (selectedSuppliers[idProductType].length > 0) {
                sendData[idProductType] = selectedSuppliers[idProductType].map(supplier => parseInt(supplier.split("|")[1]))
            }
        }

        const allSendData = {
            settings: {
                defined_suppliers: sendData
            },
            is_active: data?.user_integration.is_active,
            integration_id: data?.user_integration.integration_id
        }

        return axiosAuth()
            .post("/user_integrations/", JSON.stringify(allSendData), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
    }

    return (
        <main className="w-[600px] p-5 mx-auto">
            <p className="text-center text-[22px] ">{data?.user_integration.name}</p>
            <div className="px-2">
                <FormControlLabel control={<Switch
                    checked={data?.user_integration.is_active || false}
                    onChange={() => {
                        setData(state => {
                            if (state) {
                                return {
                                    ...state,
                                    user_integration: {
                                        ...state?.user_integration,
                                        is_active: !state?.user_integration.is_active
                                    }
                                }
                            }
                        })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />} label={(data?.user_integration.is_active ? "Виключити" : "Включити") + " інтеграцію"} />
            </div>
            <div className="flex flex-col gap-2 ">
                {
                    data?.product_types.map(item => (
                        <div
                            key={item.id}
                            className="py-4"
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">{item.name}</InputLabel>
                                <Select
                                    disabled={!data?.user_integration.is_active}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedSuppliers[item.id] || []}
                                    onChange={(e) => {
                                        setSelectedSuppliers(state => {
                                            return {
                                                ...state,
                                                [item.id]: e.target.value
                                            }
                                        });
                                    }}
                                    input={<OutlinedInput label={item.name} />}
                                    renderValue={(selected) => selected.map(item => item.toString().split("|")[0]).join(", ")}
                                >
                                    {
                                        data.grouped_suppliers.map((suppliers) => {
                                            const element: JSX.Element[] = [];

                                            for (const key in suppliers) {
                                                if (key === "niche") {
                                                    element.push(<ListSubheader>{suppliers.niche}</ListSubheader>)
                                                } else {
                                                    element.push(...suppliers.suppliers.map(supplier => (
                                                        <MenuItem key={supplier.id} value={supplier.name + "|" + supplier.id}>
                                                            <Checkbox checked={selectedSuppliers[item.id] ? selectedSuppliers[item.id].includes(supplier.name + "|" + supplier.id) : false} />
                                                            <ListItemText primary={supplier.name} />
                                                        </MenuItem>
                                                    )))
                                                }
                                            }

                                            return element
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    ))
                }
            </div>
            <SubmitBtn
                request={onSubmit}
                handlerResponse={() => {

                }}
            />
        </main>
    )
}