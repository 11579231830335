import { useState } from "react";

import { Button, Divider, Stack, SvgIcon, TextField, Tooltip } from "@mui/material"
import { useCurrentEditor } from "@tiptap/react"
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TbLayoutAlignBottom } from "react-icons/tb";
import { TbLayoutAlignMiddle } from "react-icons/tb";
import { TbLayoutAlignTop } from "react-icons/tb";
import { TbLayoutAlignCenter } from "react-icons/tb";
import { TbLayoutAlignLeft } from "react-icons/tb";
import { TbLayoutAlignRight } from "react-icons/tb";
import { TbLayoutNavbar } from "react-icons/tb";
import { TbLayoutSidebar } from "react-icons/tb";
import { TbLayoutBoard } from "react-icons/tb";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function Table() {
    const { editor } = useCurrentEditor()

    const [statusTableModal, setStatusTableModal] = useState<boolean>(false);
    
    const [tableSettings, setTableSettings] = useState<{
        rows: number,
        cols: number,
    }>({
        rows: 3,
        cols: 2,
    })

    if (editor) {
        return (
            <>
                <div className="flex items-center gap-2 pb-4" >
                    <Tooltip title="Таблиця" placement="top">
                        <Button
                            size="small"
                            sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                            variant={editor.isActive('table') ? "contained" : "outlined"}
                            onClick={() => { setStatusTableModal(true) }}
                        >
                            <TableChartOutlinedIcon />
                        </Button>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    <div className={editor.isActive('table') ? "flex items-center gap-2" : "hidden"} >
                        <Tooltip title="Додати рядок вище" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().addRowBefore().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-row-insert-top"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 18v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" /><path d="M12 9v-4" /><path d="M10 7l4 0" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Додати рядок нижче" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().addRowAfter().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-row-insert-bottom"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M20 6v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1z" /><path d="M12 15l0 4" /><path d="M14 17l-4 0" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Додати стовпчик зліва" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().addColumnBefore().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-column-insert-left"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z" /><path d="M5 12l4 0" /><path d="M7 10l0 4" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Додати стовпчик справа" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().addColumnAfter().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-column-insert-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z" /><path d="M15 12l4 0" /><path d="M17 10l0 4" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Об'єднати комірки" placement="top">
                            <div>
                                <Button
                                    size="small"
                                    sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                    variant="outlined"
                                    onClick={() => { editor.chain().focus().mergeCells().run() }}
                                    disabled={!editor.can().mergeCells()}
                                >
                                    <SvgIcon>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet" fill={editor.can().mergeCells() ? "#1976d2" : "#bdbdbd"} stroke="currentColor">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                <path d="M444 4837 c-78 -30 -142 -97 -163 -172 -9 -30 -11 -567 -9 -2125 3
                                                -1991 4 -2087 21 -2121 23 -47 79 -103 126 -126 34 -17 84 -18 861 -18 923 0
                                                857 -5 933 72 75 74 72 46 75 646 2 483 1 541 -14 573 -24 50 -68 77 -133 82
                                                -70 5 -115 -22 -148 -88 -23 -44 -23 -47 -23 -507 l0 -463 -690 0 -690 0 0
                                                905 0 905 587 0 588 0 -127 -127 c-131 -133 -158 -171 -158 -223 0 -74 60
                                                -148 128 -157 95 -13 95 -12 379 270 145 143 274 278 289 298 36 53 42 107 15
                                                161 -14 30 -109 132 -294 315 -294 293 -294 293 -387 280 -50 -6 -85 -33 -110
                                                -83 -44 -89 -23 -135 133 -291 l122 -123 -583 0 -582 0 0 905 0 905 690 0 690
                                                0 0 -463 c0 -460 0 -463 22 -507 34 -66 79 -93 149 -88 65 5 109 32 133 82 15
                                                32 16 90 14 573 -3 600 0 572 -75 646 -76 77 -12 72 -923 74 -607 1 -823 -1
                                                -846 -10z"/>
                                                <path d="M3004 4837 c-74 -28 -138 -93 -162 -166 -9 -24 -12 -188 -12 -558 0
                                                -464 2 -529 16 -559 24 -50 68 -77 133 -82 66 -4 111 20 145 78 20 34 21 52
                                                24 508 l3 472 689 0 690 0 0 -905 0 -905 -577 0 -578 0 127 128 c69 70 131
                                                137 137 149 17 33 13 104 -8 143 -27 53 -74 80 -135 80 -28 -1 -61 -6 -73 -13
                                                -12 -7 -149 -138 -303 -291 -307 -306 -312 -313 -290 -398 10 -37 49 -81 288
                                                -321 152 -153 289 -285 304 -293 72 -38 177 -3 212 69 18 40 21 108 5 140 -6
                                                12 -70 82 -142 155 l-132 132 583 0 582 0 0 -905 0 -905 -690 0 -689 0 -3 473
                                                c-3 455 -4 473 -24 507 -34 58 -79 82 -145 78 -65 -5 -109 -32 -133 -82 -15
                                                -32 -16 -90 -14 -573 3 -600 0 -572 75 -646 76 -77 10 -72 933 -72 923 0 857
                                                -5 933 72 78 77 71 -16 77 1161 3 578 3 1526 0 2105 -6 1176 1 1083 -77 1160
                                                -76 77 -12 72 -923 74 -607 1 -823 -1 -846 -10z"/>
                                            </g>
                                        </svg>
                                    </SvgIcon>
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title="Розділити комірки" placement="top">
                            <div>
                                <Button
                                    size="small"
                                    sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                    variant="outlined"
                                    onClick={() => { editor.chain().focus().splitCell().run() }}
                                    disabled={!editor.can().splitCell()}
                                >
                                    <SvgIcon>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" fill={editor.can().splitCell() ? "#1976d2" : "#bdbdbd"} stroke="currentColor" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"><path d="M444 4837 c-74 -28 -138 -93 -162 -166 -9 -24 -12 -188 -12 -558 0 -464 2 -529 16 -559 24 -50 68 -77 133 -82 66 -4 111 20 145 78 20 34 21 52 24 508 l3 472 689 0 690 0 0 -905 0 -905 -577 0 -577 0 126 128 c70 70 132 140 137 155 28 73 4 152 -62 201 -42 31 -123 28 -174 -7 -21 -14 -156 -145 -300 -289 -288 -291 -296 -301 -273 -390 10 -37 49 -81 283 -316 150 -151 289
                                                    -282 309 -293 48 -24 91 -24 142 1 52 26 77 61 84 116 8 74 -3 92 -139 229
                                                    -71 72 -129 134 -129 138 0 4 259 7 575 7 l575 0 0 -905 0 -905 -690 0 -689 0
                                                    -3 473 c-3 455 -4 473 -24 507 -34 58 -79 82 -145 78 -65 -5 -109 -32 -133
                                                    -82 -15 -32 -16 -90 -14 -573 3 -600 0 -572 75 -646 76 -77 10 -72 933 -72
                                                    923 0 857 -5 933 72 78 77 71 -16 77 1161 3 578 3 1526 0 2105 -6 1176 1 1083
                                                    -77 1160 -76 77 -12 72 -923 74 -607 1 -823 -1 -846 -10z"/>
                                                <path d="M3004 4837 c-74 -28 -138 -93 -163 -166 -16 -49 -16 -4173 0 -4222
                                                    27 -78 97 -146 174 -168 29 -9 254 -11 845 -9 899 3 837 -2 913 75 75 74 72
                                                    46 75 646 2 483 1 541 -14 573 -24 50 -68 77 -133 82 -70 5 -115 -22 -148 -88
                                                    -23 -44 -23 -47 -23 -507 l0 -463 -690 0 -690 0 0 905 0 905 577 0 577 0 -126
                                                    -127 c-69 -71 -131 -139 -138 -152 -20 -38 -15 -115 9 -155 24 -38 91 -76 134
                                                    -76 63 0 102 32 365 293 145 144 272 277 284 295 21 35 27 117 9 149 -17 32
                                                    -571 578 -599 590 -63 28 -155 -2 -193 -63 -24 -40 -29 -116 -9 -155 7 -13 69
                                                    -81 138 -151 l127 -128 -578 0 -577 0 0 905 0 905 690 0 690 0 0 -463 c0 -460
                                                    0 -463 23 -507 33 -66 78 -93 148 -88 65 5 109 32 133 82 15 32 16 90 14 573
                                                    -3 600 0 572 -75 646 -76 77 -12 72 -923 74 -607 1 -823 -1 -846 -10z"/>
                                            </g>
                                        </svg>
                                    </SvgIcon>
                                </Button>
                            </div>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Видалити стовпчик" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().deleteColumn().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-column-remove"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z" /><path d="M16 10l4 4" /><path d="M16 14l4 -4" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Видалити рядок" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { editor.chain().focus().deleteRow().run() }}
                            >
                                <SvgIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-row-remove"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M20 6v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1z" /><path d="M10 16l4 4" /><path d="M10 20l4 -4" /></svg>
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Зверху" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ verticalAlign: 'top' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellVerticalAlign("top") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignTop />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="По центру" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ verticalAlign: 'middle' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellVerticalAlign("middle") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignMiddle />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Знизу" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ verticalAlign: 'bottom' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellVerticalAlign("bottom") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignBottom />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Зліва" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ textAlign: 'left' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellHorizontalAlign("left") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignLeft />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="По центру" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ textAlign: 'center' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellHorizontalAlign("center") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignCenter />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Справа" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={editor.isActive({ textAlign: 'right' }) ? 'contained' : 'outlined'}
                                onClick={() => { editor.commands.setCellHorizontalAlign("right") }}
                            >
                                <SvgIcon>
                                    <TbLayoutAlignRight />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Вкл/викл: заголовки колонок" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={'outlined'}
                                onClick={() => { editor.chain().focus().toggleHeaderRow().run() }}
                            >
                                <SvgIcon>
                                    <TbLayoutNavbar />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Вкл/викл: заголовки рядків" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={'outlined'}
                                onClick={() => { editor.chain().focus().toggleHeaderColumn().run() }}
                            >
                                <SvgIcon>
                                    <TbLayoutSidebar />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Вкл/викл: заголовок комірки(-ок)" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={'outlined'}
                                onClick={() => { editor.chain().focus().toggleHeaderCell().run() }}
                            >
                                <SvgIcon>
                                    <TbLayoutBoard />
                                </SvgIcon>
                            </Button>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Видалити таблицю" placement="top">
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant={'outlined'}
                                onClick={() => { editor.chain().focus().deleteTable().run() }}
                            >
                                <DeleteOutlineIcon />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div
                    className={statusTableModal ? "modal h-screen z-[2000000]" : "hidden"}
                    onClick={(event) => {
                        if (event.target === event.currentTarget) {
                            setStatusTableModal(false)
                        }
                    }}
                >
                    <div style={{ padding: "15px" }} className="form bg-white w-[300px]">
                        <div className="flex items-center justify-between mb-3">
                            <p className="text-[18px]">Створити таблицю</p>
                            <Button
                                size="small"
                                sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                                variant="outlined"
                                onClick={() => { setStatusTableModal(false) }}
                            >
                                <CloseOutlinedIcon />
                            </Button>
                        </div>
                        <Stack spacing={1} >
                            <TextField
                                id="outlined-basic"
                                label={<b>Рядки*</b>}
                                variant="outlined"
                                size="small"
                                type="number"
                                value={tableSettings.rows}
                                onChange={(event) => {
                                    setTableSettings(state => ({
                                        ...state,
                                        rows: parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 1
                                    }))
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label={<b>Колонки*</b>}
                                variant="outlined"
                                size="small"
                                type="number"
                                value={tableSettings.cols}
                                onChange={(event) => {
                                    setTableSettings(state => ({
                                        ...state,
                                        cols: parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 1
                                    }))
                                }}
                            />
                        </Stack>
                        <Button
                            size="small"
                            sx={{ display: "block", mx: "auto", mt: "10px" }}
                            variant="outlined"
                            onClick={() => {
                                editor.chain().focus().insertTable({ rows: tableSettings.rows, cols: tableSettings.cols, withHeaderRow: true }).run()
                                setStatusTableModal(false)
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}