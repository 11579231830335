import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import axios from "axios";
import * as yup from 'yup';

import { TextField, Box, Container, CssBaseline, Typography } from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";

export default function NewPasswordPage() {
    const tokenNewPassword = useParams()
    
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);

    const passwordRef = useRef<string>("")

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: yup.object({
            password: yup.string().required("Це поле обов'язкове"),
            confirmPassword: yup.string().test(
                'is-equal',
                'Паролі не рівні',
                (value, context) => value === passwordRef.current,
            ).required("Це поле обов'язкове")
        }),
        onSubmit: (values) => {
            setLoading(true);

            const formData = new FormData();

            formData.append("password", values.password);

            axios.post(`/api/new_password/${tokenNewPassword.token}`, formData)
            .then(response => {
                setLoading(false);
                setSuccess(true);

                setTimeout(() => {
                    setSuccess(false);
                }, 2000)
            }).catch(response => {
                setLoading(false);
                console.error(response);
            })
        }
    })

    useEffect(() => {
        if ((Object.values(formik.errors).length === 0) && Object.values(formik.values).every(item => item)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formik.values, formik.errors]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5" sx={{textAlign: "center", marginBottom: 2}}>
                    Введіть новий пароль.
                </Typography>
                <TextField
                    sx={{marginBottom: "10px"}}
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formik.values.password}
                    onChange={(e) => {
                        formik.handleChange(e);
                        passwordRef.current = e.target.value;
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Повторіть пароль"
                    type="password"
                    id="confirmPassword"
                    autoComplete="confirm-password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
                <LoadingButton
                    fullWidth
                    type="submit"
                    size="large"
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    disabled={disabled}
                    sx={{ mt: 3, mb: 2 }}
                >
                    <span>{success ? "Пароль збережено!" : "Зберегти пароль"}</span>
                </LoadingButton>
            </Box>
        </Container>
    )
}