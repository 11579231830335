import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks"


import { Autocomplete, Chip, FormControlLabel, Stack, Switch, TextField } from "@mui/material";

import PromCategory from "./promCategory";
import { updatePromDetails } from "../../../slices/integrations/prom/slice";

export default function IntegrationProm() {
    const promIsActive = useAppSelector(state => state.prom.isActive)
    const promDetails = useAppSelector(state => state.prom.data);

    const dispatch = useAppDispatch();

    const isRequired = useMemo(() => {
        return Object.values(promDetails || {}).some(item => {
            if (typeof item === "object") {
                return item?.id
            } else {
                return item
            }
        })
    }, [promDetails])

    if (promIsActive) {
        return (
            <div className="shadow p-2">
                <p className="mb-3">Інтеграція Prom</p>
                <Stack spacing={2}>
                    <TextField
                        sx={{ marginBottom: "10px" }}
                        fullWidth
                        label={"Ярлик"}
                        type="text"
                        value={promDetails.label || ""}
                        onChange={(e) => {
                            dispatch(updatePromDetails({
                                label: "label",
                                value: e.target.value.length >= 15 ? e.target.value.substring(0, 15) : e.target.value
                            }));
                        }}
                    />
                    <div className="flex gap-2">
                        <Autocomplete
                            fullWidth
                            multiple
                            value={promDetails.search_uk.split(",")[0] ? promDetails.search_uk.split(",") : []}
                            onChange={(event, newValue) => {
                                dispatch(updatePromDetails({
                                    label: "search_uk",
                                    value: newValue.join(",")
                                }));
                            }}
                            options={[]}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Пошукові запити UK"
                                />
                            )}
                        />
                        <Autocomplete
                            fullWidth
                            multiple
                            value={promDetails.search_ru.split(",")[0] ? promDetails.search_ru.split(",") : []}
                            onChange={(event, newValue) => {
                                dispatch(updatePromDetails({
                                    label: "search_ru",
                                    value: newValue.join(",")
                                }));
                            }}
                            options={[]}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Пошукові запити RU"
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-2">
                            <TextField
                                sx={{ marginBottom: "10px" }}
                                fullWidth
                                label={"Початок знижки"}
                                type="date"
                                value={promDetails.discount_start || " "}
                                onChange={(e) => {
                                    dispatch(updatePromDetails({
                                        label: "discount_start",
                                        value: e.target.value
                                    }));
                                }}
                            />
                            <TextField
                                sx={{ marginBottom: "10px" }}
                                fullWidth
                                label={"Кінець знижки"}
                                type="date"
                                value={promDetails.discount_finish || " "}
                                onChange={(e) => {
                                    dispatch(updatePromDetails({
                                        label: "discount_finish",
                                        value: e.target.value
                                    }));
                                }}
                            />
                        </div>
                        <div className="ml-2">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={promDetails.auto_discount}
                                        onChange={() => {
                                            dispatch(updatePromDetails({
                                                label: "auto_discount",
                                                value: !promDetails.auto_discount
                                            }));
                                        }}
                                    />
                                }
                                label="Автопродовження"
                            />
                            <TextField
                                disabled={!promDetails.auto_discount}
                                size="small"
                                sx={{ marginBottom: "10px" }}
                                label={"Період"}
                                type="number"
                                value={(promDetails.discount_period + "") || "0"}
                                onChange={(e) => {
                                    dispatch(updatePromDetails({
                                        label: "discount_period",
                                        value: e.target.value ? parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0 : 0
                                    }));
                                }}
                            />
                        </div>
                    </div>
                    <PromCategory required={isRequired}/>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}