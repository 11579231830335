import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLoaderData } from "react-router-dom";

import { setModule } from "../../../slices/link/slice";

import { Radio, FormControlLabel, RadioGroup } from "@mui/material";

import { ModulesType } from "../../../pages/link/interfaces";

export default function Modules() {
    const { data: { modules } } = useLoaderData() as any as { data: { modules: ModulesType[] } }

    const selectedModule = useAppSelector(state => state.link.marketplace_module_id);

    const dispatch = useAppDispatch();

    function onChange(newValue: number) {
        dispatch(setModule(newValue))
    }

    return (
        <div>
            <p className="font-bold">Модулі</p>
            <RadioGroup
                row
                value={selectedModule}
                onChange={(e) => onChange(parseInt(e.target.value))}
            >
                {
                    modules.map(item => (
                        <FormControlLabel
                            key={item.id}
                            value={item.id}
                            control={<Radio />}
                            label={item.name}
                        />
                    ))
                }
            </RadioGroup>
        </div>
    )
}