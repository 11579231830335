import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import IntegrationsType from "./interfaces";

export default function Integration({ data }: { data: IntegrationsType }) {
    return (
        <div className='p-5 flex flex-col items-center gap-3 justify-between border-2 border-sky-600 border-solid '>
            <div className="flex flex-col items-center gap-3 justify-center">
                <p className='text-[18px]'>{data.name}</p>
                <div className='w-[100px]'>
                    <img
                        src={data.image_url}
                        alt={data.system_name}
                        className='w-full'
                    />
                </div>
            </div>
            <Link to={data.system_name}>
                <Button variant="contained">
                    Налаштувати
                </Button>
            </Link>
        </div>
    )
}