import { createSlice } from "@reduxjs/toolkit"
import LinkSlice from "./interfaces";
import { DeleteFormulaPriceType, SelectedCategoriesType, SetAllType, SetFileFormatType, SetFixedPriceType, SetModuleType, SetNameType, UpdateFormulaPriceType } from "./actionsInterfaces";

const initialState: LinkSlice = {
    id: undefined,
    name: "",
    marketplace_id: 0,
    marketplace_module_id: 0,
    categories: [],
    use_retail_price: false,
    use_fixed_markup: false,
    use_max_drop_price: false,
    fixed_markup: 0,
    use_formula_markup: false,
    formula_markup: [
        {
            from_price: 0,
            to_price: 0,
            mark_up: 0
        },
        {
            from_price: 0,
            mark_up: 0
        }
    ],
    file_format: ""
}

const linkSlice = createSlice({
    initialState,
    name: "link",
    reducers: {
        setAll: (state, actions: SetAllType) => {
            state.id = actions.payload.id;
            state.name = actions.payload.name;
            state.marketplace_id = actions.payload.marketplace_id;
            state.marketplace_module_id = actions.payload.marketplace_module_id;
            state.categories = actions.payload.categories;
            state.use_retail_price = actions.payload.use_retail_price;
            state.use_fixed_markup = actions.payload.use_fixed_markup;
            state.use_max_drop_price = actions.payload.use_max_drop_price;
            state.fixed_markup = actions.payload.fixed_markup;
            state.formula_markup = actions.payload.formula_markup;
            state.use_formula_markup = actions.payload.use_formula_markup;
            state.file_format = actions.payload.file_format;

            if ((state.use_retail_price && state.use_fixed_markup) || (state.use_retail_price && state.use_formula_markup) || (state.use_fixed_markup && state.use_formula_markup)) {
                state.use_retail_price = false;
                state.use_fixed_markup = false;
                state.use_formula_markup = false;
            }
        },

        setName: (state, actions: SetNameType) => {
            state.name = actions.payload;
        },
        setModule: (state, actions: SetModuleType) => {
            state.marketplace_module_id = actions.payload;
        },
        setFileFormat: (state, actions: SetFileFormatType) => {
            state.file_format = actions.payload;
        },
        selectedCategories: (state, actions: SelectedCategoriesType) => {
            if (state.categories.includes(actions.payload)) {
                state.categories = state.categories.filter(id => id !== actions.payload)
            } else {
                state.categories = [...state.categories, actions.payload]
            }
        },

        switchUseRetailPrice: (state) => {
            state.use_retail_price = !state.use_retail_price;
            if (state.use_retail_price) {
                state.use_formula_markup = false;
                state.use_fixed_markup = false;
            }
        },
        switchUseFixedMarkup: (state) => {
            state.use_fixed_markup = !state.use_fixed_markup;
            if (state.use_fixed_markup) {
                state.use_retail_price = false;
                state.use_formula_markup = false;
            }
        },
        switchUseMaxDropPrice: (state) => {
            state.use_max_drop_price = !state.use_max_drop_price;
        },
        switchUseFormulaMarkup: (state) => {
            state.use_formula_markup = !state.use_formula_markup;
            if (state.use_formula_markup) {
                state.use_retail_price = false;
                state.use_fixed_markup = false;
            }
        },
        setFixedPrice: (state, actions: SetFixedPriceType) => {
            state.fixed_markup = actions.payload;
        },

        updateFormulaPrice: (state, actions: UpdateFormulaPriceType) => {
            state.formula_markup = state.formula_markup.map((item, index) => {
                if (index === actions.payload.index) {
                    return {
                        ...item,
                        ...actions.payload.change
                    }
                } else if ((index === (actions.payload.index + 1)) && ("to_price" in actions.payload.change)) {
                    return {
                        ...item,
                        from_price: actions.payload.change.to_price
                    }
                } else {
                    return item
                }
            })
        },
        addFormulaPrice: (state) => {
            const lastFormula = state.formula_markup[state.formula_markup.length - 1];

            state.formula_markup = [...state.formula_markup.filter((_, index) => index !== (state.formula_markup.length - 1)), {
                from_price: state.formula_markup[state.formula_markup.length - 2]?.to_price || 0,
                to_price: 0,
                mark_up: 0
            }, lastFormula];
        },
        deleteFormulaPrice: (state, actions: DeleteFormulaPriceType) => {
            state.formula_markup = state.formula_markup.filter((_, i) => {
                return i !== actions.payload
            }).map((item, i) => {
                if (i === actions.payload) {
                    return {
                        ...item,
                        from_price: state.formula_markup[i - 1]?.to_price || 0
                    }
                } else {
                    return item
                }
            });
        },

        clearData: (state) => {
            state.id = undefined;
            state.name = "";
            state.marketplace_id = 0;
            state.marketplace_module_id = 0;
            state.categories = [];
            state.use_retail_price = false;
            state.use_fixed_markup = false;
            state.use_max_drop_price = false;
            state.fixed_markup = 0;
            state.use_formula_markup = false;
            state.formula_markup = [
                {
                    from_price: 0,
                    to_price: 0,
                    mark_up: 0
                },
                {
                    from_price: 0,
                    mark_up: 0
                }
            ];
            state.file_format = ""
        }
    }
})

const { reducer, actions } = linkSlice;

export default reducer;

export const {
    setAll,

    setName,
    setModule,
    setFileFormat,
    selectedCategories,

    switchUseRetailPrice,
    switchUseFixedMarkup,
    switchUseMaxDropPrice,
    switchUseFormulaMarkup,
    setFixedPrice,

    updateFormulaPrice,
    addFormulaPrice,
    deleteFormulaPrice,

    clearData
} = actions