import { useCurrentEditor } from "@tiptap/react";

import { Button, Tooltip } from "@mui/material";
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';

export default function TextAlign() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <>
                <Tooltip title="По лівому краю" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive({ textAlign: 'left' }) ? 'contained' : 'outlined'}
                        onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    >
                        <AlignHorizontalLeftIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="По центру" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive({ textAlign: 'center' }) ? 'contained' : 'outlined'}
                        onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    >
                        <AlignHorizontalCenterIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="По правому краю" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant={editor.isActive({ textAlign: 'right' }) ? 'contained' : 'outlined'}
                        onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    >
                        <AlignHorizontalRightIcon />
                    </Button>
                </Tooltip>
            </>
        )
    } else {
        return null
    }
}