import { useCurrentEditor } from "@tiptap/react";

import { Button, Tooltip } from "@mui/material";
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';

export default function TextIndent() {
    const { editor } = useCurrentEditor()

    if (editor) {
        return (
            <>
                <Tooltip title="Збільшити відступ (Tab)" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant="outlined"
                        onClick={() => editor.commands.indent()}
                    >
                        <FormatIndentIncreaseIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Зменшити відступ (Shift+Tab)" placement="top">
                    <Button
                        size="small"
                        sx={{ minWidth: "30px", width: "30px", height: "30px" }}
                        variant="outlined"
                        onClick={() => editor.commands.outdent()}
                    >
                        <FormatIndentDecreaseIcon />
                    </Button>
                </Tooltip>
            </>
        )
    } else {
        return null
    }
}