import { useEffect, useState } from "react"
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";

import { Tab, Box, Button, Tooltip } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';

import LinkRow from "../../components/unloading/linkRow";

import MarketplaceType from "./interfaces";

export default function UnloadingPage() {
    const URLMarketplace = useParams();
    const response = useLoaderData() as any as { data: MarketplaceType[] };
    const [data, setData] = useState<MarketplaceType[]>([]);
    const [currentTab, setCurrentTab] = useState<string>("Prom");

    const navigate = useNavigate();

    useEffect(() => {
        setData(response.data)
        setCurrentTab(URLMarketplace.marketplace || (response.data[0].id + ""))
    }, [URLMarketplace.marketplace])

    function onDelete(linkId: number, marketplaceId: number) {
        setData(state => {
            return state.map(marketplace => {
                if (marketplace.id === marketplaceId) {
                    return {
                        ...marketplace,
                        links: marketplace.links.filter(link => link.id !== linkId)
                    }
                } else {
                    return marketplace
                }
            })
        })
    }

    return (
        <main className="w-full">
            <TabContext value={currentTab} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        sx={{ justifyContent: "center" }}
                        onChange={(e, value) => {
                            window.localStorage.setItem("marketplace", value)
                            navigate("/unloading/" + value)
                        }}
                        variant="scrollable"
                    >
                        {
                            data.map((item, index) => {
                                return <Tab key={index} label={item.name} value={item.id + ""} />
                            })
                        }
                    </TabList>
                </Box>
                {
                    data.map((item, index) => {
                        return <TabPanel key={index} value={item.id + ""}>
                            <div className="flex flex-col gap-2 mb-2">
                                {
                                    item.links.map(link => {
                                        return <LinkRow
                                            key={link.id}
                                            dataLink={link}
                                            marketplaceId={item.id}
                                            onDelete={onDelete}
                                        />
                                    })
                                }
                            </div>
                            <Tooltip title="Створити посилання" arrow>
                                <Link
                                    to={"/link/add/" + item.id}
                                    className="h-[45px] w-[45px] rounded-full overflow-hidden flex items-center justify-center shrink-0 mx-auto"
                                >
                                    <Button
                                        variant="contained"
                                        sx={{ minWidth: "45px", width: "45px", height: "100%" }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Link>
                            </Tooltip>
                        </TabPanel>
                    })
                }
            </TabContext>
        </main>
    )
}
