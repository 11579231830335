import { useEffect } from "react"
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";

import { clearData } from "../../slices/link/slice";

import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import SectionsCheckbox from "../../components/link/sectionsCheckbox";
import FileFormats from "../../components/link/fileFormats";
import Markup from "../../components/link/markup";
import SubmitButton from "../../components/link/submitButton";
import NameField from "../../components/link/nameField";
import Modules from "../../components/link/modules";

export default function LinkPage() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearData())
        }
    }, [])

    return (
        <main className="w-full relative">
            <div className="top-[35px] left-[70px] fixed max-[770px]:left-[20px]">
                <Link to={"/unloading/" + window.localStorage.getItem("marketplace")}>
                    <IconButton aria-label="delete">
                        <ArrowBackIosNewIcon fontSize="large" />
                    </IconButton>
                </Link>
            </div>
            <div className="max-w-[1000px] mx-auto p-5 flex flex-col gap-2 px-[60px]">
                <NameField />
                <Modules />
                <SectionsCheckbox />
                <FileFormats  />
                <Markup />
                <SubmitButton />
            </div>
        </main>
    )
}