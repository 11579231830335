import * as yup from 'yup';

import StandartField from "../standartField";

export default function BasicForm() {
    return (
        <div>
            <StandartField label="Артикул*" name="sku" validate={yup.string().required("Це поле обов'язкове")}/>
            <StandartField label="Назва товару UA*" name="ua_name" validate={yup.string().required("Це поле обов'язкове")}/>
            <StandartField label="Назва товару RU" name="ru_name" />

            <div className="flex gap-3">
                <StandartField label="Ціна*" name="price" type="number" validate={yup.string().required("Це поле обов'язкове")}/>
                <StandartField label="Стара ціна" name="old_price" type="number" />
                <StandartField label="Роздрібна ціна" name="retail_price" type="number" />
            </div>
            <StandartField label="Опис товару UA*" name="ua_description" type="textarea" validate={yup.string().required("Це поле обов'язкове")}/>
            <StandartField label="Опис товару RU" name="ru_description" type="textarea" />
        </div>
    )
}