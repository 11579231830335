import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { Button, ButtonGroup, Pagination } from "@mui/material";

import PaginationNavigationProps from "./interfaces";
import { setQueryParams } from "../../../slices/productsTable/slice";

export default function PaginationNavigation({ fetching, childrenRender }: PaginationNavigationProps) {
    const totalItemsData = useAppSelector(state => state.productsTable.total_items);

    const dispatch = useAppDispatch();

    const queryParams = new URLSearchParams(useAppSelector(state => state.productsTable.queryParams));

    const paginationLimit = queryParams.get("limit") || "20";
    const paginationIndex = queryParams.get("offset") || "0";

    function PaginationBlock() {
        return (
            <div className='flex justify-end items-center p-5 max-[482px]:flex-col max-[482px]:gap-2'>
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                    <Button
                        variant={paginationLimit === "10" ? "contained" : "outlined"}
                        onClick={() => {
                            queryParams.set("limit", "10");
                            queryParams.set("offset", "0");
                            dispatch(setQueryParams(queryParams.toString()));

                            fetching(queryParams.toString());
                        }}
                    >10</Button>
                    <Button
                        variant={paginationLimit === "20" ? "contained" : "outlined"}
                        onClick={() => {
                            queryParams.set("limit", "20");
                            queryParams.set("offset", "0");
                            dispatch(setQueryParams(queryParams.toString()));

                            fetching(queryParams.toString());
                        }}
                    >20</Button>
                    <Button
                        variant={paginationLimit === "50" ? "contained" : "outlined"}
                        onClick={() => {
                            queryParams.set("limit", "50");
                            queryParams.set("offset", "0");
                            dispatch(setQueryParams(queryParams.toString()));

                            fetching(queryParams.toString());
                        }}
                    >50</Button>
                    <Button
                        variant={paginationLimit === "100" ? "contained" : "outlined"}
                        onClick={() => {
                            queryParams.set("limit", "100");
                            queryParams.set("offset", "0");
                            dispatch(setQueryParams(queryParams.toString()));

                            fetching(queryParams.toString());
                        }}
                    >100</Button>
                </ButtonGroup>
                <Pagination
                    showFirstButton
                    showLastButton
                    count={Math.ceil(totalItemsData / parseInt(paginationLimit)) || 1}
                    page={parseInt(paginationIndex) + 1}
                    onChange={(e, value) => {
                        queryParams.set("offset", ((value - 1) + ""));
                        dispatch(setQueryParams(queryParams.toString()));

                        fetching(queryParams.toString());
                    }}
                />
            </div>
        )
    }

    return (
        <>
            {childrenRender(parseInt(paginationLimit), parseInt(paginationIndex))}
            <PaginationBlock />
        </>
    )
}