import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { FaBoxOpen } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";

import { userClear } from "../../slices/user/slice";

import { Button, Drawer, IconButton, Divider, Tooltip } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import TableChartIcon from '@mui/icons-material/TableChart';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import TocIcon from '@mui/icons-material/Toc';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from "../shared/accordion";

export default function SideBar() {
    const [open, setOpen] = useState(false);
    const userName = useAppSelector(state => state.user.data.info.name)

    const queryParams = useAppSelector(state => state.productsTable.queryParams);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function toggleDrawer(newOpen: boolean) {
        setOpen(newOpen);
    };

    return (
        <div className="flex flex-col justify-start p-2 sticky top-0 self-start max-[770px]:z-40 max-[770px]:fixed max-[770px]:bottom-0 max-[770px]:top-auto ">
            <div className="max-[770px]:bg-white max-w-[55px]">
                <div className="items-center justify-center max-[770px]:flex hidden">
                    <IconButton aria-label="open" onClick={() => toggleDrawer(true)} sx={{ color: "black" }}>
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </div>
                <div className="max-[770px]:hidden">
                    <Tooltip title={userName} placement="right">
                        <div className="flex items-center justify-center">
                            <AccountCircleIcon fontSize="large" />
                        </div>
                    </Tooltip>
                    <div className="p-[8px] flex items-center justify-center">
                        <FaBoxOpen size="30px" />
                    </div>
                    <Divider />
                    <Tooltip title="Товари" placement="right">
                        <Link
                            to={`/?${queryParams}`}
                            className="flex items-center justify-center rounded-lg group"
                        >
                            <IconButton size="medium" >
                                <TableChartIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Характеристики" placement="right">
                        <Link to={"/characteristics"} className="flex items-center justify-center rounded-lg group">
                            <IconButton size="medium">
                                <AppRegistrationIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Категорії" placement="right">
                        <Link to={"/sections"} className="flex items-center justify-center rounded-lg group">
                            <IconButton size="medium" >
                                <TocIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Вигрузки" placement="right">
                        <Link to={"/unloading"} className="flex items-center justify-center rounded-lg group">
                            <IconButton size="medium" >
                                <DownloadIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Налаштування" placement="right">
                        <Link to={"/settings"} className="flex items-center justify-center rounded-lg group">
                            <IconButton sx={{ color: "black" }}>
                                <IoSettingsSharp size="30px" />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Вийти" placement="right">
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ minWidth: "50px", width: "50px", height: "100%" }}
                            onClick={() => {
                                toggleDrawer(false)
                                Promise.all([
                                    fetch("/api/logout/", {
                                        method: "DELETE",
                                        headers: {
                                            "Authorization": window.localStorage.getItem("access_token") || ""
                                        }
                                    }),
                                    fetch("/api/logout/", {
                                        method: "DELETE",
                                        headers: {
                                            "Authorization": window.localStorage.getItem("refresh_token") || ""
                                        }
                                    })
                                ]).then((data) => {
                                    window.localStorage.clear();
                                    dispatch(userClear());
                                    navigate("/");
                                })
                            }}
                        >
                            <LogoutOutlinedIcon />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <Drawer open={open} onClose={() => toggleDrawer(false)}>
                <div className="space-y-2 font-medium w-[250px] p-5">
                    <p>{userName}</p>
                    <Accordion title="Товари" icon={<FaBoxOpen />}>
                        <Link onClick={() => toggleDrawer(false)} to={"/"} className="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                            <span className="flex-1 ms-3 whitespace-nowrap">Таблиця товарів</span>
                        </Link>
                        <Link onClick={() => toggleDrawer(false)} to={"/characteristics"} className="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100 ">
                            <span className="flex-1 ms-3 whitespace-nowrap">Характеристики</span>
                        </Link>
                        <Link onClick={() => toggleDrawer(false)} to={"/sections"} className="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                            <span className="flex-1 ms-3 whitespace-nowrap">Розділи сайту</span>
                        </Link>
                        <Link onClick={() => toggleDrawer(false)} to={"/unloading"} className="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                            <span className="flex-1 ms-3 whitespace-nowrap">Вигрузка товарів</span>
                        </Link>
                    </Accordion>

                    <Link onClick={() => toggleDrawer(false)} to="/settings" className="flex items-center gap-4 text-gray-500">
                        <IoSettingsSharp />
                        Налаштування
                    </Link>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            toggleDrawer(false)
                            Promise.all([
                                fetch("/api/logout/", {
                                    method: "DELETE",
                                    headers: {
                                        "Authorization": window.localStorage.getItem("access_token") || ""
                                    }
                                }),
                                fetch("/api/logout/", {
                                    method: "DELETE",
                                    headers: {
                                        "Authorization": window.localStorage.getItem("refresh_token") || ""
                                    }
                                })
                            ]).then((data) => {
                                window.localStorage.clear();
                                dispatch(userClear());
                                navigate("/");
                                window.location.reload()
                            })
                        }}
                    >
                        Вийти
                    </Button>
                </div>
            </Drawer>
        </div>
    )
}