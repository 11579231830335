import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import axiosAuth from "../../../API/auth";
import { AxiosResponse } from "axios";

import { userClear } from "../../../slices/user/slice";

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from "@mui/material";
import { setErrorText } from "../../../slices/modal/slice";

export default function SubmitButton() {
    const { id } = useParams();
    let mode = useLocation().pathname.split("/")[2];

    const name = useAppSelector(state => state.link.name);
    const selectedModule = useAppSelector(state => state.link.marketplace_module_id);
    const selectCategories = useAppSelector(state => state.link.categories);
    const selectFormat = useAppSelector(state => state.link.file_format);

    const stateUseRetailPrice = useAppSelector(state => state.link.use_retail_price);
    const stateUseFormulaMarkup = useAppSelector(state => state.link.use_formula_markup);
    const stateUseFixedMarkup = useAppSelector(state => state.link.use_fixed_markup);
    const stateFixedPrice = useAppSelector(state => state.link.fixed_markup);
    const stateUseMaxDropPrice = useAppSelector(state => state.link.use_max_drop_price);
    const stateFormulaPrice = useAppSelector(state => state.link.formula_markup);

    const [statusSubmit, setStatusSubmit] = useState<["Зберегти", "primary"] | ["Збережено!", "success"] | ["Не вдалося зберегти", "error"]>(["Зберегти", "primary"]);
    const [loading, setLoading] = useState<boolean>(false);

    const [helperTextDisabled, setHelperTextDisabled] = useState<string[]>([])

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setHelperTextDisabled([])

        if (!name) setHelperTextDisabled(state => [...state, "Введіть назву для посилання"]);
        if (!selectedModule) setHelperTextDisabled(state => [...state, "Виберіть модуль"]);
        if (selectCategories.length === 0) setHelperTextDisabled(state => [...state, "Виберіть принаймі один розділ"]);
        if (!selectFormat) setHelperTextDisabled(state => [...state, "Виберіть формат файлу"]);

        if (stateUseFormulaMarkup) {
            const validateFormulaPrice = stateFormulaPrice.filter((_, index) => (index !== (stateFormulaPrice.length - 1)))

            if (!stateFormulaPrice.every(item => item.mark_up)) {
                setHelperTextDisabled(state => [...state, "До кожного діапазону потрібно ввести націнку"]);
            }
            if (!validateFormulaPrice.every(item => item.from_price < (item.to_price ? item.to_price : 0))) {
                setHelperTextDisabled(state => [...state, "Перевірте правильність введених діапазонів"]);
            }
        }

        if (!(stateUseRetailPrice || stateUseFormulaMarkup || stateUseFixedMarkup || stateUseMaxDropPrice)) {
            setHelperTextDisabled(state => [...state, "Оберіть один із способів націнки"])
        };

        if (stateUseFixedMarkup) {
            if (stateFixedPrice === 0) setHelperTextDisabled(state => [...state, "Введіть значення фіксованої націнки"])
        } 
    }, [name, selectedModule, selectCategories, selectFormat, stateUseRetailPrice, stateFormulaPrice, stateUseMaxDropPrice, stateUseFormulaMarkup, stateUseFixedMarkup, stateFixedPrice])

    function onSubmit() {
        setLoading(true);

        const postData: Record<string, any> = {
            name,
            marketplace_id: parseInt(id || ""),
            marketplace_module_id: selectedModule,
            categories: selectCategories,
            file_format: selectFormat,
            fixed_markup: stateFixedPrice,
            formula_markup: stateFormulaPrice,
            use_formula_markup: stateUseFormulaMarkup,
            use_max_drop_price: stateUseMaxDropPrice,
            use_retail_price: stateUseRetailPrice,
            use_fixed_markup: stateUseFixedMarkup
        };

        if (mode === "edit") {
            postData.id = id;
        }

        let response: Promise<AxiosResponse<any, any>> | null = null

        if (mode === "add") {
            response = axiosAuth()
                .post("/v2/links/add/", JSON.stringify(postData), {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
        } else {
            response = axiosAuth()
                .put("/v2/links/", JSON.stringify(postData), {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
        }

        response?.then((response) => {
            setStatusSubmit(["Збережено!", "success"]);
            setLoading(false);
            setTimeout(() => {
                setStatusSubmit(["Зберегти", "primary"]);
            }, 2500)
            navigate("/unloading/" + window.localStorage.getItem("marketplace"))
        }).catch((err) => {
            if (err === "logaut") {
                dispatch(userClear());
            } else {
                console.error(err)
                dispatch(setErrorText(err.response.data.error))
                setStatusSubmit(["Не вдалося зберегти", "error"]);
                setLoading(false);
                setTimeout(() => {
                    setStatusSubmit(["Зберегти", "primary"]);
                }, 2500)
            }
        })
    }

    return (
        <Tooltip
            title={helperTextDisabled.length > 0 ? Array.from(new Set(helperTextDisabled)).map((item, i) => <p key={i}>{item}</p>) : "Все заповнено"}
            placement="top"
            arrow
        >
            <div>
                <LoadingButton
                    fullWidth
                    disabled={!!helperTextDisabled.length}
                    size="large"
                    onClick={onSubmit}
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    endIcon={<SaveIcon fontSize="small" />}
                    color={statusSubmit[1]}
                >
                    <span>{statusSubmit[0]}</span>
                </LoadingButton>
            </div>
        </Tooltip>
    )
}